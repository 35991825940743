import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import CheckCircleIcon from '../../../images/md-icons/check_circle/materialicons/24px.svg';
import AddTaskIcon from '../../../images/md-icons/add_task/materialicons/24px.svg';
import CheckboxIcon from '../../../images/md-icons/check_box/materialicons/24px.svg';
import CheckboxOutlineIcon from '../../../images/md-icons/check_box_outline_blank/materialicons/24px.svg';

import Pressable from '../Pressable';
import styles from './styles';

const Checkbox = (props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onPress = (e) => {
    if (props.disabled) {
      return;
    }

    const value = !checked;

    if (props.onPress) {
      props.onPress(value);
    }
  };

  let checkedIcon = <CheckboxIcon fill="#231fda" />;
  let uncheckedIcon = <CheckboxOutlineIcon fill="#4a4a49" />;
  if (props.type === 'add-task') {
    checkedIcon = <CheckCircleIcon />;
    uncheckedIcon = <AddTaskIcon />;
  }

  return (
    <Pressable allowBubbling onPress={onPress} style={{opacity: 1}}>
      <View
        style={[styles.root, props.style, props.disabled && styles.disabled]}>
        {checked ? checkedIcon : uncheckedIcon}
      </View>
    </Pressable>
  );
};

export default Checkbox;
