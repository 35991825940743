import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {SafeAreaView, View, ScrollView, ActivityIndicator} from 'react-native';
import {HeaderBackButton} from '@react-navigation/stack';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {order as orderApi} from '../../../api/private';
import authentication from '../../../lib/authentication';
import Format from '../../../lib/format';
import UserContext from '../../../components/context/UserContext';
import SearchIcon from '../../../images/md-icons/search/materialicons/24px.svg';
import ChevronLeftIcon from '../../../images/md-icons/chevron_left/materialicons/24px.svg';
import {
  Pressable,
  Text,
  PrimaryButton,
  Dialog,
  Checkbox,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addCheckedTasks} from '../../../actions';

const List = ({route, navigation}) => {
  const dispatch = useDispatch();
  const request_id = route.params?.request_id;
  const order_id = route.params?.order_id;

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const checked = useSelector((state) => state.checkedTasks);

  const prefix = useRef('');

  const {me} = useContext(UserContext);

  const save = useCallback(async () => {
    if (checked.length > 0) {
      const token = await authentication.getAccessToken();
      await orderApi.addRequestTasks(token, order_id, request_id, checked);
    }
  }, [order_id, request_id, checked]);

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={async () => {
            if (checked.length > 0) {
              setShowConfirmation(true);
            } else {
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            }
          }}
        />
      ),
      headerTitle: (
        <Text style={{display: 'flex', justifyContent: 'center'}}>
          Opdracht
        </Text>
      ),
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.navigate('SearchTasks', {order_id});
          }}
          style={{marginRight: 16}}>
          <SearchIcon />
        </Pressable>
      ),
    });
  }, [navigation, order_id, prefix, save, dispatch, checked]);

  useEffect(() => {
    if (!me) {
      return;
    }

    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const order = await orderApi.details(token, order_id);
      const tasks = await orderApi.popularTasks(token, order.license);
      setOrder(order);
      setTasks(tasks.map((task) => ({...task, key: task.id})));
      setLoading(false);
    };

    fetch();
  }, [me, order_id]);

  const onCheckedChange = useCallback(
    (task) => {
      let changes = checked;
      if (checked.some((item) => item.key === task.key)) {
        changes = changes.filter((item) => item.key !== task.key);
      } else {
        changes.push(task);
      }

      dispatch(addCheckedTasks(changes));
    },
    [checked, dispatch],
  );

  const checkedTasks = checked?.filter((item) =>
    tasks.map((task) => task.key).includes(item.key),
  );

  const searchResults = checked?.filter(
    (item) => !tasks.map((task) => task.key).includes(item.key),
  );

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading && (
            <>
              {searchResults?.length > 0 && (
                <View style={styles.article}>
                  <View style={styles.articleHeader}>
                    <Text style={globalStyles.mediumPlus}>
                      Zoekresultaten ({searchResults.length})
                    </Text>
                  </View>
                  {searchResults?.map((task) => (
                    <Pressable
                      key={task.key}
                      style={styles.row}
                      onPress={() => onCheckedChange(task)}>
                      <View style={styles.icon}>
                        <Checkbox
                          checked={checked.some(
                            (item) => item.key === task.key,
                          )}
                        />
                      </View>
                      <View style={styles.description}>
                        <Text
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {task.description}
                        </Text>
                        {!order?.lease_company && _.isNumber(task.price) && (
                          <Text>{Format.price(task.price)}</Text>
                        )}
                      </View>
                    </Pressable>
                  ))}
                </View>
              )}
              <View style={styles.article}>
                <View style={styles.articleHeader}>
                  <Text style={globalStyles.mediumPlus}>
                    Meest gekozen{' '}
                    {checkedTasks?.length > 0 && (
                      <Text style={globalStyles.mediumPlus}>
                        ({checkedTasks.length})
                      </Text>
                    )}
                  </Text>
                </View>
                {tasks?.map((task) => (
                  <Pressable
                    key={task.key}
                    style={styles.row}
                    onPress={() => onCheckedChange(task)}>
                    <View style={styles.icon}>
                      <Checkbox
                        checked={checked.some((item) => item.key === task.key)}
                      />
                    </View>
                    <View style={styles.description}>
                      <Text
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>
                        {task.description}
                      </Text>
                      {!order?.lease_company && _.isNumber(task.price) && (
                        <Text>{Format.price(task.price)}</Text>
                      )}
                    </View>
                  </Pressable>
                ))}
              </View>
            </>
          )}
        </ScrollView>
        <View
          style={[
            globalStyles.footerView,
            {justifyContent: 'flex-end', alignItems: 'center'},
          ]}>
          <PrimaryButton
            onPress={async () => {
              await save();
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            }}>
            Toevoegen
          </PrimaryButton>
        </View>
      </SafeAreaView>
      <Dialog
        visible={showConfirmation}
        buttons={[
          {text: 'Nee', onPress: () => setShowConfirmation(false)},
          {
            text: 'Ja',
            onPress: () => {
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            },
          },
        ]}>
        <View>
          <Text>De items zijn nog niet opgeslagen.</Text>
          <Text>Weet je zeker dat je terug wilt gaan?</Text>
        </View>
      </Dialog>
    </>
  );
};

export default List;
