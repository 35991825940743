import React, {useReducer, useEffect} from 'react';
import _ from 'lodash';
import AsyncStorage from '@react-native-community/async-storage';

import {actionTypes} from '../../actions';
import SessionStateContext from '../context/SessionStateContext';
import DispatchContext from '../context/DispatchContext';
import {reducer} from '../../reducer';
import trace from '../../lib/trace';

const __SESSION_STORE = '__SESSION_STORE';
const initialSessionState = {
  checkedTasks: [],
};

export let StaticDispatch = null;

const store = _.throttle(async (newState) => {
  //trace('Persisting', newState);
  try {
    await AsyncStorage.setItem(__SESSION_STORE, JSON.stringify(newState));
  } catch (e) {
    console.error(e);
  }
}, 500);

const storeReducer = (state, action) => {
  const newState = reducer(state, action);
  store(newState);
  return newState;
};

export default (App) => {
  const WrappedApp = (props) => {
    const [sessionState, dispatch] = useReducer(
      storeReducer,
      initialSessionState,
    );

    StaticDispatch = dispatch;

    useEffect(() => {
      AsyncStorage.getItem(__SESSION_STORE, (err, storedState) => {
        if (err) {
          console.error(err);
          return;
        }

        trace('Session store initialized with', storedState);
        dispatch({type: actionTypes.INIT, value: JSON.parse(storedState)});
      });
    }, []);

    return (
      <SessionStateContext.Provider value={sessionState}>
        <DispatchContext.Provider value={dispatch}>
          <App {...props} />
        </DispatchContext.Provider>
      </SessionStateContext.Provider>
    );
  };

  return WrappedApp;
};
