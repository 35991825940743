import '@rmwc/drawer/styles';

import React, {useContext} from 'react';
import {View} from 'react-native';
import {
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
} from '@rmwc/drawer';

import globalStyles from '../../../styles';

import {useDispatch, useSelector} from '../../../lib/hooks';
import {setShowDrawer} from '../../../actions';
import {Text, Pressable, Checkbox} from '../../../components/controls';
import {user as userApi} from '../../../api/private';
import authentication from '../../../lib/authentication';
import UserContext from '../../../components/context/UserContext';

const SideDrawer = () => {
  const dispatch = useDispatch();

  const {me, update} = useContext(UserContext);

  const showDrawer = useSelector((state) => state.showDrawer);

  return (
    <Drawer
      modal
      open={showDrawer}
      onClose={() => dispatch(setShowDrawer(false))}>
      <DrawerHeader>
        <DrawerTitle>
          <Text style={globalStyles.largePlus}>
            {me?.first_name} {me?.last_name}
          </Text>
        </DrawerTitle>
        <DrawerSubtitle>
          <Text style={[globalStyles.smallPlus, {color: '#828282'}]}>
            {me?.email}
          </Text>
        </DrawerSubtitle>
      </DrawerHeader>
      <DrawerContent>
        <View
          style={{
            marginVertical: 16,
            paddingVertical: 16,
            paddingHorizontal: 16,
            borderTopWidth: 1,
            borderTopColor: '#f2f2f2',
          }}>
          <Text
            style={[
              globalStyles.smallPlus,
              {color: '#828282', marginBottom: 12},
            ]}>
            Vestiging
          </Text>
          {me?.dealers.map((dealer) => (
            <Pressable
              key={dealer.id}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginVertical: 6,
                marginLeft: -6,
              }}
              onPress={async () => {
                let changes = me?.dealer_selection;
                if (changes.includes(dealer.id)) {
                  changes = changes.filter((item) => item !== dealer.id);
                } else {
                  changes.push(dealer.id);
                }

                const token = await authentication.getAccessToken();
                await userApi.update(token, {dealer_selection: changes});
                await update();
              }}>
              <Checkbox checked={me?.dealer_selection?.includes(dealer.id)} />
              <Text style={{marginLeft: 26}}>{dealer.city}</Text>
            </Pressable>
          ))}
        </View>
      </DrawerContent>
    </Drawer>
  );
};

export default SideDrawer;
