import React, {useState, useEffect} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';

import globalStyles from '../../../../../styles';
import styles from './styles';
import {
  PrimaryButton,
  FormTextInput,
  Text,
  CloseButton,
  FormNumberInput,
  Pressable,
  RadioButton,
  Select,
} from '../../../../controls';
import authentication from '../../../../../lib/authentication';
import {car as carApi} from '../../../../../api/public';
import {order as orderApi} from '../../../../../api/private';
import leaseCompanies from '../../../../../types/lease-companies';

const Index = (props) => {
  const {order_id} = props;

  const [license, setLicense] = useState(props.license);
  const [car, setCar] = useState(null);
  const [mileage, setMileage] = useState(props.mileage);
  const [is_lease, setIsLease] = useState(_.isString(props.lease_company));
  const [lease, setLease] = useState(props.lease_company);
  const [is_company_car, setIsCompanyCar] = useState(
    _.isString(props.company_name),
  );

  useEffect(() => {
    const fetch = async () => {
      if (!license || license.length < 6) {
        setCar({});
        return;
      }

      try {
        const car = await carApi.fetch(license);
        setCar(car);
      } catch {
        setCar({});
      }
    };

    fetch();
  }, [license]);

  const isValid =
    car?.license && car?.license.length > 5 && (!is_lease || lease);

  const onSave = async () => {
    if (!isValid) {
      return;
    }

    const token = await authentication.getAccessToken();
    await orderApi.setCar(token, order_id, {
      license,
      mileage,
      lease_company: lease,
      company_name: is_company_car ? props.company_name || ' ' : null,
    });

    props.onDismiss();
  };

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView>
          <View
            style={[
              globalStyles.headerView,
              {paddingLeft: 0, paddingRight: 0, paddingBottom: 0},
            ]}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                height: 56,
                paddingHorizontal: 8,
                paddingVertical: 8,
              }}>
              <CloseButton
                style={{position: 'absolute', top: 8, left: 8}}
                onPress={props.onDismiss}
              />
              <Text style={globalStyles.medium}>Auto</Text>
            </View>
          </View>
          <View style={[globalStyles.contentView, styles.noPadding]}>
            <View style={styles.article}>
              <Text style={[globalStyles.mediumPlus, styles.label]}>
                Kenteken
              </Text>
              <FormTextInput
                value={license}
                onChangeText={(text) =>
                  setLicense(text?.replace(/[^0-9a-z]/gi, '').toUpperCase())
                }
                label="Voer cijfer-lettercombinatie in"
                inputProps={{
                  maxLength: 8,
                  autoCapitalize: 'characters',
                }}
              />
              {car?.name && <Text style={{lineHeight: 20}}>{car.name}</Text>}
            </View>
            <View style={styles.article}>
              <View style={styles.label}>
                <Text style={globalStyles.mediumPlus}>
                  Indicatie kilometerstand
                </Text>
              </View>
              <View style={styles.input}>
                <FormNumberInput
                  label="Voer schatting in"
                  value={mileage}
                  onChangeNumber={setMileage}
                  digits={0}
                  inputProps={{
                    maxLength: 7,
                  }}
                />
              </View>
            </View>
            <View style={[styles.article, styles.noPadding]}>
              <View
                style={[
                  styles.label,
                  {
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    borderBottomWidth: 1,
                    borderBottomColor: '#f2f2f2',
                  },
                ]}>
                <Text style={globalStyles.mediumPlus}>Eigendom</Text>
              </View>

              <Pressable
                style={styles.radioButtonRow}
                onPress={() => {
                  setIsLease(false);
                  setIsCompanyCar(false);
                }}>
                <RadioButton checked={!is_company_car && !is_lease} />
                <View style={styles.radioButtonDescription}>
                  <Text>Particulier</Text>
                </View>
              </Pressable>
              <Pressable
                style={styles.radioButtonRow}
                onPress={() => {
                  setIsLease(false);
                  setIsCompanyCar(true);
                }}>
                <RadioButton checked={is_company_car && !is_lease} />
                <View style={styles.radioButtonDescription}>
                  <Text>Zakelijk</Text>
                </View>
              </Pressable>
              <Pressable
                style={styles.radioButtonRow}
                onPress={() => {
                  setIsLease(true);
                  setIsCompanyCar(false);
                }}>
                <RadioButton checked={!is_company_car && is_lease} />
                <View style={styles.radioButtonDescription}>
                  <Text>Private lease</Text>
                </View>
              </Pressable>
              <Pressable
                style={styles.radioButtonRow}
                onPress={() => {
                  setIsLease(true);
                  setIsCompanyCar(true);
                }}>
                <RadioButton checked={is_company_car && is_lease} />
                <View style={[styles.radioButtonDescription, styles.last]}>
                  <Text>Zakelijk lease</Text>
                </View>
              </Pressable>
            </View>
            {is_lease && (
              <View style={styles.article}>
                <Text style={[globalStyles.mediumPlus, styles.label]}>
                  Leasemaatschappij
                </Text>
                <Select
                  label="Selecteer een leasemaatschappij"
                  value={lease}
                  options={leaseCompanies}
                  onChange={setLease}
                />
              </View>
            )}
          </View>
        </ScrollView>
        <View style={[globalStyles.footerView, styles.footerView]}>
          <PrimaryButton disabled={!isValid} onPress={onSave}>
            Opslaan
          </PrimaryButton>
        </View>
      </SafeAreaView>
    </>
  );
};

export default Index;
