export const actionTypes = {
  INIT: 'INIT',
  UPDATE_JOB: 'UPDATE_JOB',
  UPDATE_INBOX: 'UPDATE_INBOX',
  ADD_CHECKED_TASKS: 'ADD_CHECKED_TASKS',
  SET_SHOW_DRAWER: 'SET_SHOW_DRAWER',
  SET_SHOW_CHANGE_DEALER: 'SET_SHOW_CHANGE_DEALER',
  SET_CHECKED_PARTS: 'SET_CHECKED_PARTS',
};

export const updateJob = (value) => {
  return {type: actionTypes.UPDATE_JOB, value};
};

export const updateInbox = () => {
  return {type: actionTypes.UPDATE_INBOX};
};

export const addCheckedTasks = (value) => {
  return {type: actionTypes.ADD_CHECKED_TASKS, value};
};

export const setShowDrawer = (value) => {
  return {type: actionTypes.SET_SHOW_DRAWER, value};
};

export const setShowChangeDealer = (value) => {
  return {type: actionTypes.SET_SHOW_CHANGE_DEALER, value};
};

export const setCheckedParts = (value) => {
  return {type: actionTypes.SET_CHECKED_PARTS, value};
};
