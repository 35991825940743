import React from 'react';
import {
  Button,
  Divider,
  Dialog as PaperDialog,
  Portal,
} from 'react-native-paper';

const Dialog = ({
  style,
  visible,
  dismissable,
  onDismiss,
  title,
  children,
  buttons,
  options = {
    hideDividers: false,
  },
}) => {
  let footer = <Button onPress={onDismiss}>OK</Button>;

  if (buttons) {
    footer = buttons.map((button, index) => (
      <Button key={index} onPress={button.onPress}>
        {button.text}
      </Button>
    ));
  }

  return (
    <Portal>
      <PaperDialog
        style={style}
        visible={visible}
        dismissable={dismissable}
        onDismiss={onDismiss}>
        {title && (
          <>
            <PaperDialog.Title>{title}</PaperDialog.Title>
            {!options.hideDividers && <Divider />}
          </>
        )}
        <PaperDialog.Content style={{paddingBottom: 0}}>
          {children}
        </PaperDialog.Content>
        {!options.hideDividers && <Divider />}
        <PaperDialog.Actions>{footer}</PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
};

export default Dialog;
