import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';

const messages = {
  list: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.orderMessage}/${order_id}`,
    );

    const json = await result.json();
    return json;
  },

  sendMessage: async (token, order_id, message, images) => {
    const result = await withToken(token).fetch(
      `${endpoints.orderMessage}/${order_id}`,
      {
        method: 'POST',
        body: JSON.stringify({message, images}),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default messages;
