import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import config from '../../configuration';
import authentication from '../authentication';
import trace from '../trace';
import handler from './handler';

const initialize = async (params) => {
  // Pre-emptive token refresh.
  // TODO: actually validate the access token before refreshing it.
  await authentication.refresh();

  const token = await authentication.getAccessToken();
  const user_id = params?.user_id || (await authentication.getUserID());

  const connection = new HubConnectionBuilder()
    .withUrl(config.signalRHubUrl, {
      accessTokenFactory: () => token,
      headers: {
        'x-ms-client-principal-id': user_id,
      },
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection.on('notification', (notification) => {
    try {
      trace('Notification received', notification);

      const {tag, alert, type, license, status} = notification;
      handler(alert, type, null, null, null, tag, license, status);
    } catch (e) {
      console.log(e);
    }
  });
  connection.onclose(() => console.log('disconnected'));

  console.log('connecting...');
  await connection.start();
};

export default {
  initialize,
  kill: () => {
    // Notifications kill method stub
  },
  register: async (user_id) => {
    await initialize({user_id});
  },
  requestPermissions: async () => {
    const result = await Notification.requestPermission();
    trace('Permission result', result);
  },
};
