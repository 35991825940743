import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  article: {
    borderTopColor: '#dcdcdc',
    borderTopWidth: 1,
    marginTop: -1,
    paddingHorizontal: 16,
    paddingVertical: 16,
    minHeight: 56,
  },
  borderBottom: {
    borderBottomColor: '#dcdcdc',
    borderBottomWidth: 1,
  },
  noPadding: {
    padding: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginTop: 8,
  },
  inputFirst: {
    marginTop: 8,
  },
  inputLast: {
    marginBottom: 0,
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  radioButtonDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    flex: 1,
    marginLeft: 8,
    paddingRight: 16,
    paddingVertical: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
  },
  last: {
    borderBottomWidth: 0,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
  },
  footerView: {
    justifyContent: 'flex-end',
  },
});
