import React from 'react';
import {View} from 'react-native';

import styles from './styles';
import globalStyles from '../../../../styles';
import {Text, Toggle} from '../../../controls';
import Format from '../../../../lib/format';

const TAX_RATE = 1.21;

const Payment = ({
  total,
  due,
  incomplete,
  isFinished,
  isPending,
  can_pay_later,
  onPayLaterChange,
}) => {
  return (
    <View style={styles.article}>
      <View style={styles.articleHeader}>
        <Text style={globalStyles.mediumPlus}>Betaling</Text>
      </View>
      <View style={styles.row}>
        <Text>Voldaan</Text>
        <Text>{Format.price((total - due) * TAX_RATE)}</Text>
      </View>
      <View style={styles.row}>
        <Text>Openstaand</Text>
        <Text>{Format.price(due * TAX_RATE)}</Text>
      </View>
      <View style={styles.row}>
        <Text disabled={incomplete || isFinished || isPending}>
          Later betalen toestaan
        </Text>
        <Toggle
          disabled={incomplete || isFinished || isPending}
          checked={can_pay_later}
          onPress={onPayLaterChange}
        />
      </View>
    </View>
  );
};

export default Payment;
