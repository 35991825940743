import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';
import {OrderRequestStatus} from '../../types';

const order = {
  new: async (token, newOrder) => {
    const result = await withToken(token).fetch(`${endpoints.order}`, {
      method: 'POST',
      body: JSON.stringify(newOrder),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  accept: async (token, order_id, approve) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/accept`,
      {
        method: 'POST',
        body: JSON.stringify({
          approve,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  details: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  checkin: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/checkin`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  setWorkDone: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/work/done`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  checkout: async (token, dealer_id, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${dealer_id}/${order_id}/checkout`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  list: async (token) => {
    const result = await withToken(token).fetch(`${endpoints.order}/list`, {
      method: 'POST',
    });

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  popularTasks: async (token, license) => {
    const result = await withToken(token).fetch(
      `${endpoints.orderTasks}/popular/${license}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  fetchTask: async (token, task_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/task/${task_id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  taskPackages: async (token, task_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/task/${task_id}/packages`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  saveTask: async (token, task) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/task/${task.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({...task}),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  deleteTask: async (token, task_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/task/${task_id}`,
      {
        method: 'DELETE',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  saveDate: async (token, order_id, date) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          date,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  setCar: async (token, order_id, car) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          car,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  setDriver: async (token, order_id, user) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          user,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  setExtra: async (token, order_id, extra) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          extra,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  setDealer: async (token, order_id, dealer) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          dealer,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  requests: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/requests`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  addRequestTasks: async (token, order_id, request_id, tasks) => {
    const result = await withToken(token).fetch(`${endpoints.order}/request`, {
      method: 'POST',
      body: JSON.stringify({
        order_id,
        request_id,
        tasks,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  requestApproval: async (token, request_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/request/${request_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({status: OrderRequestStatus.Pending}),
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  requestApproved: async (token, request_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/request/${request_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({status: OrderRequestStatus.Approved}),
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  startDiagnosis: async (token, id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/diagnosis/start/${id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  endDiagnosis: async (token, id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/diagnosis/end/${id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  approve: async (token, id, approved) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/approve/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({status: 'APPROVED', approved}),
      },
    );

    const json = await result.json();
    return json;
  },
  delete: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'DELETE',
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  fetchParts: async (token, prefix) => {
    const result = await withToken(token).fetch(
      `${endpoints.orderParts}/search`,
      {
        method: 'POST',
        body: JSON.stringify({prefix}),
      },
    );

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  sendReminder: async (token, order_id) => {
    const result = await await withToken(token).fetch(
      `${endpoints.order}/${order_id}/reminder`,
      {
        method: 'POST',
      },
    );

    const json = await result.json();
    return json;
  },
  sendEmailReminder: async (token, order_id) => {
    const result = await await withToken(token).fetch(
      `${endpoints.order}/${order_id}/reminder`,
      {
        method: 'POST',
        body: JSON.stringify({
          email: true,
        }),
      },
    );

    const json = await result.json();
    return json;
  },
  toggleCanPayLater: async (token, order_id, can_pay_later) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          can_pay_later,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  reactivateTask: async (token, task_id) => {
    const result = await await withToken(token).fetch(
      `${endpoints.order}/task/${task_id}/reactivate`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  messagesUnread: async (token, order_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/messages/unread`,
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  saveMileage: async (token, order_id, mileage) => {
    const result = await withToken(token).fetch(
      `${endpoints.order}/${order_id}/mileage`,
      {
        method: 'POST',
        body: JSON.stringify({
          mileage,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default order;
