import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    paddingTop: 14,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  logo: {
    width: 124,
    height: 34,
  },
  contentView: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 72,
    position: 'relative',
  },
  header: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  article: {},
  articleHeader: {
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginBottom: -1,
    minHeight: 56,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
  },
  gray: {
    color: '#828282',
  },
  link: {
    textDecorationLine: 'underline',
  },
  footerRow: {
    flexDirection: 'row',
  },
  modalMainView: {
    width: '100%',
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  contentPadding: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
};

export default StyleSheet.create(styles);
