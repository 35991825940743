import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';

export default {
  user: async (token, dealer_id, license) => {
    const result = await withToken(token).fetch(
      `${endpoints.car}/user/${dealer_id}/${license}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  fetch: async (token, license) => {
    const result = await withToken(token).fetch(`${endpoints.car}/${license}`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  history: async (token, license) => {
    const result = await withToken(token).fetch(
      `${endpoints.car}/${license}/history`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};
