import React, {useState} from 'react';
import {View} from 'react-native';
import {Badge} from 'react-native-paper';
import {useNavigation} from '@react-navigation/core';

import styles from './styles';
import globalStyles from '../../../../styles';
import {Link, Text, FormSheetModal, Pressable} from '../../../controls';
import {OrderStatus} from '../../../../types';
import ChatIcon from '../../../../images/md-icons/chat/materialicons/24px.svg';

import UserModal from './UserModal';

const BadgedChatIcon = ({unread}) => {
  return (
    <View style={{width: 24, height: 24}}>
      <ChatIcon fill="#231fda" />
      <Badge
        visible={unread > 0}
        style={{position: 'absolute', top: -2, right: -2}}
        size={8}
      />
    </View>
  );
};

const User = ({
  order_id,
  status,
  isLease,
  first_name,
  last_name,
  phone_number,
  email,
  company_name,
  unread_messages,
  onRefresh,
}) => {
  const navigation = useNavigation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <View style={styles.article}>
        <View style={styles.articleHeader}>
          <Text style={globalStyles.mediumPlus}>Contact</Text>
          {status !== OrderStatus.Draft && (
            <Pressable onPress={() => navigation.navigate('Chat', {order_id})}>
              <BadgedChatIcon unread={unread_messages} />
            </Pressable>
          )}
          {status === OrderStatus.Draft && (
            <Link onPress={() => setShowModal(true)}>
              {!first_name || !last_name || !phone_number || !email
                ? 'Invoeren'
                : 'Wijzigen'}
            </Link>
          )}
        </View>
        {company_name && (
          <View style={styles.row}>
            <Text>Bedrijfsnaam</Text>
            <Text>{company_name}</Text>
          </View>
        )}
        <View style={styles.row}>
          <Text>Voornaam</Text>
          <Text>{first_name}</Text>
        </View>
        <View style={styles.row}>
          <Text>Achternaam</Text>
          <Text>{last_name}</Text>
        </View>
        <View style={styles.row}>
          <Text>Mobiele telefoonnummer</Text>
          <Text>{phone_number}</Text>
        </View>
        <View style={styles.row}>
          <Text>Emailadres</Text>
          <Text>{email}</Text>
        </View>
      </View>
      {showModal && (
        <FormSheetModal visible={true}>
          <UserModal
            isLease={isLease}
            order_id={order_id}
            first_name={first_name}
            last_name={last_name}
            phone_number={phone_number}
            email={email}
            company_name={company_name}
            onDismiss={() => {
              setShowModal(false);
              onRefresh();
            }}
          />
        </FormSheetModal>
      )}
    </>
  );
};

export default User;
