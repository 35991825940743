import {StyleSheet} from 'react-native';

const styles = {
  modalMainView: {
    width: '100%',
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  headerView: {
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  contentView: {
    width: '100%',
    flexShrink: 0,
    flexGrow: 1,
    position: 'relative',
  },
  footerView: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    borderTopWidth: 0,
  },
};

export default StyleSheet.create(styles);
