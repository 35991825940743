import {StyleSheet} from 'react-native';

const styles = {
  modalMainView: {
    width: '100%',
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  headerView: {
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  contentView: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  article: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    marginBottom: -1,
  },
  articleHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 20,
    zIndex: 1,
  },
  row: {
    paddingHorizontal: 0,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    marginTop: -1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  last: {
    borderBottomWidth: 0,
  },
  label: {
    lineHeight: 24,
  },
  gray: {
    color: '#828282',
  },
  link: {
    textDecorationLine: 'underline',
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
  },
  radioButtonDescription: {
    minHeight: 60,
    flex: 1,
    marginLeft: 20,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    position: 'relative',
    marginTop: -1,
    justifyContent: 'center',
  },
  unitButtonRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 20,
  },
  unitRadioButton: {
    paddingTop: 15,
  },
  unitInputView: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sideScrollContainer: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
  },
  searchInput: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  resultsView: {
    flex: 1,
    paddingHorizontal: 20,
  },
  results: {
    padding: 10,
    borderRadius: 1,
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.3,
    shadowColor: '#000',
    shadowRadius: 4,
    elevation: 3,
    backgroundColor: '#fff',
    width: '100%',
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 1,
  },
  result: {
    marginVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentPadding: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
  },
  footerView: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    borderTopWidth: 0,
  },
  icon: {
    marginRight: 30,
  },
};

export default StyleSheet.create(styles);
