import {StyleSheet} from 'react-native';

const styles = {
  modalMainView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  headerView: {
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  contentView: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  article: {
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  articleHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    paddingHorizontal: 0,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    marginTop: -1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  last: {
    borderBottomWidth: 0,
  },
  link: {
    textDecorationLine: 'underline',
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
  },
  radioButtonDescription: {
    minHeight: 60,
    flex: 1,
    marginLeft: 20,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    position: 'relative',
    marginTop: -1,
    justifyContent: 'center',
  },
  unitButtonRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 20,
  },
  unitRadioButton: {
    paddingTop: 15,
  },
  unitInputView: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  contentPadding: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  footerView: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    borderTopWidth: 0,
  },
};

export default StyleSheet.create(styles);
