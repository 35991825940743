import React, {useState} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../../styles';
import TaskRow from './TaskRow';
import ExtraRow from './ExtraRow';
import {Text, Pressable, Toggle} from '../../../controls';
import {OrderStatus} from '../../../../types';
import {EXTRAS_CODES} from '../../../../types/ServiceCodes';
import Format from '../../../../lib/format';
import {useDispatch} from '../../../../lib/hooks';
import {addCheckedTasks} from '../../../../actions';
import AddIcon from '../../../../images/md-icons/add/materialicons/24px.svg';
import ExpandLessIcon from '../../../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../../../images/md-icons/expand_more/materialicons/24px.svg';

const TAX_RATE = 1.21;

const Order = ({
  order,
  canAddItems,
  isLease,
  requests,
  extras,
  dealer,
  is_order_active,
  isPending,
  canAccept,
  isFinished,
  leaseApproval,
  approvedTasks,
  total,
  onTaskApproved,
  onTaskReactivated,
  onLeaseApproval,
  onRefresh,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [expand, setExpand] = useState(true);

  const invoice_rows = requests
    .flatMap((request) => request.tasks)
    .filter((task) => task.is_invoice_item)
    .sort((a, b) => a.sort_number - b.sort_number);
  const invoice_total = invoice_rows.reduce((acc, row) => acc + row.amount, 0);

  return (
    <>
      <View style={styles.article}>
        <View style={styles.articleHeader}>
          <Text style={globalStyles.mediumPlus}>Opdracht</Text>
          <Text style={globalStyles.medium}>Excl. btw</Text>
        </View>
        {isLease && is_order_active && !isPending && !canAccept && !isFinished && (
          <View style={styles.row}>
            <Text>Akkoord leasemaatschappij</Text>
            <Toggle checked={leaseApproval} onPress={onLeaseApproval} />
          </View>
        )}
        {
          <ExtraRow
            order={order}
            code={EXTRAS_CODES.REPLACEMENT}
            extras={extras}
            dealer={dealer}
            isLease={isLease}
            onRefresh={onRefresh}
          />
        }
        {requests?.map((request) => (
          <View key={request.id}>
            {request.tasks
              .filter(
                (task) =>
                  !task.is_extra &&
                  !task.is_import &&
                  (!order.checkin ||
                    task.is_approved ||
                    task.is_approved === null ||
                    !request.is_initial),
              )
              .map((task) => (
                <TaskRow
                  key={task.id}
                  task={task}
                  order={order}
                  request={request}
                  dealer={dealer}
                  isLease={isLease}
                  isOrderActive={is_order_active}
                  canAccept={canAccept}
                  isPending={isPending}
                  isFinished={isFinished}
                  approvedTasks={approvedTasks}
                  onTaskApproved={onTaskApproved}
                  onTaskReactivated={onTaskReactivated}
                  onRefresh={onRefresh}
                />
              ))}
          </View>
        ))}
        {canAddItems && (
          <Pressable
            style={[styles.row, {justifyContent: 'flex-start'}]}
            onPress={() => {
              const request_id =
                isLease && requests?.length ? requests[0].id : null;

              const screen =
                order.status === OrderStatus.Draft ? 'Services' : 'ListTasks';

              dispatch(addCheckedTasks([]));
              navigation.navigate('TasksModal', {
                screen,
                params: _.pickBy(
                  {
                    order_id: order.id,
                    request_id,
                  },
                  _.identity,
                ),
              });
            }}>
            <AddIcon fill="#231fda" />
            <Text style={{marginLeft: 30}}>Item toevoegen</Text>
          </Pressable>
        )}
        {!isLease && total > 0 && (
          <>
            <View style={styles.row}>
              <Text>BTW</Text>
              <Text>{Format.price(total * TAX_RATE - total)}</Text>
            </View>
            <View style={styles.row}>
              <View>
                <Text style={globalStyles.mediumPlus}>Totaal</Text>
                <Text style={globalStyles.small}>Inclusief BTW</Text>
              </View>
              <Text style={globalStyles.mediumPlus}>
                {Format.price(total * TAX_RATE)}
              </Text>
            </View>
          </>
        )}
      </View>
      {invoice_rows.length > 0 && (
        <View style={styles.article}>
          <Pressable
            style={styles.articleHeader}
            onPress={() => setExpand(!expand)}>
            <View style={{flexDirection: 'row'}}>
              <Text style={globalStyles.mediumPlus}>Factuur</Text>
            </View>
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Pressable>
          {expand && (
            <>
              {invoice_rows.map((task) => (
                <View key={task.id} style={styles.row}>
                  <Text>{task.description}</Text>
                  <Text>{Format.price(task.amount)}</Text>
                </View>
              ))}
              <View style={styles.row}>
                <Text>BTW</Text>
                <Text>
                  {Format.price(invoice_total * TAX_RATE - invoice_total)}
                </Text>
              </View>
              <View style={styles.row}>
                <View>
                  <Text style={globalStyles.mediumPlus}>Totaal</Text>
                  <Text style={globalStyles.small}>Inclusief BTW</Text>
                </View>
                <Text style={globalStyles.mediumPlus}>
                  {Format.price(invoice_total * TAX_RATE)}
                </Text>
              </View>
            </>
          )}
        </View>
      )}
    </>
  );
};

export default Order;
