import moment from 'moment';
import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';

const dealer = {
  services: async (token, dealer_id, all = false) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/${dealer_id}/services?${all ? 'all=1' : ''}`,
    );

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  availability: async (token) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/availability`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  saveAvailability: async (token, dealer_id, date, blocked, capacity) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/availability`,
      {
        method: 'POST',
        body: JSON.stringify({
          dealer_id,
          date: moment(date).format('YYYY-MM-DD'),
          blocked,
          capacity,
        }),
      },
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  dashboard: async (token) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/dashboard`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  dashboardOrders: async (token, status) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/dashboard/orders`,
      {
        method: 'POST',
        body: JSON.stringify({
          status,
        }),
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  messages: async (token) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/messages`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  messagesUnread: async (token) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/messages/unread`,
      {
        method: 'POST',
      },
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  details: async (token, dealer_id) => {
    const result = await withToken(token).fetch(
      `${endpoints.dealer}/${dealer_id}`,
    );

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
};

export default dealer;
