import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  FormNumberInput,
  Text,
  PrimaryButton,
  FormTextInput,
} from '../../../components/controls';
import {car as carApi} from '../../../api/public';
import {order as orderApi} from '../../../api/private';
import authentication from '../../../lib/authentication';
import UserContext from '../../../components/context/UserContext';

const Index = ({navigation}) => {
  const [license, setLicense] = useState('');
  const [mileage, setMileage] = useState(null);
  const [car, setCar] = useState({});

  const {me} = useContext(UserContext);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerStyle: {
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      if (!license || license.length < 6) {
        setCar({});
        return;
      }

      try {
        const car = await carApi.fetch(license);
        setCar(car);
      } catch {
        setCar({});
      }
    };

    fetch();
  }, [license]);

  const isValid = license.length > 5;

  const onNext = async () => {
    if (!isValid) {
      return;
    }

    let dealer_id = null;
    if (me?.dealer_selection?.length === 1) {
      dealer_id = me?.dealer_selection[0];
    }

    const token = await authentication.getAccessToken();
    const result = await orderApi.new(token, {
      car: {
        ...car,
        license,
      },
      mileage,
      license,
      dealer_id,
    });

    if (result.success) {
      navigation.replace('Details', {order_id: result.order_id});
    }
  };

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <ScrollView>
        <View style={[globalStyles.headerView, styles.headerView]}>
          <Text style={globalStyles.headerText}>Gegevens van de auto</Text>
          <Text style={{marginTop: 10, fontSize: 18}}>
            Vul de gevraagde gegevens in voor het aanmaken van een concept
            aanvraag.
          </Text>
        </View>
        <View style={[globalStyles.contentView, styles.contentView]}>
          <View style={styles.article}>
            <View style={styles.label}>
              <Text style={globalStyles.mediumPlus}>Kenteken</Text>
            </View>
            <View style={styles.input}>
              <FormTextInput
                value={license}
                onChangeText={(text) =>
                  setLicense(text?.replace(/[^0-9a-z]/gi, '').toUpperCase())
                }
                label="Voer cijfer-lettercombinatie in"
                required={true}
                errorMessage={'Kenteken is verplicht'}
                inputProps={{
                  maxLength: 8,
                  autoCapitalize: 'characters',
                }}
              />
              {car?.name && <Text style={{lineHeight: 20}}>{car.name}</Text>}
            </View>
          </View>
          <View style={styles.article}>
            <View style={styles.label}>
              <Text style={globalStyles.mediumPlus}>
                Indicatie kilometerstand
              </Text>
            </View>
            <View style={styles.input}>
              <FormNumberInput
                label="Voer schatting in"
                value={mileage}
                onChangeNumber={setMileage}
                digits={0}
                inputProps={{
                  maxLength: 7,
                }}
              />
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={[globalStyles.footerView, styles.footerView]}>
        <PrimaryButton disabled={!isValid} onPress={onNext}>
          Concept maken
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
};

export default Index;
