const SERVICE_CODES = {
  MAINTEANCE: 'MAINTENANCE',
  REPAIR: 'REPAIR',
  TIRES: 'TIRES',
  APK: 'APK',
  CHECKS: 'CHECKS',
  DAMAGE: 'DAMAGE',
};

export const EXTRAS_CODES = {
  REPLACEMENT: 'REPLACEMENT',
  EXTRA_NONE: 'EXTRA_NONE',
  PICK_UP: 'PICK_UP',
  BIKE: 'BIKE',
  REPLACE_VEHICLE: 'REPLACE_VEHICLE',
};

export const Categories = {
  POPULAR: 'Meest gekozen',
  AIRCO: 'Airco',
  TIRES: 'Banden',
  DAMAGE: 'Schade',
  VACATION: 'Vakantie',
  OTHER: 'Overig',
};

export const Extras = {
  [EXTRAS_CODES.REPLACEMENT]: {
    title: 'Vervangend vervoer',
  },
};

export default SERVICE_CODES;
