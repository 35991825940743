import React, {useState} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import moment from 'moment';

import globalStyles from '../../../../../styles';
import styles from './styles';
import {
  Text,
  PrimaryButton,
  Pressable,
  CloseButton,
} from '../../../../controls';

const Slot = ({time, checked, onPress}) => (
  <Pressable onPress={() => onPress(time)}>
    <Text style={[styles.slot, styles.available, checked && styles.checked]}>
      {time.format('H:mm')}
    </Text>
  </Pressable>
);

const Time = (props) => {
  const {dealer, onChange, onDismiss} = props;
  const [date, setDate] = useState(props.datetime);

  const onSlotChange = async (time) => {
    const change = moment(date).utc();
    change.hours(time.hours());
    change.minutes(time.minutes());
    setDate(change);
  };

  const frames = [];
  if (dealer?.begin_checkin && dealer?.end_checkin) {
    let time = moment(dealer.begin_checkin, 'HH:mm:ss');
    const end = moment(dealer.end_checkin, 'HH:mm:ss');
    let count = 0;
    while (time.isSameOrBefore(end, 'minutes')) {
      const index = Math.floor(count / 4);
      if (!frames[index]) {
        frames[index] = [null, null, null, null];
      }

      const checked =
        moment(date).utc().format('HH:mm') === time.format('HH:mm');
      const slot = {
        time: time.clone(),
        checked,
      };

      frames[index][count % 4] = slot;

      time = time.add(30, 'minutes');
      count++;
    }
  }

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <ScrollView>
        <View style={[globalStyles.headerView, styles.headerView]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              height: 56,
              paddingHorizontal: 8,
              paddingVertical: 8,
              borderBottomWidth: 1,
              borderBottomColor: '#dcdcdc',
            }}>
            <CloseButton
              style={{position: 'absolute', top: 8, left: 8}}
              onPress={onDismiss}
            />
            <Text style={globalStyles.medium}>Tijd afspraak</Text>
          </View>
        </View>
        <View style={{flex: 1, paddingVertical: 25}}>
          {frames.map((slots, index) => (
            <View key={index} style={styles.frame}>
              {slots.map((slot, index) => (
                <View key={index}>
                  {slot ? (
                    <Slot {...slot} onPress={onSlotChange} />
                  ) : (
                    <View style={styles.slot} />
                  )}
                </View>
              ))}
            </View>
          ))}
        </View>
      </ScrollView>
      <View style={[globalStyles.footerView, styles.footerView]}>
        <PrimaryButton
          onPress={async () => {
            await onChange(date);
            onDismiss();
          }}>
          Opslaan
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
};

export default Time;
