import Toast from 'react-native-root-toast';
import {Platform} from 'react-native';

import {StaticDispatch} from '../../components/hoc/with-session-state';
import {Notification, OrderStatus} from '../../types';
import {updateInbox, updateJob} from '../../actions';

const handler = (alert, type, screen, params, stack, tag, license, status) => {
  if (type === Notification.JOB) {
    StaticDispatch(updateJob(license));
  } else if (type === Notification.INBOX) {
    StaticDispatch(updateInbox());
  }

  if (alert) {
    if (
      status === OrderStatus.Created ||
      status === OrderStatus.ApprovalFinished
    ) {
      if (Platform.OS === 'web') {
        navigator.serviceWorker.ready.then((registration) => {
          const message = JSON.stringify({
            alert,
            options: {
              data: {
                license,
                status,
              },
            },
          });

          registration.active.postMessage(message);
        });
      } else {
        Toast.show(alert, {
          duration: Toast.durations.SHORT,
          position: Toast.positions.BOTTOM,
          shadow: true,
          animation: true,
          hideOnPress: true,
          delay: 0,
        });
      }
    }
  }
};

export default handler;
