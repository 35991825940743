import React from 'react';

import Pressable from '../Pressable';
import DefaultIcon from './icons/default.svg';
import CheckedIcon from './icons/checked.svg';
import CheckedDisabledIcon from './icons/checked-disabled.svg';

const Toggle = ({style, disabled, checked, onPress}) => {
  return (
    <Pressable
      style={style}
      onPress={() => {
        if (!disabled) {
          onPress(!checked);
        }
      }}>
      {checked && !disabled && <CheckedIcon />}
      {checked && disabled && <CheckedDisabledIcon />}
      {!checked && <DefaultIcon />}
    </Pressable>
  );
};

export default Toggle;
