import React from 'react';
import {Button} from 'react-native-paper';

const PrimaryButton = ({disabled, icon, onPress, children}) => {
  return (
    <Button icon={icon} disabled={disabled} mode="contained" onPress={onPress}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
