import '@rmwc/drawer/styles';

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  ActivityIndicator,
  SafeAreaView,
  View,
  useWindowDimensions,
} from 'react-native';
import moment from 'moment';
import {RecyclerListView, DataProvider, LayoutProvider} from 'recyclerlistview';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../styles';
import {
  Text,
  Pressable,
  Link,
  Dialog,
  RadioButton,
} from '../../components/controls';
import {DealerHeader} from '../../components/shared';
import {DateText, Day, Tile} from '../../components/modules/Calendar';
import {order as orderApi, dealer as dealerApi} from '../../api/private';
import authentication from '../../lib/authentication';
import AddIcon from '../../images/md-icons/add/materialicons/24px.svg';
import CalendarTodayIcon from '../../images/md-icons/calendar_today/materialicons/24px.svg';
import UserContext from '../../components/context/UserContext';
import {useDispatch, useSelector} from '../../lib/hooks';

const AvailabilityOptions = {
  Available: {
    text: 'Beschikbaar',
    value: 'available',
    isChecked: (availability) =>
      !availability ||
      (availability.blocked === false && availability.capacity > 0),
  },
  Occupied: {
    text: 'Bezet',
    value: 'occupied',
    isChecked: (availability) => availability?.blocked === true,
  },
  Closed: {
    text: 'Gesloten',
    value: 'closed',
    isChecked: (availability) => availability?.capacity === 0,
  },
};

const SECTION_TITLE_HEIGHT = 40;
const TILE_HEIGHT = 96;
const NO_BOOKINGS_HEIGHT = 40;

const AvailabilityLink = ({dealer_id, availability, onChangeAvailability}) => {
  let text = 'Beschikbaar';
  if (availability) {
    const {blocked, capacity} = availability;

    if (blocked) {
      text = 'Bezet';
    } else if (capacity === 0) {
      text = 'Gesloten';
    }
  }

  return <Link onPress={() => onChangeAvailability(dealer_id)}>{text}</Link>;
};

const DialogChangeAvailability = ({
  availability,
  onChangeAvailability,
  onDismiss,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    AvailabilityOptions.Available.value,
  );
  useEffect(() => {
    Object.values(AvailabilityOptions).forEach((option) => {
      const is_checked = option.isChecked(availability);
      if (is_checked) {
        setSelectedValue(option.value);
      }
    });
  }, [availability]);

  return (
    <Dialog
      visible={true}
      title="Werkplaats"
      buttons={[
        {
          text: 'OK',
          onPress: () => {
            onChangeAvailability(selectedValue);
            onDismiss();
          },
        },
      ]}>
      {Object.values(AvailabilityOptions).map((option) => (
        <Pressable
          key={option.value}
          onPress={() => setSelectedValue(option.value)}
          style={{
            flexDirection: 'row',
            paddingVertical: 16,
          }}>
          <RadioButton checked={option.value === selectedValue} />
          <Text style={{marginLeft: 32}}>{option.text}</Text>
        </Pressable>
      ))}
    </Dialog>
  );
};

const Calendar = ({navigation, route}) => {
  const dispatch = useDispatch();
  const date = route.params?.date;

  const {height} = useWindowDimensions();

  const [width, setWidth] = useState(0);
  const [squareDims, setSquareDims] = useState(0);
  const [orders, setOrders] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changeAvailability, setChangeAvailability] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [sections, setSections] = useState([]);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [weekdayVisibleIndex, setWeekdayVisibleIndex] = useState(0);
  const [layoutProvider, setLayoutProvider] = useState(
    new LayoutProvider(
      () => {},
      () => {},
    ),
  );
  const [weekLayoutProvider, setWeekLayoutProvider] = useState(
    new LayoutProvider(
      () => {},
      () => {},
    ),
  );
  const automaticScroll = useRef(false);

  const listViewRef = useRef(null);
  const weekListViewRef = useRef(null);
  const dataProvider = useRef(new DataProvider((r1, r2) => r1 !== r2));
  const expensiveFetch = useRef(null);

  const jobNotifications = useSelector((state) => state.jobNotifications);

  const {me} = useContext(UserContext);

  useEffect(() => {
    const dates = {};
    const start = moment().add(-6, 'month');
    const end = moment().add(1, 'year').endOf('year').endOf('week');
    while (start.isSameOrBefore(end, 'date')) {
      const key = start.format('YYYY-MM-DD');
      dates[key] = {
        key,
        date: start.clone(),
        is_today: start.isSame(moment(), 'date'),
        data: {},
      };

      start.add(1, 'day');
    }

    dataProvider.current = dataProvider.current.cloneWithRows(
      Object.values(dates),
    );
    setSections(dates);
  }, [navigation]);

  expensiveFetch.current = useCallback(
    _.debounce(
      () => {
        const fetch = async () => {
          const token = await authentication.getAccessToken();
          const orders = await orderApi.list(token);

          setOrders(
            orders.reduce((acc, order) => {
              if (!acc[order.date]) {
                acc[order.date] = [];
              }

              acc[order.date].push(order);

              return acc;
            }, {}),
          );

          setLoading(false);

          if (!date) {
            const key = moment().format('YYYY-MM-DD');
            const todayIndex = dataProvider.current
              ?.getAllData()
              .findIndex((item) => item.key === key);

            const scroll = () =>
              setTimeout(() => {
                if (listViewRef.current?._virtualRenderer.getLayoutManager()) {
                  automaticScroll.current = true;
                  listViewRef.current?.scrollToIndex(todayIndex, false);
                  return;
                } else {
                  scroll();
                }
              }, 100);

            scroll();
          } else {
            listViewRef.current?.forceRerender();
          }

          setInitialized(true);
        };

        fetch();
      },
      1000,
      {leading: false, trailing: true},
    ),
    [initialized, date],
  );

  useEffect(() => {
    setLoading(true);
    expensiveFetch.current();
  }, [jobNotifications, me]);

  useEffect(() => {
    if (me) {
      const fetch = async () => {
        if (!availability) {
          const token = await authentication.getAccessToken();
          const response = await dealerApi.availability(token);
          setAvailability(response);
        }
      };

      fetch();
    }
  }, [me, dispatch, availability]);

  useEffect(() => {
    if (!sections) {
      return;
    }

    setLayoutProvider(
      new LayoutProvider(
        (index) => {
          return index;
        },
        (index, dim) => {
          const key = Object.values(sections)[index].key;

          dim.width = width;
          dim.height =
            SECTION_TITLE_HEIGHT +
            (orders[key]?.length * TILE_HEIGHT || NO_BOOKINGS_HEIGHT);
        },
      ),
    );
  }, [sections, orders, width]);

  useEffect(() => {
    weekListViewRef.current?.scrollToIndex(
      visibleIndex - (visibleIndex % 7),
      automaticScroll.current === false,
    );
    weekListViewRef.current?.forceRerender();

    automaticScroll.current = false;
  }, [visibleIndex]);

  useEffect(() => {
    const key = date?.format('YYYY-MM-DD');
    const index = dataProvider.current
      ?.getAllData()
      .findIndex((item) => item.key === key);

    if (index >= 0) {
      const scroll = () =>
        setTimeout(() => {
          if (listViewRef.current?._virtualRenderer.getLayoutManager()) {
            automaticScroll.current = true;
            listViewRef.current?.scrollToIndex(index, false);
            return;
          } else {
            scroll();
          }
        }, 100);

      scroll();
    }
  }, [date]);

  const onRowLayout = useCallback((e) => {
    const {layout} = e.nativeEvent;
    const breadth = layout.width / 7;
    setSquareDims({
      width: breadth,
      height: breadth,
    });
    setWidth(layout.width);

    setWeekLayoutProvider(
      new LayoutProvider(
        (index) => index,
        (type, dim) => {
          dim.width = breadth;
          dim.height = breadth * 2;
        },
      ),
    );
  }, []);

  const onChangeAvailability = useCallback(
    async (state) => {
      const token = await authentication.getAccessToken();
      const {dealer_id, date} = changeAvailability;

      const {capacity: defaultCapacity} = await dealerApi.details(
        token,
        dealer_id,
      );

      let blocked = false;
      let capacity = defaultCapacity;
      switch (state) {
        case AvailabilityOptions.Occupied.value:
          blocked = true;
          break;
        case AvailabilityOptions.Closed.value:
          capacity = 0;
          break;
        case AvailabilityOptions.Available.value:
        default:
          break;
      }

      const result = await dealerApi.saveAvailability(
        token,
        dealer_id,
        date,
        blocked,
        capacity,
      );

      if (result.success) {
        const availability = await dealerApi.availability(token);
        setAvailability(availability);
        setChangeAvailability(null);
        listViewRef.current?.forceRerender();
        weekListViewRef.current?.forceRerender();
      }
    },
    [changeAvailability],
  );

  const onScroll = useCallback(
    (e) => {
      const {contentOffset, contentSize} = e.nativeEvent;
      const index =
        contentOffset.y < contentSize.height - height
          ? listViewRef.current?.findApproxFirstVisibleIndex()
          : dataProvider.current?.getAllData().length - 1;

      setVisibleIndex(index);
    },
    [height],
  );

  const onWeekDayScroll = useCallback(() => {
    setWeekdayVisibleIndex(
      weekListViewRef.current?.findApproxFirstVisibleIndex(),
    );
  }, []);

  const onWeekDayRenderer = useCallback(
    (dataIndex, {date}) => (
      <View style={{flex: 1}}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            ...squareDims,
          }}>
          <Text
            style={[
              globalStyles.smallPlus,
              {color: '#828282', textTransform: 'capitalize'},
            ]}>
            {date.format('dd')[0]}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            ...squareDims,
          }}>
          <Day
            selected={visibleIndex === dataIndex}
            availability={availability}
            selectedDealers={me?.dealer_selection}
            date={date.clone()}
            onChangeDate={() => {
              listViewRef.current.scrollToIndex(dataIndex, false);
            }}
          />
        </View>
      </View>
    ),
    [squareDims, me, availability, visibleIndex],
  );

  const onSectionRenderer = useCallback(
    (type, {key, date}) => {
      const data = orders[key];
      return (
        <>
          <View
            style={{flexDirection: 'row', marginLeft: 8, paddingVertical: 8}}>
            <DateText date={moment(date)} />
            {!loading && me?.dealer_selection?.length === 1 && (
              <>
                <Text style={globalStyles.mediumPlus}>
                  &nbsp;&middot;&nbsp;
                </Text>
                <AvailabilityLink
                  dealer_id={me?.dealer_selection[0]}
                  availability={
                    availability && availability[me?.dealer_selection[0]][key]
                  }
                  onChangeAvailability={() =>
                    setChangeAvailability({
                      dealer_id: me?.dealer_selection[0],
                      date: key,
                    })
                  }
                />
              </>
            )}
          </View>
          {data?.length > 0 ? (
            data.map((order) => <Tile key={order.id} {...order} />)
          ) : (
            <>
              <Text style={{color: '#828282', marginLeft: 8}}>
                Geen boekingen
              </Text>
            </>
          )}
        </>
      );
    },
    [loading, me, orders, availability],
  );

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <View style={[globalStyles.headerView, styles.headerView]}>
          {initialized && (
            <DealerHeader
              icon={<CalendarTodayIcon fill="#ffffff" height={18} width={18} />}
              titleStyle={{textTransform: 'capitalize'}}
              title={moment(
                Object.values(sections)[weekdayVisibleIndex]?.date,
              ).format('MMMM')}
            />
          )}
          <View onLayout={onRowLayout}>
            <View
              style={{flexDirection: 'row', minHeight: squareDims?.height * 2}}>
              {initialized && Object.keys(sections).length > 0 && (
                <RecyclerListView
                  ref={weekListViewRef}
                  style={{flex: 1}}
                  dataProvider={dataProvider.current}
                  layoutProvider={weekLayoutProvider}
                  rowRenderer={onWeekDayRenderer}
                  isHorizontal={true}
                  onScroll={onWeekDayScroll}
                  scrollThrottle={100}
                />
              )}
            </View>
          </View>
        </View>
        <View style={[globalStyles.contentView, styles.contentView]}>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading && Object.keys(sections).length > 0 && (
            <RecyclerListView
              ref={listViewRef}
              style={{flex: 1}}
              contentContainerStyle={{paddingBottom: height}}
              dataProvider={dataProvider.current}
              layoutProvider={layoutProvider}
              rowRenderer={onSectionRenderer}
              scrollViewProps={{showsVerticalScrollIndicator: false}}
              scrollThrottle={100}
              onScroll={onScroll}
            />
          )}
        </View>

        <View style={{position: 'relative', width: '100%', height: 0}}>
          <Pressable
            style={styles.floatingIcon}
            onPress={() => navigation.navigate('Job', {screen: 'Car'})}>
            <AddIcon fill="#ffffff" />
          </Pressable>
        </View>
      </SafeAreaView>

      {!!changeAvailability && (
        <DialogChangeAvailability
          availability={
            availability[changeAvailability.dealer_id][changeAvailability.date]
          }
          onChangeAvailability={onChangeAvailability}
          onDismiss={() => setChangeAvailability(null)}
        />
      )}
    </>
  );
};

export default Calendar;
