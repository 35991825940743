import React, {useCallback, useLayoutEffect, useState, useEffect} from 'react';
import {SafeAreaView, View, ScrollView, TextInput} from 'react-native';
import _ from 'lodash';
import {useFocusEffect} from '@react-navigation/native';
import {HeaderBackButton} from '@react-navigation/stack';

import styles from './styles';
import globalStyles from '../../../styles';
import {order as orderApi, task as taskApi} from '../../../api/private';
import authentication from '../../../lib/authentication';
import ArrowBackIcon from '../../../images/md-icons/arrow_back/materialicons/24px.svg';
import {Checkbox, Link, Pressable, Text} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addCheckedTasks} from '../../../actions';
import Format from '../../../lib/format';

const Search = ({route, navigation}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;

  const [order, setOrder] = useState(null);
  const [results, setResults] = useState([]);
  const [prefix, setPrefix] = useState('');
  const checked = useSelector((state) => state.checkedTasks);

  const fetchTasks = useCallback(
    _.debounce(
      async (_prefix) => {
        if (_prefix?.length > 2) {
          const token = await authentication.getAccessToken();
          const tasks = await taskApi.search(token, {
            prefix: _prefix,
            license: order?.license,
          });

          setResults(tasks);
        } else {
          setResults([]);
        }
      },
      500,
      {leading: false, trailing: true},
    ),
    [setResults, order],
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTitle: () => (
        <TextInput
          style={[globalStyles.regular, {lineHeight: 16}]}
          autoCompleteType="off"
          value={prefix}
          placeholder="Zoek"
          placeholderTextColor="#dcdcdc"
          onChangeText={(text) => {
            setPrefix(text);
            fetchTasks(text);
          }}
        />
      ),
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ArrowBackIcon />}
          onPress={() => {
            navigation.goBack();
          }}
        />
      ),
    });
  }, [navigation, order_id, fetchTasks, prefix]);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const order = await orderApi.details(token, order_id);
      setOrder(order);
    };

    fetch();
  }, [order_id]);

  useFocusEffect(
    useCallback(() => {
      setTimeout(() => {
        fetchTasks(prefix);
      }, 100);
    }, [fetchTasks, prefix]),
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <ScrollView>
        {prefix?.length === 0 &&
          results.length === 0 &&
          checked.length === 0 && (
            <Text
              style={[globalStyles.large, styles.contentPadding, styles.gray]}>
              Zoek of filter voor resultaten...
            </Text>
          )}
        {(prefix?.length > 0 || results.length > 0 || checked.length > 0) && (
          <View style={styles.article}>
            {results.map((task, index) => (
              <Pressable
                key={index}
                style={styles.row}
                onPress={async () => {
                  let changes = checked;
                  if (checked.some((item) => item.id === task.id)) {
                    changes = changes.filter(
                      (item) =>
                        item.id !== task.id ||
                        item.description !== task.description,
                    );
                  } else {
                    changes.push(task);
                  }

                  dispatch(addCheckedTasks(changes));
                }}>
                <View style={styles.icon}>
                  <Checkbox
                    checked={checked.some((item) => item.id === task.id)}
                  />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flex: 1,
                  }}>
                  <Text
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>
                    {task.description}
                  </Text>
                  {!order?.lease_company && _.isNumber(task.price) && (
                    <Text>{Format.price(task.price)}</Text>
                  )}
                </View>
              </Pressable>
            ))}
            <View style={styles.row}>
              <Text>Niet gevonden?&nbsp;</Text>
              <Link
                onPress={() =>
                  navigation.navigate('NewTask', {
                    order_id,
                    description: prefix,
                  })
                }>
                Voeg taak toe
              </Link>
            </View>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};

export default Search;
