import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import moment from 'moment';

import globalStyles from '../../../styles';
import styles from './styles';
import Text from '../Text';

const Calendar = ({cellWidth, month, date, onPress}) => {
  const start = moment(month).startOf('month');
  const end = start.clone().endOf('month');
  const today = moment();

  const days = [];
  const weeks = end.diff(start.clone().startOf('week'), 'week');

  const height = (weeks + 1) * cellWidth;

  for (let i = 0; i <= end.diff(start, 'days'); i++) {
    const current = start.clone().startOf('day').add(12, 'hours').add(i, 'day');

    const past = current.isBefore(today, 'day');
    const selected = date && current.isSame(date, 'date');

    const top = current.diff(start.clone().startOf('week'), 'week') * cellWidth;
    const left = (current.isoWeekday() - 1) * cellWidth;

    const day = (
      <TouchableOpacity
        key={current.format('DD-MM-YYYY')}
        style={[
          styles.dayView,
          {width: cellWidth, height: cellWidth, top, left},
        ]}
        onPress={() => onPress(current.utc())}>
        <View
          style={[
            styles.available,
            past && styles.past,
            selected && styles.selected,
            styles.dayText,
          ]}>
          <Text
            style={[
              styles.availableText,
              past && styles.grayText,
              selected && styles.whiteText,
            ]}>
            {i + 1}
          </Text>
        </View>
      </TouchableOpacity>
    );
    days.push(day);
  }

  return (
    <>
      <View style={[styles.month, {height: cellWidth}]}>
        <Text style={globalStyles.largePlus}>
          {moment(month).format('MMMM YYYY')}
        </Text>
      </View>
      <View style={[styles.days, {maxWidth: cellWidth * 7, height}]}>
        {days}
      </View>
    </>
  );
};

export default React.memo(Calendar);
