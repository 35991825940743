import {StyleSheet} from 'react-native';

const styles = {
  headerView: {},
  contentView: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  article: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    minHeight: 56,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  noPadding: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingHorizontal: 16,
    paddingVertical: 15,
    minHeight: 56,
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
  },
  radioButtonDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    flex: 1,
    marginLeft: 10,
    paddingRight: 21,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
  },
  last: {
    borderBottomWidth: 0,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 13,
  },
  footerView: {
    justifyContent: 'flex-end',
  },
};

export default StyleSheet.create(styles);
