import React, {useState, useContext} from 'react';
import {View} from 'react-native';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';

import styles from './styles';
import globalStyles from '../../../../styles';
import {
  Link,
  Text,
  Pressable,
  FormSheetModal,
  Dialog,
  RadioButton,
} from '../../../controls';
import {OrderStatus} from '../../../../types';
import authentication from '../../../../lib/authentication';
import {order as orderApi} from '../../../../api/private';
import UserContext from '../../../context/UserContext';
import DateModal from './DateModal';
import TimeModal from './TimeModal';
import DeleteIcon from '../../../../images/md-icons/delete/materialicons/24px.svg';

const DialogChangeDealer = ({dealer, onChangeDealer, onDismiss}) => {
  const {me} = useContext(UserContext);

  const [selectedValue, setSelectedValue] = useState(dealer);

  return (
    <Dialog
      visible={true}
      onDismiss={onDismiss}
      title="Vestiging"
      buttons={[
        {
          text: 'OK',
          onPress: async () => {
            await onChangeDealer(selectedValue);
            onDismiss();
          },
        },
      ]}>
      <View>
        {me.dealers.map((item) => (
          <Pressable
            onPress={() => setSelectedValue(item)}
            style={{
              flexDirection: 'row',
              paddingVertical: 16,
            }}>
            <RadioButton checked={selectedValue.id === item.id} />
            <Text style={{marginLeft: 32}}>{item.city}</Text>
          </Pressable>
        ))}
      </View>
    </Dialog>
  );
};

const Appointment = ({
  order_id,
  status,
  isFinished,
  datetime,
  dealer,
  onRefresh,
}) => {
  const navigation = useNavigation();
  const [date, setDate] = useState(datetime);
  const [changeDealer, setChangeDealer] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onChange = async (date) => {
    const token = await authentication.getAccessToken();
    await orderApi.saveDate(token, order_id, date);
    setDate(date);
    onRefresh();
  };

  const onChangeDealer = async (dealer) => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.setDealer(token, order_id, {
      id: dealer.id,
    });

    if (result.success) {
      onRefresh();
    }
  };

  const onDelete = async () => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.delete(token, order_id);
    if (result?.success) {
      navigation.replace('Tabs', {screen: 'Calendar'});
    }
  };

  return (
    <>
      <View style={styles.article}>
        <View style={styles.articleHeader}>
          <Text style={globalStyles.mediumPlus}>Afspraak</Text>
          {status === OrderStatus.Draft && (
            <Pressable onPress={() => setConfirmDelete(true)}>
              <DeleteIcon fill="#c4c4c4" />
            </Pressable>
          )}
        </View>
        <View style={styles.row}>
          <Text>Vestiging</Text>
          {status !== OrderStatus.Draft ? (
            <Text>{dealer.city}</Text>
          ) : (
            <Link onPress={() => setChangeDealer(true)}>{dealer.city}</Link>
          )}
          {changeDealer && (
            <DialogChangeDealer
              dealer={dealer}
              onChangeDealer={onChangeDealer}
              onDismiss={() => setChangeDealer(false)}
            />
          )}
        </View>
        <View style={styles.row}>
          <Text>Datum</Text>
          {!isFinished ? (
            <Link onPress={() => setShowDateModal(true)}>
              {date && !!datetime
                ? moment(datetime).utc().format('dddd D MMMM YYYY')
                : 'Kiezen'}
            </Link>
          ) : (
            <Text>{moment(datetime).utc().format('dddd D MMMM YYYY')}</Text>
          )}
        </View>
        <View style={styles.row}>
          <Text>Tijd</Text>
          {!isFinished ? (
            <Link onPress={() => setShowTimeModal(true)}>
              {date && !!datetime
                ? moment(datetime).utc().format('HH:mm')
                : 'Kiezen'}
            </Link>
          ) : (
            <Text>{moment(datetime).utc().format('HH:mm')}</Text>
          )}
        </View>
      </View>
      {(showDateModal || showTimeModal) && (
        <FormSheetModal visible={true}>
          {showDateModal && (
            <DateModal
              datetime={date}
              dealer={dealer}
              onChange={onChange}
              onDismiss={() => {
                setShowDateModal(false);
              }}
            />
          )}
          {showTimeModal && (
            <TimeModal
              datetime={date}
              dealer={dealer}
              onChange={onChange}
              onDismiss={() => {
                setShowTimeModal(false);
              }}
            />
          )}
        </FormSheetModal>
      )}
      {confirmDelete && (
        <Dialog
          visible={true}
          buttons={[
            {text: 'Annuleren', onPress: () => setConfirmDelete(false)},
            {
              text: 'Verwijderen',
              textStyle: {color: '#972727'},
              onPress: onDelete,
            },
          ]}>
          <Text>
            Weet je zeker dat je deze concept aanvraag wilt verwijderen?
          </Text>
        </Dialog>
      )}
    </>
  );
};

export default Appointment;
