import React from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import styles from './styles';
import globalStyles from '../../../../styles';
import {Pressable, Text, Persona, OrderStatusIcon} from '../../../controls';
import {OrderStatus} from '../../../../types';
import DirectionsCarIcon from '../../../../images/md-icons/directions_car/materialicons/24px.svg';
import ElectricBikeIcon from '../../../../images/md-icons/electric_bike/materialicons/24px.svg';
import LocalCafeIcon from '../../../../images/md-icons/local_cafe/materialicons/24px.svg';
import MultipleStopIcon from '../../../../images/md-icons/multiple_stop/materialicons/24px.svg';
import PedalBikeIcon from '../../../../images/md-icons/pedal_bike/materialicons/24px.svg';
import EuroSymbolIcon from '../../../../images/md-icons/euro_symbol/materialicons/24px.svg';
import {EXTRAS_CODES} from '../../../../types/ServiceCodes';
import Format from '../../../../lib/format';

const ExtraIcon = ({extra, will_wait, wants_electric_bike, style}) => {
  if (!extra) {
    return null;
  }

  let icon;
  switch (extra) {
    case EXTRAS_CODES.EXTRA_NONE:
      icon = will_wait ? <LocalCafeIcon fill="#c4c4c4" style={style} /> : null;
      break;
    case EXTRAS_CODES.BIKE:
      icon = wants_electric_bike ? (
        <ElectricBikeIcon fill="#c4c4c4" style={style} />
      ) : (
        <PedalBikeIcon fill="#c4c4c4" style={style} />
      );
      break;
    case EXTRAS_CODES.REPLACE_VEHICLE:
      icon = <DirectionsCarIcon fill="#c4c4c4" style={style} />;
      break;
    case EXTRAS_CODES.PICK_UP:
      icon = <MultipleStopIcon fill="#c4c4c4" style={style} />;
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

const Tile = ({
  id,
  type,
  status,
  first_name,
  last_name,
  company_name,
  license,
  lease_company,
  is_order_active,
  extras,
  will_wait,
  wants_electric_bike,
  is_due,
}) => {
  const navigation = useNavigation();

  const imageInitials =
    first_name && last_name
      ? `${first_name[0]}${last_name[0]}`
      : first_name
      ? first_name[0]
      : last_name
      ? last_name[0]
      : '?';

  const name =
    first_name && last_name ? `${first_name} ${last_name}` : 'Particulier';

  return (
    <Pressable
      style={[styles.root, type === 'IMPORT' && {backgroundColor: '#f7f7f7'}]}
      onPress={() =>
        navigation.navigate('Job', {
          screen: 'Details',
          params: {order_id: id},
        })
      }>
      <View style={{flex: 0, minWidth: 'auto'}}>
        <Persona imageInitials={imageInitials} />
      </View>
      <View style={{flex: 1, marginHorizontal: 16}}>
        <Text
          style={[
            globalStyles.mediumPlus,
            {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          ]}>
          {OrderStatus.text(status)}
        </Text>
        <View style={{flexDirection: 'row'}}>
          <Text style={globalStyles.smallRegular}>{name}</Text>
          {company_name?.length > 0 && (
            <Text style={globalStyles.smallRegular}>
              &nbsp;&middot;&nbsp;{company_name}
            </Text>
          )}
        </View>
        <View style={{flexDirection: 'row'}}>
          <Text style={[globalStyles.smallRegular, {whiteSpace: 'nowrap'}]}>
            {Format.license(license)}
          </Text>
          {lease_company?.length > 0 && (
            <Text
              style={[
                globalStyles.smallRegular,
                {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              ]}>
              &nbsp;&middot;&nbsp;{lease_company}
            </Text>
          )}
        </View>
      </View>
      <View style={{flex: 0, minWidth: 'auto', alignItems: 'flex-end'}}>
        <OrderStatusIcon status={status} is_order_active={is_order_active} />
        <View style={{flexDirection: 'row', marginTop: 14}}>
          {is_due && <EuroSymbolIcon fill="#c4c4c4" />}
          {extras?.map((extra) => (
            <ExtraIcon
              key={extra}
              style={{marginLeft: 16}}
              extra={extra}
              will_wait={will_wait}
              wants_electric_bike={wants_electric_bike}
            />
          ))}
        </View>
      </View>
    </Pressable>
  );
};

export default Tile;
