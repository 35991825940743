import {StyleSheet} from 'react-native';
const styles = {
  article: {},
  articleHeader: {
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginBottom: -1,
    minHeight: 56,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
};

export default StyleSheet.create(styles);
