import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  main: {
    paddingTop: 42,
    paddingBottom: 42,
  },
  button: {
    marginTop: 16,
    marginBottom: 16,
  },
  footer: {
    flexDirection: 'row',
  },

  footerText: {
    fontSize: 12,
  },
});
