import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {SafeAreaView, View, ActivityIndicator, ScrollView} from 'react-native';
import moment from 'moment';
import {useNavigation, useRoute} from '@react-navigation/native';
import _ from 'lodash';
import {HeaderBackButton} from '@react-navigation/stack';

import globalStyles from '../../../styles';
import styles from './styles';
import {order as orderApi, messages as messagesApi} from '../../../api/private';
import authentication from '../../../lib/authentication';
import {
  Persona,
  Pressable,
  Text,
  OrderStatusIcon,
  OrderRequestStatusIcon,
  Dialog,
  Link,
} from '../../../components/controls';
import {PersonaSize} from '../../../components/controls/Persona';
import {HtmlView, RichTextField} from '../../../components/shared';
import {OrderStatus, OrderRequestStatus} from '../../../types';
import NotificationsIcon from '../../../images/md-icons/notifications/materialicons/24px.svg';
import SendIcon from '../../../images/md-icons/send/materialicons/24px.svg';
import TextSmsIcon from '../../../images/md-icons/textsms/materialicons/24px.svg';
import DoneIcon from '../../../images/md-icons/done/materialicons/24px.svg';
import DoneAllIcon from '../../../images/md-icons/done_all/materialicons/24px.svg';
import {useSelector} from '../../../lib/hooks';
import ChevronLeftIcon from '../../../images/md-icons/chevron_left/materialicons/24px.svg';
import TextsmsIcon from '../../../images/md-icons/textsms/materialicons/24px.svg';
import EmailIcon from '../../../images/md-icons/email/materialicons/24px.svg';

const MESSAGE_TYPE = 'MESSAGE';
const ORDER_TYPE = 'ORDER';
const ORDER_REQUEST_TYPE = 'ORDER_REQUEST';

const Message = ({
  first_name,
  last_name,
  is_generated,
  is_dealer,
  timestamp,
  message,
  is_read,
  reminder_sent,
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
      }}>
      <Persona
        is_dealer={is_dealer}
        imageInitials={`${first_name[0]}${(last_name && last_name[0]) || ''}`}
        size={PersonaSize.size40}
      />
      <View
        style={{
          flex: 1,
          paddingLeft: 16,
          paddingRight: 16,
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Text style={globalStyles.mediumBold}>
            {first_name}&nbsp;{last_name}
          </Text>
          <View style={{flexDirection: 'row'}}>
            <Text
              style={[
                globalStyles.small,
                {
                  color: '#7a7a7a',
                },
              ]}>
              {moment(timestamp).format('HH:mm')}
            </Text>
            {is_dealer && is_read && (
              <View style={{marginLeft: 4}}>
                <DoneAllIcon width={14} height={14} fill="#231fda" />
              </View>
            )}
            {is_dealer && !is_read && reminder_sent && (
              <View style={{marginLeft: 4}}>
                <DoneIcon width={14} height={14} fill="#828282" />
              </View>
            )}
          </View>
        </View>
        <HtmlView value={message} />
        {is_generated && (
          <Text style={{marginTop: 16, color: '#828282'}}>
            Dit bericht is automatisch gegenereerd.
          </Text>
        )}
      </View>
    </View>
  );
};

const Activity = ({
  type,
  status,
  description,
  timestamp,
  rank,
  order,
  is_read,
  reminder_sent,
}) => {
  const navigation = useNavigation();
  const route = useRoute();

  const isCurrentStatus = order.status === status && rank === 1;

  let info = status;
  let icon = <NotificationsIcon style={{marginRight: 10}} />;
  let link = null;
  switch (type) {
    case ORDER_TYPE: {
      switch (status) {
        case OrderStatus.DateChanged:
        case OrderStatus.TimeChanged:
          info = `Update · ${OrderStatus.text(status)}`;
          break;
        default:
          info = OrderStatus.text(status);
          break;
      }

      if (description) {
        info += ` (${description})`;
      }

      icon = (
        <OrderStatusIcon
          status={status}
          style={{marginRight: 20}}
          iconProps={_.pickBy(
            {fill: !isCurrentStatus && '#828282', width: 18, height: 18},
            _.identity,
          )}
        />
      );

      break;
    }
    case ORDER_REQUEST_TYPE: {
      info = OrderRequestStatus.text(status);

      icon = (
        <OrderRequestStatusIcon
          status={status}
          style={{marginRight: 20}}
          iconProps={_.pickBy(
            {fill: !isCurrentStatus && '#828282', width: 18, height: 18},
            _.identity,
          )}
        />
      );

      break;
    }
    default:
      break;
  }

  if (isCurrentStatus) {
    let linkText = 'Bekijken';
    if (
      status === OrderStatus.Draft ||
      status === OrderStatus.Created ||
      status === OrderStatus.Diagnosis ||
      status === OrderStatus.ApprovalFinished
    ) {
      linkText = 'Opvolgen';
    }

    link = (
      <Link
        textStyle={globalStyles.xSmallMedium}
        onPress={() =>
          navigation.navigate('Job', {
            screen: 'Details',
            params: {order_id: order.id, modal: route.params?.modal},
          })
        }>
        {linkText}
      </Link>
    );
  }

  return (
    <Pressable
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 8,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        backgroundColor: '#f2f2f2',
        borderRadius: 20,
      }}
      onPress={() =>
        navigation.navigate('Job', {
          screen: 'Details',
          params: {order_id: order.id},
        })
      }>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {icon}
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Text
            style={[
              globalStyles.xSmallMedium,
              {color: isCurrentStatus ? '#4a4a49' : '#828282'},
            ]}>
            {info}
            {link && <>&nbsp;&middot;&nbsp;{link}</>}
          </Text>
          <View style={{flexDirection: 'row'}}>
            <Text
              style={[
                globalStyles.xSmallMedium,
                {color: isCurrentStatus ? '#4a4a49' : '#828282'},
              ]}>
              {moment(timestamp).format('HH:mm')}
            </Text>
            {is_read && (
              <View style={{marginLeft: 4}}>
                <DoneAllIcon width={14} height={14} fill="#231fda" />
              </View>
            )}
            {!is_read && reminder_sent && (
              <View style={{marginLeft: 4}}>
                <DoneIcon width={14} height={14} fill="#828282" />
              </View>
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

const Chat = ({navigation, route}) => {
  const order_id = route.params?.order_id;
  const [clearCounter, setClearCounter] = useState(0);
  const [order, setOrder] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [images, setImages] = useState([]);
  const [showConfirmSms, setShowConfirmSms] = useState(false);

  const jobNotifications = useSelector((state) => state.jobNotifications);
  const inboxNotifications = useSelector((state) => state.inboxNotifications);
  const updatedJob = useSelector((state) => state.updatedJob);

  const scrollViewRef = useRef(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const order = await orderApi.details(token, order_id);
      const messages = await messagesApi.list(token, order_id);

      if (!order) {
        navigation.navigate('Tabs', {screen: 'MessagesOverview'});
        return;
      }

      setOrder(order);
      setMessages(messages);
      setLoading(false);
      navigation.setOptions({
        headerLeft: () => (
          <HeaderBackButton
            backImage={() => <ChevronLeftIcon />}
            onPress={() => {
              const parent = navigation.dangerouslyGetParent();
              const parentState = parent?.dangerouslyGetState();

              if (parentState?.routes.length > 1) {
                // goBack().
                // Doesn't use navigation.goBack() because nested in an iframe it can cause the
                // parent window to go back as well.
                navigation.pop();
              } else {
                navigation.replace('Tabs', {screen: 'Chat'});
              }
            }}
          />
        ),
        headerTitle: () => (
          <View style={{alignItems: 'center'}}>
            <Text style={globalStyles.medium}>
              {order?.first_name}&nbsp;{order?.last_name}
            </Text>
          </View>
        ),
        headerRight: () => (
          <Pressable
            style={{marginRight: 16}}
            onPress={() => setShowConfirmSms(true)}>
            <TextSmsIcon />
          </Pressable>
        ),
      });
    };

    fetch();
  }, [order_id, navigation]);

  const updateMessages = useCallback(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const messages = await messagesApi.list(token, order_id);
      const order = await orderApi.details(token, order_id);

      setMessages(messages);
      setOrder(order);
    };

    fetch();
  }, [order_id]);

  const license = order?.license;
  useEffect(() => {
    if (!jobNotifications || !updatedJob) {
      return;
    }

    if (updatedJob.toUpperCase() === license?.toUpperCase()) {
      updateMessages();
    }
  }, [jobNotifications, updatedJob, order_id, license, updateMessages]);

  useEffect(() => {
    updateMessages();
  }, [inboxNotifications, order_id, updateMessages]);

  const onSend = async () => {
    if (!message.length && !images.length) {
      return;
    }

    setMessage('');
    setImages([]);
    setClearCounter(clearCounter + 1);

    const token = await authentication.getAccessToken();
    const response = await messagesApi.sendMessage(
      token,
      order?.id,
      message,
      images,
    );

    if (response.success) {
      const newState = await messagesApi.list(token, order?.id);
      setMessages(newState);
    }
  };

  const onSendReminder = async () => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.sendReminder(token, order?.id);

    if (result.success) {
      setShowConfirmSms(false);
      updateMessages();
    }
  };

  const onSendEmailReminder = async () => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.sendEmailReminder(token, order?.id);

    if (result.success) {
      setShowConfirmSms(false);
      updateMessages();
    }
  };

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView
          style={[globalStyles.contentView, {paddingVertical: 12}]}
          ref={scrollViewRef}
          onContentSizeChange={() =>
            scrollViewRef.current.scrollToEnd({animated: true})
          }>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading &&
            messages &&
            Object.keys(messages).map((key) => (
              <View key={key} style={styles.article}>
                <Text
                  style={[
                    globalStyles.smallPlus,
                    {
                      textAlign: 'center',
                    },
                  ]}>
                  {moment(key, 'DD-MM-YYYY').format('DD MMM YYYY')}
                </Text>
                {messages[key].map((message) => {
                  switch (message.type) {
                    case MESSAGE_TYPE:
                      return <Message key={message.id} {...message} />;
                    default:
                      return (
                        <Activity key={message.id} {...message} order={order} />
                      );
                  }
                })}
              </View>
            ))}
        </ScrollView>
        <View
          style={{padding: 16, borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
          <RichTextField
            key={clearCounter}
            style={{flex: 1}}
            defaultValue={message}
            placeholder="Bericht toevoegen"
            buttons={
              <Pressable disabled={!message?.length} onPress={onSend}>
                <SendIcon fill={message?.length ? '#231fda' : '#dcdcdc'} />
              </Pressable>
            }
            onValueChanged={(value) => setMessage(value)}
            onImagesChanged={(value) => setImages(value)}
          />
        </View>
      </SafeAreaView>
      <Dialog
        visible={showConfirmSms}
        title="Berichten naar berijder"
        dismissable={false}
        buttons={[{text: 'Sluiten', onPress: () => setShowConfirmSms(false)}]}
        options={{hideDividers: true}}>
        <View>
          <View style={{paddingTop: 16, flexDirection: 'row', gap: 32}}>
            <TextsmsIcon fill="#4a4a49" />
            <View>
              <Text>SMS</Text>
              <Text style={[globalStyles.smallRegular, {color: '#828282'}]}>
                {order?.phone_number}
              </Text>
              <View style={{flexDirection: 'row'}}>
                <Link
                  textStyle={[
                    globalStyles.smallRegular,
                    {color: '#2907E3', textDecorationLine: 'none'},
                  ]}
                  onPress={onSendReminder}>
                  Verzenden
                </Link>
              </View>
            </View>
          </View>
          <View style={{paddingTop: 16, flexDirection: 'row', gap: 32}}>
            <EmailIcon fill="#4a4a49" />
            <View>
              <Text>Email</Text>
              <Text style={[globalStyles.smallRegular, {color: '#828282'}]}>
                {order?.email}
              </Text>
              <View style={{flexDirection: 'row'}}>
                <Link
                  textStyle={[
                    globalStyles.smallRegular,
                    {color: '#2907E3', textDecorationLine: 'none'},
                  ]}
                  onPress={onSendEmailReminder}>
                  Verzenden
                </Link>
              </View>
            </View>
          </View>
        </View>
      </Dialog>
    </>
  );
};

export default Chat;
