import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12,
  },
  logo: {
    width: 124,
    height: 34,
  },
  contentView: {
    flex: 1,
    paddingTop: 18,
    paddingLeft: 4,
    paddingRight: 4,
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    backgroundColor: '#f2f2f2',
  },
  header: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  article: {},
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  first: {
    borderTopWidth: 0,
  },
  last: {
    borderBottomWidth: 0,
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
  },
  gray: {
    color: '#828282',
  },
  link: {
    textDecorationLine: 'underline',
  },
  contentPadding: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  footerView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerRow: {
    flexDirection: 'row',
  },
  tileRoot: {
    backgroundColor: '#ffffff',
    margin: 4,
    padding: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#dcdcdc',
    minWidth: 'calc(50% - 8px)',
    maxWidth: 'calc(50% - 8px)',
    overflow: 'hidden',
  },
};

export default StyleSheet.create(styles);
