import React, {useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {HeaderBackButton} from '@react-navigation/stack';

import styles from './styles';
import globalStyles from '../../../styles';
import {FormTextInput, Text, PrimaryButton} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {setCheckedParts} from '../../../actions';

const CHECKED_VIEW = 1;

const NewPart = ({navigation, route}) => {
  const dispatch = useDispatch();
  const prefix = route.params?.prefix;
  const checked = useSelector((state) => state.checkedParts);

  const [newPart, setNewPart] = useState({designation: prefix});

  return (
    <SafeAreaView style={[globalStyles.mainView, styles.modalMainView]}>
      <View style={styles.contentView}>
        <View style={[globalStyles.headerView, styles.modalHeaderView]}>
          <HeaderBackButton
            style={styles.headerButton}
            onPress={() => navigation.goBack()}
          />
          <Text>Onderdeel toevoegen</Text>
        </View>
        <View style={globalStyles.contentView}>
          <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
            Naam onderdeel
          </Text>
          <FormTextInput
            value={newPart?.designation}
            inputProps={{placeholder: 'Voer tekst in'}}
            onChangeText={(designation) => setNewPart({designation})}
          />
        </View>
        <View style={[globalStyles.footerView, {justifyContent: 'flex-end'}]}>
          <PrimaryButton
            onPress={async () => {
              checked.push(newPart);
              dispatch(setCheckedParts([...checked]));
              navigation.navigate('SearchPart', {view: CHECKED_VIEW});
            }}>
            Toevoegen
          </PrimaryButton>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default NewPart;
