import endpoints from './endpoints';
import config from '../../configuration';
import {fetch} from '../../lib/fetch';

const {publicFunctionHostKey} = config;

export default {
  fetch: async (license) => {
    const result = await fetch(`${endpoints.car}/${license}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
    });

    if (!result || result.status !== 200) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};
