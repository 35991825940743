import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12,
  },
  logo: {
    width: 124,
    height: 34,
  },
  contentView: {
    flex: 1,
    paddingTop: 18,
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    backgroundColor: '#f2f2f2',
  },
  header: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  article: {},
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  first: {
    borderTopWidth: 0,
  },
  last: {
    borderBottomWidth: 0,
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
  },
  gray: {
    color: '#828282',
  },
  link: {
    textDecorationLine: 'underline',
  },
  contentPadding: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  floatingIcon: {
    width: 56,
    height: 56,
    position: 'absolute',
    bottom: 16,
    right: 20,
    backgroundColor: '#181716',
    borderRadius: 90,
    alignItems: 'center',
    justifyContent: 'center',
    shadowOffset: {width: 0, height: 3},
    shadowOpacity: 0.5,
    shadowColor: '#181716',
    shadowRadius: 10,
    elevation: 3,
  },
  footerView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerRow: {
    flexDirection: 'row',
  },
};

export default StyleSheet.create(styles);
