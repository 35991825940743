import React, {useState} from 'react';
import {View} from 'react-native';

import styles from './styles';
import globalStyles from '../../../../../styles';
import {Text, Pressable, FormSheetModal, Link} from '../../../../controls';
import Format from '../../../../../lib/format';
import {Extras, EXTRAS_CODES} from '../../../../../types/ServiceCodes';
import EditModal from '../ExtraEditModal';

const ExtraRow = ({order, code, extras, dealer, isLease, onRefresh}) => {
  const extra = extras?.find((item) => item.group === code);
  const {task} = extra || {};

  const [editExtra, setEditExtra] = useState(null);

  const onPress = () => {
    setEditExtra(true);
  };

  console.log(order);

  return (
    <>
      <Pressable style={styles.row} onPress={onPress}>
        <View style={{flex: 1}}>
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {Extras[code]?.title}
          </Text>
          {extra?.description?.length > 0 ? (
            <Text
              style={[globalStyles.small, {textDecorationLine: 'underline'}]}>
              {extra.service_code === EXTRAS_CODES.EXTRA_NONE ? (
                order.will_wait ? (
                  <>Wachten</>
                ) : (
                  <>Niet wachten</>
                )
              ) : (
                extra?.description
              )}
            </Text>
          ) : (
            <Link
              onPress={onPress}
              textStyle={[
                globalStyles.small,
                {textDecorationLine: 'underline'},
              ]}>
              Keuze maken
            </Link>
          )}
        </View>
        {!isLease && (
          <Text>
            <Text>{task?.price ? Format.price(task?.price) : '-'}</Text>
          </Text>
        )}
      </Pressable>
      {editExtra && (
        <FormSheetModal visible={true}>
          <EditModal
            onDismiss={async () => {
              setEditExtra(false);
              onRefresh();
            }}
            code={code}
            extra={extra}
            dealer={dealer}
            order={order}
            isLease={isLease}
          />
        </FormSheetModal>
      )}
    </>
  );
};

export default ExtraRow;
