import React, {useEffect, useContext} from 'react';
import {View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import authentication from '../../lib/authentication';
import notifications from '../../lib/notifications';
import globalStyles from '../../styles';
import styles from './styles';
import {Text, DefaultButton, Pressable} from '../../components/controls';
import BrowserContext from '../../components/context/BrowserContext';

const Signin = () => {
  const {embedded} = useContext(BrowserContext);

  useEffect(() => {
    authentication.loginInit();
  }, []);

  const onLogin = async () => {
    authentication.login().then(async () => {
      const userID = await authentication.getUserID();

      if (userID) {
        await notifications.requestPermissions();
        await notifications.register(userID);
      }
    });
  };

  if (embedded) {
    return <View />;
  }

  return (
    <SafeAreaView style={[globalStyles.mainView, styles.main]}>
      <View style={globalStyles.headerView}>
        <Text style={globalStyles.headerText}>Je profiel</Text>
      </View>
      <View style={globalStyles.contentView}>
        <Text>Log in om te beginnen</Text>
        <DefaultButton
          title="Inloggen"
          style={[styles.button, {backgroundColor: '#ff761a'}]}
          onPress={onLogin}>
          Inloggen
        </DefaultButton>
        {false && (
          <View style={styles.footer}>
            <Text style={styles.footerText}>
              Heb je nog geen account?&nbsp;
            </Text>
            <Pressable>
              <Text style={[styles.footerText, globalStyles.link]}>
                Aanmelden
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default Signin;
