import {StyleSheet} from 'react-native';

const styles = {
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
    minHeight: 56,
  },
  modalHeaderView: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
  },
  modalContentView: {
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
    marginLeft: 4,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default StyleSheet.create(styles);
