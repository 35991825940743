import React, {useRef, useState, useCallback, useEffect} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';
import {HeaderBackButton} from '@react-navigation/stack';
import {v4 as uuid} from 'uuid';

import styles from './styles';
import globalStyles from '../../../styles';
import {
  FormTextInput,
  Pressable,
  Text,
  Link,
} from '../../../components/controls';
import authentication from '../../../lib/authentication';
import {order as orderApi} from '../../../api/private';
import AddTaskIcon from '../../../images/md-icons/add_task/materialicons/24px.svg';
import CheckCircleIcon from '../../../images/md-icons/check_circle/materialicons/24px.svg';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {setCheckedParts} from '../../../actions';

const RESULTS_VIEW = 0;
const CHECKED_VIEW = 1;

const SearchPart = ({navigation, route}) => {
  const dispatch = useDispatch();
  const task = route.params?.task;
  const view = route.params?.view;
  const searchRef = useRef(null);
  const [results, setResults] = useState([]);
  const checked = useSelector((state) => state.checkedParts);

  const [resultsView, setResultsView] = useState(view || RESULTS_VIEW);
  const [prefix, setPrefix] = useState('');

  const fetchResults = useCallback(
    _.debounce(
      async (_prefix) => {
        const token = await authentication.getAccessToken();
        const results = await orderApi.fetchParts(token, _prefix);

        setResults(results);
      },
      500,
      {leading: false, trailing: true},
    ),
    [setResults],
  );

  useEffect(() => {
    setTimeout(() => {
      searchRef.current?.focus();
    }, 250);
  }, []);

  return (
    <SafeAreaView style={[globalStyles.mainView, styles.modalMainView]}>
      <View style={[globalStyles.headerView, styles.modalHeaderView]}>
        <HeaderBackButton
          style={styles.headerButton}
          onPress={() => {
            let parts = task?.parts;
            if (checked?.length) {
              parts = [
                ...parts,
                ...checked.map((part) => ({
                  generic_article_id: uuid(),
                  designation: part.designation,
                })),
              ];

              dispatch(setCheckedParts([]));
            }

            console.log(task);

            navigation.navigate('TaskEdit', {task: {...task, parts}});
          }}
        />
        <FormTextInput
          inputRef={searchRef}
          style={{flex: 1, marginLeft: 30}}
          value={prefix}
          inputProps={{
            placeholder: 'Zoek',
            theme: {
              colors: {primary: '#231fda', background: 'transparent'},
            },
          }}
          onChangeText={(text) => {
            setPrefix(text);
            fetchResults(text);
            setResultsView(RESULTS_VIEW);
          }}
        />
      </View>
      <View style={styles.contentView}>
        <View style={styles.article}>
          <View style={styles.articleHeader}>
            <Link
              textStyle={[
                globalStyles.medium,
                resultsView === RESULTS_VIEW && styles.gray,
              ]}
              onPress={() => setResultsView(RESULTS_VIEW)}>
              {results.length} resultaten
            </Link>
            <Link
              textStyle={[
                globalStyles.medium,
                resultsView === CHECKED_VIEW && styles.gray,
              ]}
              onPress={() => setResultsView(CHECKED_VIEW)}>
              {checked?.length || 0} geselecteerd
            </Link>
          </View>
          <ScrollView>
            {(resultsView === RESULTS_VIEW ? results : checked)?.map(
              (part, index) => (
                <Pressable
                  key={index}
                  style={[
                    styles.row,
                    {
                      paddingHorizontal: 20,
                      alignItems: 'center',
                      justifyContent: 'inherit',
                    },
                  ]}
                  onPress={async () => {
                    let changes = checked || [];
                    if (
                      checked?.some(
                        (item) => item.designation === part.designation,
                      )
                    ) {
                      changes = changes.filter(
                        (item) => item.designation !== part.designation,
                      );
                    } else {
                      changes.push({designation: part.designation});
                    }

                    dispatch(setCheckedParts([...changes]));
                  }}>
                  <View style={styles.icon}>
                    {checked?.some(
                      (item) => item.designation === part.designation,
                    ) ? (
                      <CheckCircleIcon />
                    ) : (
                      <AddTaskIcon />
                    )}
                  </View>
                  <Text>{part.designation}</Text>
                </Pressable>
              ),
            )}
            {resultsView === RESULTS_VIEW && (
              <View
                style={[
                  styles.row,
                  {
                    paddingHorizontal: 20,
                    alignItems: 'center',
                    justifyContent: 'inherit',
                  },
                ]}>
                <Text>Niet gevonden?&nbsp;</Text>
                <Link
                  onPress={() => {
                    navigation.navigate('NewPart', {prefix});
                  }}>
                  Voeg onderdeel toe
                </Link>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default SearchPart;
