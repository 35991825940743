import 'react-native-gesture-handler';
import 'intl';
import 'intl/locale-data/jsonp/nl-NL';
import 'moment/locale/nl';

import {StatusBar} from 'expo-status-bar';
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import {View, Platform} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import {ThemeProvider} from '@rmwc/theme';
import {
  configureFonts,
  DefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import {enableScreens} from 'react-native-screens';

import moment from 'moment';

import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';

import configuration from './configuration';
import authentication from './lib/authentication';
import AuthContext from './components/context/AuthContext';
import UserContext from './components/context/UserContext';
import BrowserContext from './components/context/BrowserContext';
import {Text, Portal, Dialog} from './components/controls';
import {user as userApi} from './api/private';
import withSessionState from './components/hoc/with-session-state';
import Notifications from './lib/notifications';

import {
  JobFlow,
  TabsFlow,
  DashboardFlow,
  TaskModalFlow,
  TasksModalFlow,
} from './flows';
import {SideDrawer} from './components/shared';
import {useDispatch, useSelector} from './lib/hooks';
import {setShowChangeDealer} from './actions';

import LogRocket from 'logrocket';
LogRocket.init(configuration.LOG_ROCKET_KEY);

enableScreens();

moment.locale('nl');

const withAuthContext = (App) => {
  return (props) => {
    const [auth, setAuth] = useState({authenticated: false});

    useEffect(() => {
      const check = async () => {
        const accessToken = await authentication.getAccessToken();

        setAuth({authenticated: !!accessToken});
      };

      authentication.addListener(async () => {
        await check();
      });
      check();
    }, []);

    return (
      <AuthContext.Provider value={auth}>
        <App {...props} />
      </AuthContext.Provider>
    );
  };
};

const withUserContext = (App) => {
  return (props) => {
    const {authenticated} = useContext(AuthContext);
    const [user, setUser] = useState(null);

    const fetch = useCallback(async () => {
      const token = await authentication.getAccessToken();
      try {
        const me = await userApi.me(token);
        if (!me) {
          throw new Error('Not able to retrieve user from api.');
        }

        setUser(me);

        LogRocket.identify(me.user_id, me.user_settings.logrocket);
      } catch (e) {
        await authentication.logout();
      }
    }, []);

    useEffect(() => {
      if (!authenticated) {
        return;
      }

      fetch();
    }, [authenticated, fetch]);

    return (
      <UserContext.Provider value={{me: user, update: fetch}}>
        <App {...props} />
      </UserContext.Provider>
    );
  };
};

const Stack = createStackNavigator();

const prefix = Linking.makeUrl('/');
const routes = {
  screens: {
    Job: {
      path: '',
      screens: {
        Services: ':order_id/Services',
        ListTasks: ':order_id/ListTasks',
        SearchTasks: ':order_id/SearchTasks',
        NewTask: ':order_id/NewTask',
        Chat: ':order_id/Chat',
        Details: 'Details',
      },
    },
    Tabs: {
      path: '',
      screens: {
        MessagesOverview: '',
        DashboardOverview: 'Dashboard',
        Calendar: {
          path: 'Calendar',
          parse: {
            date: (date) =>
              (date ? moment(date, 'YYYY-MM-DD') : moment()).add(12, 'hours'),
          },
          stringify: {
            date: (date) =>
              (date ? moment(date) : moment()).format('YYYY-MM-DD'),
          },
        },
      },
    },
  },
};

const linking = {
  prefixes: [prefix],
  config: routes,
};

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#231fda',
    background: '#ffffff',
    text: '#49494a',
  },
  fonts: configureFonts({
    web: {
      regular: {
        fontFamily: 'Roboto_400Regular',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'Roboto_500Medium',
        fontWeight: '500',
      },
    },
    native: {
      regular: {
        fontFamily: 'Roboto_400Regular',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'Roboto_500Medium',
        fontWeight: '500',
      },
    },
  }),
};

const withBrowserContext = (App) => {
  return (props) => {
    // Check to see if the page is embedded inside a web extension or iframe.
    let embedded = false;
    if (Platform.OS === 'web') {
      try {
        embedded = window?.parent.location?.href === null;
      } catch {
        embedded = true;
      }
    }

    if (window) {
      window.bahnkick = {
        ...(window.bahnkick || {}),
        embedded,
      };
    }

    return (
      <BrowserContext.Provider value={{embedded}}>
        <App {...props} />
      </BrowserContext.Provider>
    );
  };
};

const PERSISTENCE_KEY = '__BK_DEALER_NAV_STATE__';

const App = withBrowserContext(
  withAuthContext(
    withUserContext(
      withSessionState(() => {
        const dispatch = useDispatch();
        const {me, update} = useContext(UserContext);
        const {embedded} = useContext(BrowserContext);

        const showChangeDealerDialog = useSelector(
          (state) => state.changeDealer,
        );
        const navigationRef = useRef();
        const [initialized, setInitialized] = useState(false);

        const [isReady, setIsReady] = useState(false);
        const [initialState, setInitialState] = useState();
        const [storeState, setStoreState] = useState(false);

        let [fontsLoaded] = useFonts({
          Roboto_100Thin,
          Roboto_300Light,
          Roboto_400Regular,
          Roboto_500Medium,
          Roboto_700Bold,
          Roboto_900Black,
        });

        useEffect(() => {
          Notifications.initialize();
          return () => {
            Notifications.kill();
          };
        }, []);

        useEffect(() => {
          const restoreState = async () => {
            try {
              const initialUrl = await Linking.getInitialURL();

              if (
                (Platform.OS !== 'web' && initialUrl == null) ||
                (embedded &&
                  initialUrl &&
                  !new URL(initialUrl).searchParams.get('modal'))
              ) {
                setStoreState(true);

                if (new URL(initialUrl).searchParams.get('norestore')) {
                  return;
                }

                // Only restore state if there's no deep link and we're not on web
                const savedStateString = await AsyncStorage.getItem(
                  PERSISTENCE_KEY,
                );
                const state = savedStateString
                  ? JSON.parse(savedStateString)
                  : undefined;

                if (state !== undefined) {
                  setInitialState(state);
                }
              }
            } finally {
              setIsReady(true);
            }
          };

          if (!isReady) {
            restoreState();
          }
        }, [isReady, embedded]);

        useEffect(() => {
          if (!me || initialized) {
            return;
          }

          const route = navigationRef.current?.getCurrentRoute();
          const dealer_id = route?.params?.dealer_id;
          if (
            me.dealers.map((dealer) => dealer.id).includes(dealer_id) &&
            !me.dealer_selection.includes(dealer_id)
          ) {
            dispatch(setShowChangeDealer(true));
          }

          setInitialized(true);
        }, [initialized, me, dispatch]);

        const onChangeDealer = useCallback(() => {
          const route = navigationRef.current?.getCurrentRoute();
          const dealer_id = route?.params?.dealer_id;

          const fetch = async () => {
            const token = await authentication.getAccessToken();
            await userApi.update(token, {dealer_selection: [dealer_id]});
            update();
            dispatch(setShowChangeDealer(false));
          };

          fetch();
        }, [dispatch, update]);

        if (!fontsLoaded || !isReady) {
          return <View />;
        }

        return (
          <PaperProvider theme={theme}>
            <StatusBar />
            <NavigationContainer
              ref={navigationRef}
              linking={linking}
              fallback={<Text>Loading...</Text>}
              documentTitle={{
                formatter: () => `Bahnkick Dealer`,
              }}
              initialState={initialState}
              onStateChange={(state) => {
                if (storeState) {
                  AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
                }
              }}>
              <Stack.Navigator
                initialRouteName="Tabs"
                detachInactiveScreens={false}
                screenOptions={{
                  cardStyle: {
                    flex: 1,
                    backgroundColor: 'rgba(74, 74, 72, 0.5)',
                  },
                }}>
                <Stack.Screen
                  name="Tabs"
                  options={{
                    headerShown: false,
                  }}
                  component={TabsFlow}
                />
                <Stack.Screen
                  name="Job"
                  options={{
                    headerShown: false,
                  }}
                  component={JobFlow}
                />
                <Stack.Screen
                  name="Dashboard"
                  options={{
                    headerShown: false,
                  }}
                  component={DashboardFlow}
                />
                <Stack.Screen
                  name="TaskModal"
                  options={{
                    headerShown: false,
                  }}
                  component={TaskModalFlow}
                />
                <Stack.Screen
                  name="TasksModal"
                  options={{
                    headerShown: false,
                  }}
                  component={TasksModalFlow}
                />
              </Stack.Navigator>
            </NavigationContainer>
            <Portal>
              <ThemeProvider options={{primary: '#231fda'}}>
                <SideDrawer />
                {showChangeDealerDialog && (
                  <Dialog
                    visible={true}
                    title="Vestiging wisselen?"
                    buttons={[
                      {
                        text: 'Annuleren',
                        onPress: () => dispatch(setShowChangeDealer(false)),
                      },
                      {text: 'OK', onPress: onChangeDealer},
                    ]}
                    options={{hideDividers: true}}>
                    <Text>
                      Hiermee verander je de geselecteerde vestiging om de
                      gevraagde info te kunnen zien.
                    </Text>
                  </Dialog>
                )}
              </ThemeProvider>
            </Portal>
          </PaperProvider>
        );
      }),
    ),
  ),
);

export default App;
