import {StyleSheet} from 'react-native';
const styles = {
  root: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderRadius: 4,
    minHeight: 88,
    paddingHorizontal: 16,
    marginVertical: 4,
    alignItems: 'center',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.25,
    shadowColor: '#000000',
    shadowRadius: 4,
    elevation: 3,
  },
};

export default StyleSheet.create(styles);
