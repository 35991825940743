const OrderRequestStatus = {
  Created: 'CREATED',
  Pending: 'PENDING',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
  Finished: 'FINISHED',
  Ready: 'READY',
  Invoice: 'INVOICE',

  text: (status) => {
    switch (status) {
      case OrderRequestStatus.Created:
        return 'Aangemaakt';
      case OrderRequestStatus.Pending:
        return 'Voorstel';
      case OrderRequestStatus.Rejected:
        return 'Voorstel afgekeurd';
      case OrderRequestStatus.Approved:
        return 'Voorstel goedgekeurd';
      case OrderRequestStatus.Finished:
        return 'Voorstel afgerond';
      case OrderRequestStatus.Ready:
        return 'Gereed';
      default:
        return status;
    }
  },
};

export default OrderRequestStatus;
