import React, {useState} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';

import globalStyles from '../../../../../styles';
import styles from './styles';
import {
  PrimaryButton,
  FormTextInput,
  Text,
  CloseButton,
} from '../../../../controls';
import authentication from '../../../../../lib/authentication';
import {order as orderApi} from '../../../../../api/private';

/* eslint-disable no-control-regex */
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
const phoneRegex = /^06[0-9]{8}/gi;

const Index = (props) => {
  const {order_id} = props;

  const [firstName, setFirstName] = useState(props.first_name);
  const [lastName, setLastName] = useState(props.last_name);
  const [email, setEmail] = useState(props.email);
  const [phone_number, setPhoneNumber] = useState(props.phone_number);
  const [emailError, setEmailError] = useState(
    email && !email?.match(emailRegex),
  );
  const [phoneError, setPhoneError] = useState(
    phone_number && !phone_number?.match(phoneRegex),
  );
  const [company_name, setCompanyName] = useState(props.company_name?.trim());

  const isValid =
    email?.match(emailRegex) &&
    phone_number?.match(phoneRegex) &&
    !!firstName &&
    !!lastName;

  const onSave = async () => {
    if (!isValid) {
      return;
    }

    const token = await authentication.getAccessToken();
    await orderApi.setDriver(token, order_id, {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number,
      company_name,
    });

    props.onDismiss();
  };

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView>
          <View
            style={[
              globalStyles.headerView,
              {paddingLeft: 0, paddingRight: 0, paddingBottom: 0},
            ]}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                height: 56,
                paddingHorizontal: 8,
                paddingVertical: 8,
              }}>
              <CloseButton
                style={{position: 'absolute', top: 8, left: 8}}
                onPress={props.onDismiss}
              />
              <Text style={globalStyles.medium}>Berijder</Text>
            </View>
          </View>
          <View style={[globalStyles.contentView, styles.noPadding]}>
            <View style={styles.article}>
              <Text style={[globalStyles.mediumPlus, styles.label]}>Naam</Text>
              {_.isString(company_name) && (
                <FormTextInput
                  value={company_name || ''}
                  onChangeText={setCompanyName}
                  style={styles.input}
                  required={true}
                  errorMessage={'Bedrijfsnaam is verplicht'}
                  inputProps={{
                    placeholder: 'Bedrijfsnaam',
                  }}
                />
              )}
              <FormTextInput
                value={firstName || ''}
                onChangeText={setFirstName}
                style={styles.input}
                required={true}
                errorMessage={'Voornaam is verplicht'}
                inputProps={{
                  placeholder: 'Voornaam',
                }}
              />
              <FormTextInput
                value={lastName || ''}
                onChangeText={setLastName}
                style={[styles.input, styles.inputLast]}
                required={true}
                errorMessage={'Achternaam is verplicht'}
                inputProps={{
                  placeholder: 'Achternaam',
                }}
              />
            </View>
            <View style={styles.article}>
              <View style={styles.label}>
                <Text style={globalStyles.mediumPlus}>Contactgegevens</Text>
              </View>
              <FormTextInput
                value={phone_number || ''}
                onChangeText={setPhoneNumber}
                onEndEditing={() => {
                  const valid =
                    !phone_number?.length && phone_number?.match(phoneRegex);
                  setPhoneError(!valid);
                }}
                style={styles.input}
                hasError={phoneError}
                required={true}
                errorMessage={
                  !phone_number?.length
                    ? 'Mobiele telefoonnummer is verplicht'
                    : !phone_number?.match(phoneRegex)
                    ? 'Ongeldige waarde mobiele telefoonnummer'
                    : null
                }
                digits={0}
                inputProps={{
                  maxLength: 10,
                  keyboardType: 'numeric',
                  placeholder: 'Mobiele telefoonnummer',
                }}
              />
              <FormTextInput
                value={email || ''}
                onChangeText={setEmail}
                onEndEditing={() => {
                  const valid = email?.length && email?.match(emailRegex);
                  setEmailError(!valid);
                }}
                style={[styles.input, styles.inputFirst]}
                hasError={emailError}
                required={true}
                errorMessage={
                  !email?.length
                    ? 'Email verplicht'
                    : !email?.match(emailRegex)
                    ? 'Email heeft een ongeldige waarde'
                    : null
                }
                inputProps={{
                  keyboardType: 'email-address',
                  placeholder: 'Emailadres',
                }}
              />
            </View>
          </View>
        </ScrollView>
        <View style={[globalStyles.footerView, styles.footerView]}>
          <PrimaryButton disabled={!isValid} onPress={onSave}>
            Opslaan
          </PrimaryButton>
        </View>
      </SafeAreaView>
    </>
  );
};

export default Index;
