import {StyleSheet} from 'react-native';
const styles = {
  row: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
};

export default StyleSheet.create(styles);
