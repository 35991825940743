import React, {useState} from 'react';
import {View, ImageBackground, SafeAreaView} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';

import {FormSheetModal, Pressable} from '../../controls';
import styles from './styles';

const HtmlView = ({style, value, clampLines = null}) => {
  const [showImage, setShowImage] = useState(null);

  if (showImage) {
    return (
      <FormSheetModal
        animationType="slide"
        onRequestClose={() => {
          setShowImage(null);
        }}>
        <SafeAreaView style={{flex: 1, backgroundColor: '#000'}}>
          <ImageBackground
            style={styles.imageView}
            imageStyle={{resizeMode: 'contain'}}
            source={{uri: showImage}}>
            <View style={styles.closeButtonView}>
              <Pressable
                style={styles.closeButton}
                onPress={() => setShowImage(null)}>
                <Icon style={{color: 'white'}} name="times" size={32} />
              </Pressable>
            </View>
          </ImageBackground>
        </SafeAreaView>
      </FormSheetModal>
    );
  }

  let _style = null;
  if (clampLines) {
    _style = {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: clampLines,
      height: `${clampLines * 1.3}em`,
    };
  }

  return (
    <View style={style}>
      <div style={_style} dangerouslySetInnerHTML={{__html: value}} />
    </View>
  );
};

export default HtmlView;
