import React from 'react';
import {View, ScrollView, SafeAreaView} from 'react-native';
import moment from 'moment';
import Icon from 'react-native-vector-icons/Ionicons';

import styles from './styles';
import globalStyles from '../../../styles';
import {Text, Pressable} from '../../../components/controls';
import Format from '../../../lib/format';

const TaskView = ({navigation, route}) => {
  const task = route.params?.task;

  if (!task) {
    return <></>;
  }

  const parts_total = task.is_overall_price
    ? 0
    : task?.parts.reduce((acc, part) => acc + part.amount, 0);
  const task_total =
    (task?.task_amount +
      parts_total +
      task?.markup_fee +
      task?.environment_surcharge) *
    ((100 + task?.vat_percentage) / 100);

  const rows = [
    {
      description: 'Werkzaamheden',
      amount: task?.task_amount * ((100 + task?.vat_percentage) / 100),
    },
  ]
    .concat(
      task?.parts
        .filter((part) => part.id)
        .map((part) => ({
          description: part.designation,
          no: part.description,
          amount: task.is_overall_price
            ? null
            : part.amount * ((100 + task?.vat_percentage) / 100),
        })),
    )
    .concat([
      task?.markup_fee > 0
        ? {
            description: 'Klein materiaal',
            amount: task?.markup_fee * ((100 + task?.vat_percentage) / 100),
          }
        : null,
      task?.environment_surcharge > 0
        ? {
            description: 'Milieuheffing',
            amount:
              task?.environment_surcharge *
              ((100 + task?.vat_percentage) / 100),
          }
        : null,
    ])
    .filter(Boolean);

  let duration;
  if (task?.diagnosis_datetime) {
    const timestamp = moment(task?.diagnosis_datetime);
    duration = timestamp.format('DD-MM-YYYY');
    if (timestamp.isSame(moment(), 'day')) {
      duration = `${moment
        .duration(moment().diff(timestamp))
        .humanize()} geleden`;
    }
  }

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <View style={[globalStyles.headerView, styles.modalHeaderView]}>
        <Pressable
          style={styles.closeButton}
          onPress={() => navigation.goBack()}>
          <Icon name="ios-close" size={36} light={true} />
        </Pressable>
        <Text>Details</Text>
      </View>
      <View style={[globalStyles.contentView, styles.modalContentView]}>
        <ScrollView>
          <View
            style={[
              styles.row,
              {justifyContent: 'inherit', alignItems: 'center'},
            ]}>
            <Text style={globalStyles.mediumPlus}>{task.description}</Text>
          </View>
          {rows.map((row, index) => (
            <View key={index} style={styles.row}>
              <View>
                <Text style={globalStyles.medium}>{row.description}</Text>
                {row.no && <Text style={globalStyles.small}>{row.no}</Text>}
              </View>
              <Text style={globalStyles.medium}>
                {row.amount !== null ? Format.price(row.amount) : ''}
              </Text>
            </View>
          ))}
          <View style={[styles.row, {marginBottom: -1}]}>
            <View>
              <Text style={globalStyles.mediumSemi}>Totaal</Text>
              <Text style={globalStyles.small}>Inclusief BTW</Text>
            </View>
            <Text style={globalStyles.mediumSemi}>
              {Format.price(task_total)}
            </Text>
          </View>
          {task.is_required && (
            <>
              <View
                style={[
                  styles.row,
                  {borderTopWidth: 1, borderTopColor: '#dcdcdc'},
                ]}>
                <Text style={globalStyles.mediumPlus}>Reden werkvoorstel</Text>
              </View>
              <View style={styles.row}>
                <Text>Voldoet niet aan APK eis.</Text>
              </View>
            </>
          )}
        </ScrollView>
        {task.diagnosis && (
          <View
            style={{
              backgroundColor: '#f2f2f2',
            }}>
            <View style={styles.row}>
              <Text style={globalStyles.mediumPlus}>{task.diagnosis_user}</Text>
              <Text style={globalStyles.smallPlus}>{duration}</Text>
            </View>
            <Text style={{paddingHorizontal: 20, paddingBottom: 20}}>
              {task.diagnosis}
            </Text>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default TaskView;
