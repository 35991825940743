import React, {useEffect, useState, useContext} from 'react';
import {View} from 'react-native';
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';
import {Badge} from 'react-native-paper';
import {useFocusEffect} from '@react-navigation/core';
import memoize from 'memoizee';

import {Overview as MessagesOverview} from '../screens/messages';
import {Overview} from '../screens/dashboard';
import Calendar from '../screens/calendar';
import withProtectedScreen from '../components/hoc/with-protected-screen';
import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChatIcon from '../images/md-icons/chat/materialicons/24px.svg';
import DashboardIcon from '../images/md-icons/dashboard/materialicons/24px.svg';
import CalendarTodayIcon from '../images/md-icons/calendar_today/materialicons/24px.svg';
import {useSelector} from '../lib/hooks';
import authentication from '../lib/authentication';
import {dealer as dealerApi} from '../api/private';
import UserContext from '../components/context/UserContext';

const TabsStack = createMaterialBottomTabNavigator();

const memoized = memoize(
  async () => {
    const token = await authentication.getAccessToken();
    const result = await dealerApi.messagesUnread(token);
    return result;
  },
  {promise: true, maxAge: 5000},
);

const fetchUnread = async (callback) => {
  const result = await memoized();
  callback(result);
};

const TabChatIcon = ({color}) => {
  const {me} = useContext(UserContext);
  const inboxNotifications = useSelector((state) => state.inboxNotifications);
  const [unread, setUnread] = useState(null);

  useFocusEffect(() => {
    fetchUnread((result) => {
      setUnread(result?.unread);
    });
  }, []);

  useEffect(() => {
    fetchUnread((result) => {
      setUnread(result?.unread);
    });
  }, [inboxNotifications, me]);

  return (
    <View style={{width: 24, height: 24}}>
      <ChatIcon fill={color} />
      <Badge
        visible={unread > 0}
        style={{position: 'absolute', top: -2, right: -2}}
        size={8}
      />
    </View>
  );
};

export default () => {
  return (
    <TabsStack.Navigator
      initialRouteName="MessagesOverview"
      shifting={true}
      activeColor="#231fda"
      inactiveColor="#757575"
      barStyle={{
        backgroundColor: '#ffffff',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.1,
        shadowColor: '#000',
        shadowRadius: 5,
        elevation: 3,
      }}>
      <TabsStack.Screen
        name="MessagesOverview"
        options={{
          headerShown: false,
          title: 'Chat',
          tabBarIcon: (props) => <TabChatIcon {...props} />,
        }}
        component={withProtectedScreen(withScreenIsFocused(MessagesOverview))}
      />
      <TabsStack.Screen
        name="DashboardOverview"
        options={{
          headerShown: false,
          title: 'Dashboard',
          tabBarIcon: ({color, size}) => (
            <DashboardIcon width={size} height={size} fill={color} />
          ),
        }}
        component={withProtectedScreen(withScreenIsFocused(Overview))}
      />
      <TabsStack.Screen
        name="Calendar"
        options={{
          headerShown: false,
          title: 'Kalender',
          tabBarIcon: ({color, size}) => (
            <CalendarTodayIcon width={size} height={size} fill={color} />
          ),
        }}
        component={withProtectedScreen(withScreenIsFocused(Calendar))}
      />
    </TabsStack.Navigator>
  );
};
