import React, {useState, useEffect, useCallback} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../../../styles';
import {
  CloseButton,
  FormNumberInput,
  FormTextInput,
  Pressable,
  Toggle,
  Text,
  Select,
  RadioButton,
  PrimaryButton,
} from '../../../../controls';
import authentication from '../../../../../lib/authentication';
import {
  booking as bookingApi,
  address as addressApi,
} from '../../../../../api/public';
import {order as orderApi} from '../../../../../api/private';
import {Extras, EXTRAS_CODES} from '../../../../../types/ServiceCodes';
import ExpandLessIcon from '../../../../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../../../../images/md-icons/expand_more/materialicons/24px.svg';

const Address = ({postalCode, houseNumber, annex, onChange}) => {
  const [address, setAddress] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  const _fetchAddress = useCallback(
    _.debounce(async (_postalCode, _houseNumber, _annex) => {
      const {address} = await addressApi.fetch(
        _postalCode,
        _houseNumber,
        _annex,
      );
      setAddress(address);
    }, 500),
    [setAddress],
  );

  useEffect(() => {
    if (postalCode?.length === 6) {
      _fetchAddress(postalCode, houseNumber, annex);
    }
  }, [postalCode, houseNumber, annex, _fetchAddress]);

  const _onChange = (address) => {
    onChange({
      postalCode,
      houseNumber,
      annex,
      ...address,
    });
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <FormTextInput
          value={postalCode}
          style={{width: 115}}
          label="Postcode"
          inputProps={{
            maxLength: 6,
            autoCapitalize: 'characters',
          }}
          onChangeText={(text) => _onChange({postalCode: text.toUpperCase()})}
        />
        <FormTextInput
          value={houseNumber}
          style={{width: 115}}
          label="Huisnr."
          inputProps={{
            maxLength: 6,
          }}
          onChangeText={(text) => _onChange({houseNumber: text})}
        />
        <FormTextInput
          value={annex}
          style={{width: 115}}
          label="Toevoeging"
          onChangeText={(text) => _onChange({annex: text})}
        />
      </View>
      <Text style={{lineHeight: 32}}>{address}</Text>
    </>
  );
};

const Edit = ({code, extra, order, dealer, isLease, onDismiss}) => {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(
    extra && {
      id: extra.dealer_service_id,
      value: extra.service_code,
      text: extra.description,
    },
  );
  const [expandReason, setExpandReason] = useState(!!extra?.task?.diagnosis);
  const [will_wait, setWillWait] = useState(order?.will_wait);
  const [wants_electric_bike, setWantsElectricBike] = useState(
    order?.wants_electric_bike,
  );
  const [pick_up_location, setPickupLocation] = useState(
    order?.pickup_address && {
      pick_up: order?.pickup_address,
      deliver_different_address: !!order?.deliver_address,
      deliver: order?.deliver_address,
    },
  );

  const [price, setPrice] = useState(extra?.task?.price);
  const [reason, setReason] = useState(extra?.task?.diagnosis);
  const [hasPrice, setHasPrice] = useState(_.isNumber(extra?.task?.price));

  useEffect(() => {
    const fetch = async () => {
      const extras = await bookingApi.extras(dealer.id);

      const options = extras[code].map((extra) => ({
        id: extra.id,
        value: extra.code,
        text: extra.title,
      }));
      setOptions(options);

      if (!selected) {
        setSelected(options[0]);
      }
    };

    fetch();
  }, [code, selected]);

  const isValid =
    selected?.value !== EXTRAS_CODES.PICK_UP || pick_up_location != null;

  const onSave = async () => {
    if (!isValid) {
      return;
    }

    const token = await authentication.getAccessToken();
    const result = await orderApi.setExtra(token, order.id, {
      code,
      service_id: selected?.id,
      will_wait: selected?.value === EXTRAS_CODES.EXTRA_NONE ? will_wait : null,
      wants_electric_bike:
        selected?.value === EXTRAS_CODES.BIKE ? wants_electric_bike : null,
      pick_up_location:
        selected?.value === EXTRAS_CODES.PICK_UP ? pick_up_location : null,
      price: hasPrice ? price : null,
      reason: hasPrice ? reason : null,
    });

    if (result.success) {
      onDismiss();
    }
  };

  let extraOption = null;
  switch (selected?.value) {
    case EXTRAS_CODES.EXTRA_NONE:
      extraOption = (
        <View>
          <View style={styles.articleHeader}>
            <Text style={globalStyles.mediumPlus}>Wachten?</Text>
          </View>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => setWillWait(true)}>
            <RadioButton checked={will_wait} />
            <View style={styles.radioButtonDescription}>
              <Text>Ja</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => setWillWait(false)}>
            <RadioButton checked={!will_wait} />
            <View style={styles.radioButtonDescription}>
              <Text>Nee</Text>
            </View>
          </Pressable>
        </View>
      );
      break;
    case EXTRAS_CODES.BIKE:
      extraOption = (
        <View>
          <View style={styles.articleHeader}>
            <Text style={globalStyles.mediumPlus}>Elektrisch?</Text>
          </View>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => setWantsElectricBike(true)}>
            <RadioButton checked={wants_electric_bike} />
            <View style={styles.radioButtonDescription}>
              <Text>Ja</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => setWantsElectricBike(false)}>
            <RadioButton checked={!wants_electric_bike} />
            <View style={styles.radioButtonDescription}>
              <Text>Nee</Text>
            </View>
          </Pressable>
        </View>
      );
      break;
    case EXTRAS_CODES.PICK_UP:
      extraOption = (
        <View style={{paddingTop: 20}}>
          <View style={{paddingHorizontal: 20}}>
            <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
              Ophaallocatie
            </Text>
            <Address
              {...pick_up_location?.pick_up}
              onChange={(address) =>
                setPickupLocation({
                  ...pick_up_location,
                  pick_up: address,
                })
              }
            />
          </View>
          <View style={[styles.row, {paddingHorizontal: 20}]}>
            <Text style={globalStyles.mediumPlus}>
              Afleverlocatie hetzelfde?
            </Text>
          </View>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() =>
              setPickupLocation({
                ...pick_up_location,
                deliver_different_address: false,
              })
            }>
            <RadioButton
              checked={!pick_up_location?.deliver_different_address}
            />
            <View style={styles.radioButtonDescription}>
              <Text>Ja</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() =>
              setPickupLocation({
                ...pick_up_location,
                deliver_different_address: true,
              })
            }>
            <RadioButton
              checked={pick_up_location?.deliver_different_address}
            />
            <View style={styles.radioButtonDescription}>
              <Text>Nee</Text>
            </View>
          </Pressable>
          {pick_up_location?.deliver_different_address && (
            <View style={{paddingHorizontal: 20, paddingBottom: 20}}>
              <Address
                {...pick_up_location?.deliver}
                onChange={(address) =>
                  setPickupLocation({
                    ...pick_up_location,
                    deliver: address,
                  })
                }
              />
            </View>
          )}
        </View>
      );
      break;
    default:
      extraOption = null;
      break;
  }

  return (
    <SafeAreaView style={[globalStyles.mainView, styles.modalMainView]}>
      <View style={styles.contentView}>
        <View style={[globalStyles.headerView, styles.modalHeaderView]}>
          <CloseButton style={styles.headerButton} onPress={onDismiss} />
          <Text>Kiezen</Text>
        </View>
        <ScrollView>
          <View
            style={[
              styles.article,
              {
                borderTopWidth: 1,
                borderTopColor: '#dcdcdc',
                paddingHorizontal: 20,
                paddingVertical: 16,
              },
            ]}>
            <Text style={globalStyles.mediumPlus}>{Extras[code]?.title}</Text>
            {options?.length > 0 && (
              <View style={{paddingTop: 12}}>
                <Select
                  label="Kies een optie"
                  style={{width: 250}}
                  options={options}
                  value={selected}
                  onChange={(option) => {
                    setSelected(option);
                  }}
                />
              </View>
            )}
          </View>
          {extraOption}
          {!isLease && (
            <View style={styles.article}>
              <View style={styles.articleHeader}>
                <Text style={globalStyles.mediumPlus}>Prijs</Text>
                <Toggle
                  checked={hasPrice}
                  onPress={() => setHasPrice(!hasPrice)}
                />
              </View>
              {hasPrice && (
                <View
                  style={{
                    maxWidth: '50%',
                    paddingHorizontal: 20,
                    paddingBottom: 20,
                  }}>
                  <FormNumberInput
                    value={price}
                    digits={2}
                    label="Bedrag ex BTW"
                    onChangeNumber={setPrice}
                  />
                </View>
              )}
            </View>
          )}
        </ScrollView>
        {hasPrice && (
          <View style={[globalStyles.footerView, styles.footerView]}>
            <View style={styles.article}>
              <Pressable
                onPress={() => setExpandReason(!expandReason)}
                style={[styles.articleHeader, {marginBottom: -1}]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={globalStyles.mediumPlus}>
                    Boodschap voor berijder
                  </Text>
                </View>
                {expandReason ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Pressable>
              {expandReason && (
                <>
                  <View
                    style={[
                      styles.articleHeader,
                      styles.last,
                      {paddingHorizontal: 12, paddingVertical: 12},
                    ]}>
                    <FormTextInput
                      value={reason}
                      style={{flex: 1}}
                      inputProps={{
                        placeholder: 'Opmerking toevoegen...',
                        theme: {
                          colors: {
                            primary: '#231fda',
                            background: 'transparent',
                          },
                        },
                      }}
                      onChangeText={(text) => setReason(text)}
                    />
                  </View>
                </>
              )}
            </View>
          </View>
        )}
        <View style={[globalStyles.footerView, {justifyContent: 'flex-end'}]}>
          <PrimaryButton
            disabled={!isValid}
            onPress={async () => {
              await onSave();
              onDismiss();
            }}>
            Opslaan
          </PrimaryButton>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Edit;
