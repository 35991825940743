import {StyleSheet} from 'react-native';
const styles = {
  comment: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 13,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    maxHeight: 88,
    overflow: 'hidden',
  },
  commentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  floatingIcon: {
    width: 56,
    height: 56,
    position: 'absolute',
    bottom: 16,
    right: 20,
    backgroundColor: '#181716',
    borderRadius: 90,
    alignItems: 'center',
    justifyContent: 'center',
    shadowOffset: {width: 0, height: 3},
    shadowOpacity: 0.5,
    shadowColor: '#181716',
    shadowRadius: 10,
    elevation: 3,
  },
  footerView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    marginBottom: 0,
  },
  footerButton: {
    flex: 1,
    margin: 0,
    borderRadius: 0,
    minHeight: 65,
    justifyContent: 'center',
  },
};

export default StyleSheet.create(styles);
