import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import {SafeAreaView, ScrollView, ActivityIndicator} from 'react-native';
import {HeaderBackButton} from '@react-navigation/stack';
import moment from 'moment';

import styles from './styles';
import globalStyles from '../../../styles';
import {Text, OrderStatusIcon} from '../../../components/controls';
import {
  order as orderApi,
  car as carApi,
  dealer as dealerApi,
} from '../../../api/private';
import authentication from '../../../lib/authentication';
import UserContext from '../../../components/context/UserContext';
import {OrderRequestStatus, OrderStatus} from '../../../types';
import {useSelector} from '../../../lib/hooks';

import {
  Appointment,
  Car,
  Footer,
  Order,
  Payment,
  User,
} from '../../../components/modules/Details';
import ChevronLeftIcon from '../../../images/md-icons/chevron_left/materialicons/24px.svg';

const Details = ({route, navigation}) => {
  const modal = route.params?.modal;
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [requests, setRequests] = useState([]);
  const [car, setCar] = useState({});
  const [expandMileage, setExpandMileage] = useState(false);
  const [dealer, setDealer] = useState(null);
  const [approvedTasks, setApprovedTasks] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const scrollViewRef = useRef(null);

  const jobNotifications = useSelector((state) => state.jobNotifications);
  const updatedJob = useSelector((state) => state.updatedJob);

  const {me} = useContext(UserContext);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={() => {
            const parent = navigation.dangerouslyGetParent();
            const parentState = parent?.dangerouslyGetState();

            if (modal || parentState?.routes.length > 1) {
              // goBack().
              // Doesn't use navigation.goBack() because nested in an iframe it can cause the
              // parent window to go back as well.
              navigation.pop();
            } else {
              navigation.replace('Tabs', {screen: 'Chat'});
            }
          }}
        />
      ),
      headerTitle: () => (
        <Text style={globalStyles.largePlus}>
          {OrderStatus.text(order?.status)}
        </Text>
      ),
      headerRight: () => (
        <OrderStatusIcon style={{marginRight: 16}} status={order?.status} />
      ),
    });
  }, [navigation, modal, order]);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      let order_id = route.params?.order_id;

      if (order_id) {
        const order = await orderApi.details(token, order_id);
        const requests = await orderApi.requests(token, order_id);
        const car = await carApi.fetch(token, order?.license);
        const dealer = await dealerApi.details(token, order?.dealer_id);
        const {unread} = await orderApi.messagesUnread(token, order_id);

        setOrder(order);
        setRequests(requests);
        setCar(car);
        setDealer(dealer);
        setUnreadMessages(unread);

        if (!!order?.lease_company) {
          setApprovedTasks(
            requests
              ?.flatMap((request) => request.tasks)
              .filter((task) => task.is_approved)
              .map((task) => task.id),
          );
        }
      } else {
        navigation.replace('Tabs', {screen: 'Chat'});
      }

      setLoading(false);
    };

    fetch();
  }, [navigation, route.params]);

  const order_id = order?.id;
  const license = order?.license;

  useEffect(() => {
    if (!updatedJob || !me || !order_id) {
      return;
    }

    if (updatedJob?.toUpperCase() === license.toUpperCase()) {
      const fetch = async () => {
        const token = await authentication.getAccessToken();
        const changes = await orderApi.details(token, order_id);
        const requests = await orderApi.requests(token, order_id);
        const {unread} = await orderApi.messagesUnread(token, order_id);
        setOrder(changes);
        setRequests(requests);
        setUnreadMessages(unread);
      };

      fetch();
    }
  }, [jobNotifications, updatedJob, license, me, order_id]);

  const onAccept = async (approve) => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.accept(token, order_id, approve);

    if (isLease && moment(order?.datetime).isSame(moment(new Date()), 'd')) {
      await orderApi.checkin(token, order.id);
    }

    if (result.success) {
      navigation.navigate('Chat', {
        order_id,
      });
    }
  };

  const onCheckin = async () => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.checkin(token, order.id);

    if (result.success) {
      navigation.navigate('Chat', {
        order_id,
      });
    }
  };

  const fastForwardOrder = async () => {
    const token = await authentication.getAccessToken();
    if (!order.checkin) {
      await orderApi.checkin(token, order.id);
    }

    await orderApi.startDiagnosis(token, order.id);
    await orderApi.endDiagnosis(token, order.id);
  };

  const onSend = async (approve) => {
    const pending_requests = requests.filter(
      (item) =>
        item.status === OrderRequestStatus.Created ||
        item.status === OrderRequestStatus.Pending,
    );

    const token = await authentication.getAccessToken();

    if (order.checkin) {
      await orderApi.startDiagnosis(token, order.id);
      await orderApi.endDiagnosis(token, order.id);
    }

    let success = true;
    try {
      if (approve) {
        await Promise.all(
          pending_requests.map(
            async (request) =>
              await orderApi.requestApproved(token, request.id),
          ),
        );
      } else {
        await Promise.all(
          pending_requests.map(
            async (request) =>
              await orderApi.requestApproval(token, request.id),
          ),
        );
      }
    } catch {
      success = false;
    }

    if (success) {
      navigation.navigate('Chat', {
        order_id,
      });
    }
  };

  const onComplete = async () => {
    const token = await authentication.getAccessToken();
    await fastForwardOrder();

    if (isLease) {
      await orderApi.approve(token, order.id, approvedTasks);
    }

    await orderApi.setWorkDone(token, order.id);

    navigation.navigate('Chat', {
      order_id,
    });
  };

  const onChangeMileage = async (mileage) => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const result = await orderApi.saveMileage(token, order_id, mileage);

      if (result?.success) {
        const changes = await orderApi.details(token, order.id);
        setOrder(changes);
      }
    };

    fetch();
  };

  const onTaskApproved = async (task) => {
    let change = approvedTasks;
    if (change.includes(task.id)) {
      change = change.filter((item) => item !== task.id);
    } else {
      change.push(task.id);
    }

    const token = await authentication.getAccessToken();
    await orderApi.approve(token, order.id, change);

    setApprovedTasks([...change]);
  };

  const onLeaseApproval = async () => {
    let change;
    if (approvedTasks?.length) {
      change = [];
    } else {
      change = requests
        .flatMap((request) => request.tasks)
        .filter((task) => order?.status !== OrderStatus.Draft || !task.is_extra)
        .map((task) => task.id);
    }

    const token = await authentication.getAccessToken();
    await orderApi.approve(token, order.id, change);

    setApprovedTasks([...change]);
  };

  const onPayLaterChange = async () => {
    const value = !order.can_pay_later;
    const token = await authentication.getAccessToken();
    const result = await orderApi.toggleCanPayLater(token, order.id, value);
    if (result.success) {
      const changes = await orderApi.details(token, order.id);
      setOrder(changes);
    }
  };

  const onRefresh = async () => {
    const token = await authentication.getAccessToken();
    const changes = await orderApi.details(token, order.id);
    const requests = await orderApi.requests(token, order.id);
    const dealer = await dealerApi.details(token, changes.dealer_id);
    const {unread} = await orderApi.messagesUnread(token, order_id);
    setOrder(changes);
    setRequests(requests);
    setDealer(dealer);
    setUnreadMessages(unread);
  };

  const total = requests.reduce((acc, request) => acc + request.amount, 0);
  const due = requests.reduce((acc, request) => acc + request.open_amount, 0);

  const isLease = !!order?.lease_company;
  const leaseApproval = approvedTasks?.length > 0;

  const isDraft = order?.status === OrderStatus.Draft;
  const isReservation = order?.status === OrderStatus.Created;
  const isFinished =
    order?.status === OrderStatus.Done ||
    order?.status === OrderStatus.InvoiceCreated ||
    order?.status === OrderStatus.Finished;
  const isPending =
    order?.status === OrderStatus.Pending ||
    order?.status === OrderStatus.ApprovalRequested;

  const is_order_active = !isReservation && !isFinished && !!order?.checkin;
  const tasks = requests?.flatMap((request) => request.tasks);
  const canAccept = isReservation && !is_order_active && !isPending;

  const canSendApprovals =
    !isLease &&
    !canAccept &&
    !isFinished &&
    requests.some(
      (request) =>
        (request.status === OrderRequestStatus.Created ||
          request.status === OrderRequestStatus.Pending) &&
        request.tasks.length > 0,
    );
  const canCompleteJob =
    !canAccept &&
    !isPending &&
    !isFinished &&
    !canSendApprovals &&
    (!isLease || leaseApproval) &&
    !!order?.car_mileage_date;

  const canAddItems = !isPending && !isFinished;

  const extras = order?.services?.filter((service) => service.type === 'EXTRA');

  let incomplete =
    !isLease &&
    (requests.length === 0 ||
      tasks?.every((task) => task.is_approved !== null) ||
      tasks?.some((task) => !task.is_complete));

  if (isDraft && !incomplete) {
    incomplete =
      tasks?.every((task) => task.is_extra) ||
      !order?.user_id ||
      !order?.datetime ||
      !extras?.length;
  }

  const hasRejections = requests
    ?.flatMap((request) => request.tasks)
    .some((task) => task.is_approved === false);

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView
          ref={scrollViewRef}
          style={[globalStyles.contentView, styles.contentView]}>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading && (
            <>
              {!order && (
                <Text style={[globalStyles.large, styles.header]}>
                  Auto niet gevonden
                </Text>
              )}
              {order && (
                <>
                  <Appointment
                    order_id={order.id}
                    status={order.status}
                    isFinished={isFinished}
                    datetime={order.datetime}
                    dealer={dealer}
                    onRefresh={onRefresh}
                  />
                  <Car
                    isDraft={isDraft}
                    isFinished={isFinished}
                    status={order.status}
                    order_id={order.id}
                    car_name={car?.name}
                    license={order.license}
                    mileage={order.mileage}
                    car_mileage={order.car_mileage}
                    car_mileage_date={order.car_mileage_date}
                    last_car_mileage={order.last_car_mileage}
                    last_car_mileage_date={order.last_car_mileage_date}
                    lease_company={order.lease_company}
                    company_name={order.company_name}
                    apk_expiry={car?.apk_expiry}
                    expandMileage={expandMileage}
                    isLease={isLease}
                    onExpandMileage={setExpandMileage}
                    onChangeMileage={onChangeMileage}
                    onRefresh={onRefresh}
                  />
                  <User
                    order_id={order.id}
                    status={order.status}
                    isLease={isLease}
                    first_name={order.first_name}
                    last_name={order.last_name}
                    email={order.email}
                    phone_number={order.phone_number}
                    company_name={order.company_name}
                    unread_messages={unreadMessages}
                    onRefresh={onRefresh}
                  />
                  <Order
                    order={order}
                    canAddItems={canAddItems}
                    isLease={isLease}
                    requests={requests}
                    extras={extras}
                    dealer={dealer}
                    is_order_active={is_order_active}
                    isPending={isPending}
                    canAccept={canAccept}
                    isFinished={isFinished}
                    leaseApproval={leaseApproval}
                    approvedTasks={approvedTasks}
                    total={total}
                    onTaskApproved={onTaskApproved}
                    onTaskReactivated={onRefresh}
                    onRefresh={onRefresh}
                    onLeaseApproval={onLeaseApproval}
                  />
                  {!isLease && (
                    <Payment
                      total={total}
                      due={due}
                      incomplete={incomplete}
                      isFinished={isFinished}
                      isPending={isPending}
                      can_pay_later={order.can_pay_later}
                      onPayLaterChange={onPayLaterChange}
                    />
                  )}
                </>
              )}
            </>
          )}
        </ScrollView>
        {!loading && order && (
          <Footer
            status={order.status}
            tasks={requests
              .flatMap((request) => request.tasks)
              .filter((task) => task.is_approved !== false)}
            canAccept={canAccept}
            isActive={is_order_active}
            isLease={isLease}
            incomplete={incomplete}
            isDraft={isDraft}
            isPending={isPending}
            isFinished={isFinished}
            canCompleteJob={canCompleteJob}
            hasRejections={hasRejections}
            canSendApprovals={canSendApprovals}
            onAccept={onAccept}
            onSend={onSend}
            onComplete={onComplete}
            onCheckin={onCheckin}
          />
        )}
      </SafeAreaView>
    </>
  );
};

export default Details;
