import endpoints from './endpoints';
import config from '../../configuration';
import {fetch} from '../../lib/fetch';

const {publicFunctionHostKey} = config;

const booking = {
  suggest: async (dealerId, service, license, mileage) => {
    const res = await fetch(
      `${endpoints.booking}/${dealerId}/suggest/${service}`,
      {
        method: 'POST',
        headers: {
          'x-functions-key': publicFunctionHostKey,
        },
        body: JSON.stringify({license, mileage}),
      },
    );

    const json = await res.json();

    return json;
  },
  services: async (dealerId) => {
    const res = await fetch(`${endpoints.services}/${dealerId}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
    });

    const json = await res.json();
    return json;
  },
  additionals: async (dealerId, services) => {
    const res = await fetch(`${endpoints.additionals}/${dealerId}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({
        services,
      }),
    });

    const json = await res.json();
    return json;
  },
  extras: async (dealerId) => {
    const res = await fetch(`${endpoints.extras}/${dealerId}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
    });

    const json = await res.json();
    return json;
  },
  summary: async (dealer_id, params) => {
    const res = await fetch(`${endpoints.booking}/${dealer_id}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({
        ...params,
      }),
    });

    const json = await res.json();
    return json;
  },
  new: async (dealer_id, params) => {
    const res = await fetch(`${endpoints.booking}/${dealer_id}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
      method: 'PUT',
      body: JSON.stringify({
        ...params,
      }),
    });

    const json = await res.json();
    return json;
  },
  details: async (dealer_id, order_id) => {
    const res = await fetch(`${endpoints.booking}/${dealer_id}/${order_id}`, {
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
    });

    const json = await res.json();
    return json;
  },
  payment: async (dealer_id, order_id) => {
    const res = await fetch(
      `${endpoints.booking}/payment/${dealer_id}/${order_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-functions-key': publicFunctionHostKey,
        },
        method: 'POST',
      },
    );

    const json = await res.json();
    return json;
  },
};

export default booking;
