import React, {useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import ArrowBackIcon from '../../../images/md-icons/arrow_back/materialicons/24px.svg';
import {
  FormTextInput,
  Text,
  Select,
  PrimaryButton,
  Toggle,
  FormNumberInput,
} from '../../../components/controls';
import {TaskAction} from '../../../types';
import {HeaderBackButton} from '@react-navigation/stack';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addCheckedTasks} from '../../../actions';

const New = ({route, navigation}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;
  const description = route.params?.description;

  const checked = useSelector((state) => state.checkedTasks);
  const [newTask, setNewTask] = useState({description, has_parts: true});

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTitle: <Text>Taak toevoegen</Text>,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ArrowBackIcon />}
          onPress={() => {
            navigation.navigate('ListTasks', {order_id});
          }}
        />
      ),
    });
  }, [navigation, order_id]);

  const onNewTask = () => {
    checked.push(newTask);
    dispatch(addCheckedTasks(checked));
    navigation.navigate('ListTasks', {order_id});
  };

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <View style={[globalStyles.contentView, {paddingHorizontal: 0}]}>
        <View
          style={[
            styles.articleHeader,
            styles.contentPadding,
            {
              flexDirection: 'column',
              justifyContent: 'inherit',
              alignItems: 'inherit',
            },
          ]}>
          <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
            Naam taak
          </Text>
          <FormTextInput
            value={newTask?.description}
            style={styles.input}
            inputProps={{placeholder: 'Voer tekst in'}}
            onChangeText={(text) => {
              setNewTask({...newTask, description: text});
            }}
          />
          <View style={{alignItems: 'center', flex: 1}}>
            <Text
              style={[globalStyles.small, globalStyles.gray, {lineHeight: 24}]}>
              Deze beschrijving is zichtbaar voor de klant indien voorgesteld
            </Text>
          </View>
        </View>
        <View
          style={[
            styles.articleHeader,
            styles.contentPadding,
            {
              borderTopWidth: 0,
              flexDirection: 'column',
              justifyContent: 'inherit',
              alignItems: 'inherit',
            },
          ]}>
          <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
            Handeling
          </Text>
          <Select
            label="Selecteer een handeling"
            options={[{text: 'Niet van toepassing', value: null}].concat(
              TaskAction.options(),
            )}
            value={newTask?.action && TaskAction.text(newTask?.action)}
            onChange={(selected) => {
              setNewTask({...newTask, action: selected.value});
            }}
          />
        </View>
        <View
          style={[
            styles.articleHeader,
            styles.contentPadding,
            {
              borderTopWidth: 0,
              flexDirection: 'column',
              justifyContent: 'inherit',
              alignItems: 'inherit',
            },
          ]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
              Klein materiaal
            </Text>
            <Toggle
              checked={newTask?.has_markup}
              onPress={(value) =>
                setNewTask({
                  ...newTask,
                  has_markup: value,
                })
              }
            />
          </View>
        </View>
        <View
          style={[
            styles.articleHeader,
            styles.contentPadding,
            {
              borderTopWidth: 0,
              flexDirection: 'column',
              justifyContent: 'inherit',
              alignItems: 'inherit',
            },
          ]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Text style={[globalStyles.mediumPlus, {paddingBottom: 10}]}>
              Milieuheffing
            </Text>
            <Toggle
              checked={_.isNumber(newTask?.environment_surcharge)}
              onPress={(value) =>
                setNewTask({
                  ...newTask,
                  environment_surcharge: value ? 0 : null,
                })
              }
            />
          </View>
          {_.isNumber(newTask?.environment_surcharge) && (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 10,
                alignItems: 'flex-end',
              }}>
              <FormNumberInput
                value={newTask?.environment_surcharge}
                digits={2}
                style={{width: 120}}
                inputProps={{
                  placeholder: 'Bedrag (EUR)',
                }}
                onChangeNumber={(value) => {
                  setNewTask({
                    ...newTask,
                    environment_surcharge: value,
                  });
                }}
              />
              <Text style={{marginLeft: 20}}>Exclusief BTW</Text>
            </View>
          )}
        </View>
      </View>
      <View style={[globalStyles.footerView, {justifyContent: 'flex-end'}]}>
        <PrimaryButton
          disabled={!newTask?.description}
          style={{
            backgroundColor: '#231fda',
            height: 36,
            paddingTop: 0,
            paddingBottom: 0,
            paddingVertical: 0,
            minWidth: 133,
            borderRadius: 4,
            shadowOffset: {width: 0, height: 1.5},
            shadowOpacity: 0.2,
            shadowColor: '#000000',
            shadowRadius: 5,
            elevation: 3,
          }}
          textStyle={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            textTransform: 'uppercase',
          }}
          onPress={onNewTask}>
          <Text style={[globalStyles.smallMedium, {color: '#ffffff'}]}>
            Toevoegen
          </Text>
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
};

export default New;
