import endpoints from "./endpoints";
import { fetch } from "../../lib/fetch";
import config from "../../configuration";

const functionHostKey = config.publicFunctionHostKey;

const order = {
  fetch: async (id) => {
    const result = await fetch(`${endpoints.order}/${id}`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  payment: async (id) => {
    const result = await fetch(`${endpoints.order}/payment/${id}`, {
      method: "POST",
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  paymentResult: async (id, payment_id) => {
    const result = await fetch(
      `${endpoints.order}/payment/${id}?payment_id=${payment_id}`,
      {
        headers: {
          "x-functions-key": functionHostKey,
        },
      }
    );

    const json = await result.json();
    return json;
  },
  messages: async (id) => {
    const result = await fetch(`${endpoints.order}/${id}/messages`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  sendMessage: async (id, message, images) => {
    const result = await fetch(`${endpoints.order}/${id}/message`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
      method: "POST",
      body: JSON.stringify({ message, images }),
    });

    const json = await result.json();
    return json;
  },
  list: async (user_id) => {
    const result = await fetch(`${endpoints.orders}`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
      method: "POST",
      body: JSON.stringify({ user_id }),
    });

    const json = await result.json();
    return json;
  },
  fetchTask: async (task_id) => {
    const result = await fetch(`${endpoints.order}/task/${task_id}`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  fetchApprovals: async (id) => {
    const result = await fetch(`${endpoints.order}/approve/${id}`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  approve: async (id, approved, notApproved) => {
    const result = await fetch(`${endpoints.order}/approve/${id}`, {
      method: "PATCH",
      headers: {
        "x-functions-key": functionHostKey,
      },
      body: JSON.stringify({ status: "APPROVED", approved, notApproved }),
    });

    const json = await result.json();
    return json;
  },
  reject: async (request_id) => {
    const result = await fetch(`${endpoints.order}/approve/${request_id}`, {
      method: "PATCH",
      headers: {
        "x-functions-key": functionHostKey,
      },
      body: JSON.stringify({
        status: "FINISHED",
      }),
    });

    const json = await result.json();
    return json;
  },
  fetchPhoto: async (photo_id) => {
    const res = await fetch(`${endpoints.order}/photo/${photo_id}`, {
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    if (!res) {
      return null;
    }

    const result = await res.text();
    return result;
  },
  accept: async (id) => {
    const result = await fetch(`${endpoints.order}/accept/${id}`, {
      method: "PATCH",
      headers: {
        "x-functions-key": functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
};

export default order;
