const TaskAction = {
  Refill: 'REFILL',
  Clean: 'CLEAN',
  Repair: 'REPAIR',
  Execute: 'EXECUTE',
  Update: 'UPDATE',
  Replace: 'REPLACE',
  Check: 'CHECK',

  options: () => {
    return [
      TaskAction.Refill,
      TaskAction.Clean,
      TaskAction.Repair,
      TaskAction.Execute,
      TaskAction.Update,
      TaskAction.Replace,
      TaskAction.Check,
    ].map((action) => ({value: action, text: TaskAction.text(action)}));
  },

  text: (action) => {
    switch (action) {
      case TaskAction.Refill:
        return 'Bijvullen';
      case TaskAction.Clean:
        return 'Reinigen';
      case TaskAction.Repair:
        return 'Repareren';
      case TaskAction.Execute:
        return 'Uitvoeren';
      case TaskAction.Update:
        return 'Updaten';
      case TaskAction.Replace:
        return 'Vervangen';
      case TaskAction.Check:
        return 'Controleren';
      default:
        break;
    }

    return action;
  },
};

export default TaskAction;
