import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {
  New as NewTask,
  List as ListTasks,
  Search as SearchTasks,
  Services,
} from '../screens/tasks';
import withProtectedScreen from '../components/hoc/with-protected-screen';
import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';

const TasksStack = createStackNavigator();

export default () => (
  <TasksStack.Navigator
    initialRouteName="ListTasks"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: false,
      headerTitle: '',
      headerStyle: {shadowColor: 'transparent', elevation: 0},
      cardStyle: {
        marginTop: 32,
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        flex: 1,
        minHeight: 0,
      },
    }}>
    <TasksStack.Screen
      name="Services"
      component={withProtectedScreen(withScreenIsFocused(Services))}
    />
    <TasksStack.Screen
      name="ListTasks"
      component={withProtectedScreen(withScreenIsFocused(ListTasks))}
    />
    <TasksStack.Screen
      name="SearchTasks"
      component={withProtectedScreen(withScreenIsFocused(SearchTasks))}
    />
    <TasksStack.Screen
      name="NewTask"
      component={withProtectedScreen(withScreenIsFocused(NewTask))}
    />
  </TasksStack.Navigator>
);
