import {StyleSheet} from 'react-native';

const styles = {
  contentView: {
    width: '100%',
    flexShrink: 0,
    flexGrow: 1,
    position: 'relative',
  },
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  row: {
    paddingLeft: 16,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingRight: 16,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
  },
  label: {
    lineHeight: 24,
  },
  gray: {
    color: '#828282',
  },
  link: {
    textDecorationLine: 'underline',
  },
  icon: {
    marginRight: 16,
  },
};

export default StyleSheet.create(styles);
