import React, {useState, useCallback} from 'react';
import {View, SafeAreaView, ScrollView} from 'react-native';
import moment from 'moment';
import {Divider} from 'react-native-paper';

import styles from './styles';
import globalStyles from '../../../../styles';
import Format from '../../../../lib/format';
import {OrderStatus} from '../../../../types';
import {
  Pressable,
  Text,
  FormNumberInput,
  Link,
  FormSheetModal,
  CloseButton,
} from '../../../controls';
import CarModal from './CarModal';
import HistoryIcon from '../../../../images/md-icons/history/materialicons/24px.svg';
import authentication from '../../../../lib/authentication';
import {car as carApi} from '../../../../api/private';

const Car = ({
  isDraft,
  isFinished,
  status,
  order_id,
  car_name,
  license,
  mileage,
  car_mileage,
  car_mileage_date,
  last_car_mileage,
  last_car_mileage_date,
  apk_expiry,
  lease_company,
  company_name,
  isLease,
  expandMileage,
  onExpandMileage,
  onChangeMileage,
  onRefresh,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [history, setHistory] = useState(null);

  const onHistoryPress = useCallback(async () => {
    const token = await authentication.getAccessToken();
    const result = await carApi.history(token, license);
    setHistory(result);
  }, [license]);

  return (
    <>
      <View style={styles.article}>
        <View style={styles.articleHeader}>
          <Text style={globalStyles.mediumPlus}>Auto</Text>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Pressable onPress={onHistoryPress}>
              <HistoryIcon fill="#231fda" />
            </Pressable>
            {isDraft && (
              <Link onPress={() => setShowModal(true)}>&nbsp;Wijzigen</Link>
            )}
          </View>
        </View>
        <View style={styles.row}>
          <Text>{car_name}</Text>
          <Text>{Format.license(license)}</Text>
        </View>
        {(isDraft || status === OrderStatus.Created) && (
          <View style={styles.row}>
            <Text>Kilometerstand indicatie</Text>
            <Text>
              {mileage > 0 ? Format.number(mileage, 0, true) : 'Niet opgegeven'}
              &nbsp;
            </Text>
          </View>
        )}
        {!isDraft && status !== OrderStatus.Created && (
          <>
            <View style={styles.row}>
              <Text>
                Kilometerstand&nbsp;
                <Text>
                  {isFinished || car_mileage
                    ? `(${moment(car_mileage_date).format('D MMM YYYY')})`
                    : ''}
                </Text>
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {isFinished && (
                  <Text>
                    {car_mileage
                      ? Format.number(car_mileage, 0, true)
                      : 'Onbekend'}
                  </Text>
                )}
                {!isFinished && (
                  <Pressable
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    onPress={() => onExpandMileage(!expandMileage)}>
                    <Text
                      style={{
                        color: '#231fda',
                      }}>
                      {car_mileage
                        ? Format.number(car_mileage, 0, true)
                        : 'Opgeven'}
                      &nbsp;
                    </Text>
                  </Pressable>
                )}
              </View>
            </View>
            {expandMileage && (
              <View style={[styles.row, {flexDirection: 'column'}]}>
                <View
                  style={{
                    paddingBottom: 10,
                  }}>
                  <Text style={globalStyles.mediumPlus}>
                    Exacte kilometerstand
                  </Text>
                  {last_car_mileage && (
                    <View
                      style={{
                        paddingTop: 5,
                      }}>
                      <Text style={globalStyles.small}>
                        Laatst bekend&nbsp;
                        {Format.number(last_car_mileage, 0, true)}
                        &nbsp;(
                        {moment(last_car_mileage_date).format('D MMM YYYY')})
                      </Text>
                    </View>
                  )}
                </View>

                <FormNumberInput
                  value={car_mileage}
                  digits={0}
                  label="Voer huidge waarde in (dashboard auto)"
                  onChangeNumber={(number) => {
                    onChangeMileage(number);
                    onExpandMileage(false);
                  }}
                />
              </View>
            )}
          </>
        )}
        {apk_expiry && (
          <View style={styles.row}>
            <Text>APK vervaldatum</Text>
            <Text>{moment(apk_expiry).format('D MMM YYYY')}</Text>
          </View>
        )}
        <View style={styles.row}>
          <Text>Eigendom</Text>
          <Text>{lease_company || company_name || 'Particulier'}</Text>
        </View>
        {isLease && (
          <>
            <View style={styles.row}>
              <Text>Contract</Text>
              <Text>{company_name ? 'Zakelijk' : 'Particulier'}</Text>
            </View>
          </>
        )}
      </View>
      {showModal && (
        <FormSheetModal visible={true}>
          <CarModal
            order_id={order_id}
            license={license}
            mileage={mileage}
            lease_company={lease_company}
            company_name={company_name}
            onDismiss={() => {
              setShowModal(false);
              onRefresh();
            }}
          />
        </FormSheetModal>
      )}
      {history && (
        <FormSheetModal visible={true} onDismiss={() => setHistory(null)}>
          <SafeAreaView style={globalStyles.mainView}>
            <View
              style={[
                globalStyles.headerView,
                {paddingLeft: 0, paddingRight: 0, paddingBottom: 0},
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  height: 56,
                  paddingHorizontal: 8,
                  paddingVertical: 8,
                }}>
                <CloseButton
                  style={{position: 'absolute', top: 8, left: 8}}
                  onPress={() => setHistory(null)}
                />
                <Text style={globalStyles.medium}>Historie</Text>
              </View>
              <Divider />
            </View>
            <ScrollView>
              {history.map((row, index) => (
                <>
                  <View
                    style={{
                      padding: 16,
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}>
                    <View>
                      <Text style={globalStyles.mediumBold} key={index}>
                        {row.designation}
                      </Text>
                      {row.mileage && (
                        <Text style={globalStyles.smallRegular}>
                          KM-stand: {Format.number(row.mileage, 0, true)}
                        </Text>
                      )}
                    </View>
                    <Text>{moment(row.datetime).format('D MMM YYYY')}</Text>
                  </View>
                  <Divider />
                </>
              ))}
            </ScrollView>
          </SafeAreaView>
        </FormSheetModal>
      )}
    </>
  );
};

export default Car;
