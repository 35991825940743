import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Orders} from '../screens/dashboard';
import withProtectedScreen from '../components/hoc/with-protected-screen';
import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';

const DashboardStack = createStackNavigator();

export default () => (
  <DashboardStack.Navigator
    initialRouteName="Orders"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: false,
      headerTitle: '',
      headerStyle: {shadowColor: 'transparent', elevation: 0},
    }}>
    <DashboardStack.Screen
      name="Orders"
      component={withProtectedScreen(withScreenIsFocused(Orders))}
    />
  </DashboardStack.Navigator>
);
