import React, {useState} from 'react';
import {
  SafeAreaView,
  View,
  useWindowDimensions,
  ScrollView,
} from 'react-native';
import moment from 'moment';

import globalStyles from '../../../../../styles';
import styles from './styles';
import {Calendar, Text, PrimaryButton, CloseButton} from '../../../../controls';

const weekdays = ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'];

const SCROLLBAR_WIDTH = 17;
const MONTHS_IN_YEAR = 12;

const initialMonths = [...Array(MONTHS_IN_YEAR).keys()].map((value, index) =>
  moment().startOf('month').add(index, 'month'),
);

const AddDate = (props) => {
  const {dealer, onChange, onDismiss} = props;

  const dimensions = useWindowDimensions();
  const [date, setDate] = useState(props.datetime);
  const months = useState(initialMonths)[0];

  const onDateChange = async (date) => {
    const time = moment(dealer.begin_checkin, 'HH:mm:ss');
    date.hours(time.hours());
    date.minutes(time.minutes());
    setDate(date);
  };

  const width =
    (dimensions.width > 1024 ? 414 : dimensions.width) - SCROLLBAR_WIDTH;
  const cellWidth = Math.floor(width / 7);

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <View style={[globalStyles.headerView, styles.headerView]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            height: 56,
            paddingHorizontal: 8,
            paddingVertical: 8,
            borderBottomWidth: 1,
            borderBottomColor: '#dcdcdc',
          }}>
          <CloseButton
            style={{position: 'absolute', top: 8, left: 8}}
            onPress={onDismiss}
          />
          <Text style={globalStyles.medium}>Datum afspraak</Text>
        </View>
        <View style={[styles.weekdays, {height: cellWidth}]}>
          {weekdays.map((weekday, index) => (
            <View
              key={index}
              style={[
                styles.weekdayView,
                {
                  left: index * cellWidth,
                  width: cellWidth,
                  height: cellWidth,
                },
              ]}>
              <View style={styles.weekdayText}>
                <Text>{weekday}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <ScrollView style={[globalStyles.contentView, styles.contentView]}>
        {months.map((item) => (
          <Calendar
            key={`${moment(item).year()}-${moment(item).month()}`}
            cellWidth={cellWidth}
            month={item}
            date={date && new Date(date)}
            onPress={onDateChange}
          />
        ))}
      </ScrollView>
      <View style={[globalStyles.footerView, styles.footerView]}>
        <PrimaryButton
          onPress={async () => {
            await onChange(date);
            onDismiss();
          }}>
          Opslaan
        </PrimaryButton>
      </View>
    </SafeAreaView>
  );
};

export default AddDate;
