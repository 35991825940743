import {StyleSheet} from 'react-native';

const styles = {
  contentView: {
    width: '100%',
    flexShrink: 0,
    flexGrow: 1,
    position: 'relative',
  },
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    marginBottom: -1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  last: {
    borderBottomWidth: 0,
  },
  label: {
    lineHeight: 24,
  },
  gray: {
    color: '#828282',
  },
  contentPadding: {
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  link: {
    textDecorationLine: 'underline',
  },
  icon: {
    marginRight: 30,
  },
  modalMainView: {
    width: '100%',
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  footerLink: {
    textTransform: 'uppercase',
  },
};

export default StyleSheet.create(styles);
