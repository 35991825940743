import React, {useState} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/core';

import styles from './styles';
import globalStyles from '../../../../../styles';
import {Text, Checkbox, Dialog, Pressable, Link} from '../../../../controls';
import {order as orderApi} from '../../../../../api/private';
import authentication from '../../../../../lib/authentication';
import Format from '../../../../../lib/format';
import {OrderRequestStatus} from '../../../../../types';
import ThumbUpIcon from '../../../../../images/md-icons/thumb_up/materialicons/24px.svg';
import ThumbDownIcon from '../../../../../images/md-icons/thumb_down/materialicons/24px.svg';

const TaskRow = ({
  task,
  order,
  request,
  isLease,
  isOrderActive,
  canAccept,
  isPending,
  isFinished,
  approvedTasks,
  onTaskApproved,
  onTaskReactivated,
}) => {
  const navigation = useNavigation();

  const [showAlert, setShowAlert] = useState(false);

  const onReactivateTask = async () => {
    const token = await authentication.getAccessToken();

    const result = await orderApi.reactivateTask(token, task.id);

    if (result?.success) {
      setShowAlert(false);
      onTaskReactivated();
    }
  };

  const onPress = () => {
    if (isLease) {
      return;
    }

    if (task.is_approved === true || isPending || isFinished) {
      navigation.navigate('TaskModal', {screen: 'TaskView', params: {task}});
      return;
    }

    if (task.is_approved === false) {
      setShowAlert(true);
      return;
    }

    navigation.navigate('TaskModal', {
      screen: 'TaskEdit',
      params: {task_id: task.id},
    });
  };

  let amountElement;
  if (isLease) {
    amountElement =
      canAccept || isPending || isFinished || !isOrderActive ? (
        <Text>&nbsp;</Text>
      ) : (
        <Checkbox
          checked={approvedTasks?.includes(task.id)}
          onPress={() => onTaskApproved(task)}
        />
      );
  } else {
    if (
      request.status !== OrderRequestStatus.Pending &&
      task.is_approved === null
    ) {
      amountElement = (
        <Link onPress={onPress}>
          {task.is_complete ? Format.price(task.amount) : '-'}
        </Link>
      );
    } else {
      amountElement = (
        <Text>{task.is_complete ? Format.price(task.amount) : '-'}</Text>
      );
    }
  }

  let link = null;
  if (!isLease) {
    if (!task.is_complete) {
      link = (
        <Link
          onPress={onPress}
          textStyle={[globalStyles.small, {textDecorationLine: 'underline'}]}>
          Keuze maken
        </Link>
      );
    } else {
      link = (
        <Text
          style={[
            globalStyles.small,
            {
              textDecorationLine:
                task.is_approved === false ? 'line-through' : 'underline',
            },
          ]}>
          {task.remark ||
            task.parts
              ?.map((part) => part.description || part.designation)
              ?.join(', ') ||
            'Details'}
        </Text>
      );
    }
  }

  return (
    <>
      <Pressable style={styles.row} onPress={onPress}>
        <View style={{flex: 1}}>
          {task.description ? (
            <View style={{flexDirection: 'row'}}>
              <Text
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: 4,
                  textDecorationLine:
                    task.is_approved === false ? 'line-through' : null,
                }}>
                {task.description}
              </Text>
              {!isLease &&
              !isFinished &&
              ((order.checkin && !request.is_initial) ||
                (!order.checkin && request.is_initial)) &&
              task.is_approved === true ? (
                <ThumbUpIcon width={18} height={18} fill="#299976" />
              ) : task.is_approved === false ? (
                <ThumbDownIcon width={18} height={18} fill="#972727" />
              ) : null}
            </View>
          ) : (
            <Text style={styles.gray}>(Geen omschrijving)</Text>
          )}
          {link}
        </View>
        <Text
          style={{
            textDecorationLine:
              task.is_approved === false ? 'line-through' : null,
          }}>
          {amountElement}
        </Text>
      </Pressable>
      <Dialog
        visible={showAlert}
        buttons={[
          {text: 'Annuleren', onPress: () => setShowAlert(false)},
          {text: 'Opnieuw voorstellen', onPress: onReactivateTask},
        ]}>
        <Text>
          Wil je de afgewezen voorstel opnieuw voorstellen aan de klant?
        </Text>
      </Dialog>
    </>
  );
};

export default TaskRow;
