import {actionTypes} from '../actions';

export const reducer = (sessionState, action) => {
  const {type, value} = action;
  switch (type) {
    case actionTypes.INIT: {
      return {
        ...sessionState,
        ...value,
      };
    }

    case actionTypes.UPDATE_JOB: {
      return {
        ...sessionState,
        jobNotifications: (sessionState.jobNotifications || 0) + 1,
        updatedJob: value,
      };
    }

    case actionTypes.UPDATE_INBOX: {
      return {
        ...sessionState,
        inboxNotifications: (sessionState.inboxNotifications || 0) + 1,
      };
    }

    case actionTypes.ADD_CHECKED_TASKS: {
      return {
        ...sessionState,
        checkedTasks: [...value],
      };
    }

    case actionTypes.SET_SHOW_DRAWER: {
      return {
        ...sessionState,
        showDrawer: value,
      };
    }

    case actionTypes.SET_SHOW_CHANGE_DEALER: {
      return {
        ...sessionState,
        changeDealer: value,
      };
    }

    case actionTypes.SET_CHECKED_PARTS: {
      return {
        ...sessionState,
        checkedParts: value,
      };
    }

    default: {
      return sessionState;
    }
  }
};
