import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {TaskEdit, SearchPart, NewPart, TaskView} from '../screens/task';
import withProtectedScreen from '../components/hoc/with-protected-screen';
import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';

const TaskStack = createStackNavigator();

export default () => (
  <TaskStack.Navigator
    initialRouteName="TaskEdit"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: false,
      headerTitle: '',
      headerStyle: {shadowColor: 'transparent', elevation: 0},
      cardStyle: {
        marginTop: 32,
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        flex: 1,
        minHeight: 0,
      },
    }}>
    <TaskStack.Screen
      name="TaskEdit"
      component={withProtectedScreen(withScreenIsFocused(TaskEdit))}
    />
    <TaskStack.Screen
      name="SearchPart"
      component={withProtectedScreen(withScreenIsFocused(SearchPart))}
    />
    <TaskStack.Screen
      name="NewPart"
      component={withProtectedScreen(withScreenIsFocused(NewPart))}
    />
    <TaskStack.Screen
      name="TaskView"
      component={withProtectedScreen(withScreenIsFocused(TaskView))}
    />
  </TaskStack.Navigator>
);
