import moment from 'moment';

import endpoints from './endpoints';
import config from '../../configuration';
import {fetch} from '../../lib/fetch';

const functionHostKey = config.publicFunctionHostKey;

export default {
  fetchPhoto: async (dealer_id) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/photo`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    if (!res) {
      return null;
    }

    const result = await res.text();
    return result;
  },
  details: async (dealer_id) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });
    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
  availability: async (dealer_id) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/availability`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({
        from: moment(),
        to: moment().add(12, 'months').endOf('month'),
      }),
    });
    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
  services: async (dealer_id, license) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/services`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({license}),
    });
    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
};
