import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import config from '../../configuration';

const {publicFunctionHostKey} = config;

export default {
  fetch: async (postalCode, houseNumber, annex) => {
    const result = await fetch(`${endpoints.address}`, {
      method: 'POST',
      headers: {
        'x-functions-key': publicFunctionHostKey,
      },
      body: JSON.stringify({
        postalCode,
        houseNumber,
        annex,
      }),
    });

    if (!result || result.status !== 200) {
      return {};
    }

    const json = await result.json();
    return json;
  },
};
