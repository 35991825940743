import React, {useContext} from 'react';
import {View} from 'react-native';

import globalStyles from '../../../styles';
import UserContext from '../../context/UserContext';
import {DealerPersona, Pressable, Text} from '../../controls';
import {useDispatch} from '../../../lib/hooks';
import {setShowDrawer} from '../../../actions';

const DealerHeader = (props) => {
  const dispatch = useDispatch();
  const {me} = useContext(UserContext);

  let title = props.title;
  if (!title) {
    title =
      me?.dealer_selection.length === 1
        ? me?.dealers.find((dealer) => dealer.id === me?.dealer_selection[0])
            ?.city
        : me?.dealers.length === me?.dealer_selection?.length
        ? 'Alle vestigingen'
        : 'Geen vestiging';
  }

  return (
    <Pressable
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
      onPress={() => dispatch(setShowDrawer(true))}>
      {me?.dealer_selection?.length === 1 ? (
        <DealerPersona dealer_id={me?.dealer_selection[0]} />
      ) : (
        <View
          style={{
            backgroundColor: '#231fda',
            borderRadius: 50,
            alignItems: 'center',
            justifyContent: 'center',
            width: 32,
            height: 32,
          }}>
          {props.icon}
        </View>
      )}

      <Text style={[globalStyles.xxLarge, {marginLeft: 32}, props.titleStyle]}>
        {title}
      </Text>
    </Pressable>
  );
};

export default DealerHeader;
