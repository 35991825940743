import React, {useContext} from 'react';

import Signin from '../../screens/signin';
import AuthContext from '../context/AuthContext';

const withProtectedStack = (StackScreen) => {
  return (props) => {
    const {authenticated} = useContext(AuthContext);

    if (!authenticated) {
      return <Signin {...props} />;
    }

    return <StackScreen {...props} />;
  };
};

export default withProtectedStack;
