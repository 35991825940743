import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import {Details, Chat, Car} from '../screens/job';
import withProtectedScreen from '../components/hoc/with-protected-screen';
import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';

const JobStack = createStackNavigator();

export default () => (
  <JobStack.Navigator
    initialRouteName="Details"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: false,
      headerTitle: '',
      headerStyle: {shadowColor: 'transparent', elevation: 0},
      /* Hide document scroll bar */
      cardStyle: {flex: 1},
    }}>
    <JobStack.Screen
      name="Car"
      component={withProtectedScreen(withScreenIsFocused(Car))}
    />
    <JobStack.Screen
      name="Details"
      component={withProtectedScreen(withScreenIsFocused(Details))}
    />

    <JobStack.Screen
      name="Chat"
      component={withProtectedScreen(withScreenIsFocused(Chat))}
    />
  </JobStack.Navigator>
);
