import React, {useState} from 'react';
import {View} from 'react-native';

import styles from './styles';
import globalStyles from '../../../../styles';
import {
  PrimaryButton,
  Text,
  Link,
  Dialog,
  RadioButton,
  Pressable,
} from '../../../controls';
import Format from '../../../../lib/format';
import {OrderStatus} from '../../../../types';

const TAX_RATE = 1.21;

const Footer = ({
  status,
  tasks,
  canAccept,
  isLease,
  incomplete,
  isDraft,
  isPending,
  isFinished,
  canCompleteJob,
  canSendApprovals,
  onAccept,
  onSend,
  onComplete,
  onCheckin,
}) => {
  const [showChangeState, setShowChangeState] = useState(false);
  const [autoApprove, setAutoApprove] = useState(false);
  const [checkin, setCheckin] = useState(false);
  const [completeJob, setCompleteJob] = useState(isLease);

  const pending_tasks = tasks.filter((task) => task.is_approved === null);

  const onChangeState = () => {
    setShowChangeState(true);
  };

  let total_amount = null;
  if (tasks?.length > 0 && !isLease) {
    const amount =
      (pending_tasks.length > 0 ? pending_tasks : tasks).reduce(
        (acc, task) => acc + task.amount,
        0,
      ) * TAX_RATE;

    let statusText = 'opdracht';
    if (autoApprove) {
      statusText =
        status === OrderStatus.Draft ||
        status === OrderStatus.Confirmed ||
        status === OrderStatus.Created ||
        status === OrderStatus.Pending
          ? 'opdracht'
          : 'meerwerk';
    } else if (completeJob) {
      statusText = 'gereed';
    } else if (!isDraft && pending_tasks.length > 0) {
      statusText = 'voorstel';
    } else if (isDraft || canAccept) {
      statusText = 'offerte';
    } else if (checkin) {
      statusText = 'check-in';
    } else if (status === OrderStatus.ApprovalFinished) {
      statusText = 'meerwerk';
    }

    total_amount = (
      <>
        <Text style={globalStyles.mediumBold}>
          {Format.price(amount)} (
          {(pending_tasks.length > 0 ? pending_tasks : tasks).length})&nbsp;
        </Text>
        {!isFinished && (
          <Link
            onPress={onChangeState}
            textStyle={[
              globalStyles.medium,
              {color: '#828282', textDecorationLine: 'underline'},
            ]}>
            {statusText}
          </Link>
        )}
        {isFinished && (
          <Text style={[globalStyles.medium, {color: '#828282'}]}>gereed</Text>
        )}
      </>
    );
  }

  let dialogTitle = 'Status';
  if (status === OrderStatus.Created || pending_tasks.length > 0) {
    dialogTitle += ' na verzenden';
  }

  return (
    <>
      <View style={[globalStyles.footerView, styles.footerView]}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {total_amount}
        </View>
        {!isFinished && (
          <>
            {(isDraft || canAccept) &&
              (incomplete ? (
                <PrimaryButton disabled={true}>Verzenden</PrimaryButton>
              ) : (
                <PrimaryButton
                  disabled={incomplete}
                  onPress={async () => {
                    await onAccept(autoApprove);
                    setAutoApprove(false);
                  }}>
                  {isLease ? 'Accepteren' : 'Verzenden'}
                </PrimaryButton>
              ))}
            {!isDraft && !canSendApprovals && checkin && (
              <PrimaryButton
                onPress={() => {
                  onCheckin();
                  setCheckin(false);
                }}>
                Verzenden
              </PrimaryButton>
            )}
            {!isDraft &&
              canSendApprovals &&
              (incomplete || (isPending && !autoApprove) ? (
                <PrimaryButton disabled={true}>Verzenden</PrimaryButton>
              ) : (
                <PrimaryButton
                  onPress={async () => {
                    await onSend(autoApprove);
                    setAutoApprove(false);
                  }}>
                  Verzenden
                </PrimaryButton>
              ))}
            {!isDraft &&
              !isPending &&
              !canAccept &&
              !checkin &&
              !canSendApprovals &&
              (!canCompleteJob || !completeJob) && (
                <PrimaryButton disabled={true}>Verzenden</PrimaryButton>
              )}
            {canCompleteJob && completeJob && (
              <PrimaryButton onPress={onComplete}>Verzenden</PrimaryButton>
            )}
          </>
        )}
      </View>
      <Dialog
        title={dialogTitle}
        visible={showChangeState}
        onDismiss={() => setShowChangeState(false)}>
        <View>
          {status === OrderStatus.Draft || status === OrderStatus.Created ? (
            <>
              <Pressable
                onPress={() => setAutoApprove(false)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={!autoApprove} style={{marginRight: 32}} />
                <Text>Offerte</Text>
              </Pressable>
              <Pressable
                onPress={() => setAutoApprove(true)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={autoApprove} style={{marginRight: 32}} />
                <Text>Opdracht</Text>
              </Pressable>
            </>
          ) : pending_tasks.length > 0 ? (
            <>
              <Pressable
                onPress={() => setAutoApprove(false)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={!autoApprove} style={{marginRight: 32}} />
                <Text>Voorstel</Text>
              </Pressable>
              <Pressable
                onPress={() => setAutoApprove(true)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={autoApprove} style={{marginRight: 32}} />
                <Text>
                  {status === OrderStatus.Confirmed ||
                  status === OrderStatus.Created ||
                  status === OrderStatus.Pending
                    ? 'Opdracht'
                    : 'Meerwerk'}
                </Text>
              </Pressable>
            </>
          ) : status === OrderStatus.Confirmed ||
            status === OrderStatus.ConfirmedPartially ? (
            <>
              <Pressable
                onPress={() => setCheckin(false)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={!checkin} style={{marginRight: 32}} />
                <Text>Opdracht</Text>
              </Pressable>
              <Pressable
                onPress={() => setCheckin(true)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={checkin} style={{marginRight: 32}} />
                <Text>Check-in (na verzenden)</Text>
              </Pressable>
            </>
          ) : (
            <>
              <Pressable
                onPress={() => setCompleteJob(false)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton checked={!completeJob} style={{marginRight: 32}} />
                <Text>
                  {status === OrderStatus.Diagnosis ? 'Opdracht' : 'Meerwerk'}
                </Text>
              </Pressable>
              <Pressable
                disabled={!canCompleteJob}
                onPress={() => setCompleteJob(true)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 12,
                }}>
                <RadioButton
                  checked={completeJob}
                  disabled={!canCompleteJob}
                  style={{marginRight: 32}}
                />
                <Text style={!canCompleteJob && {opacity: 0.5}}>
                  Gereed (na verzenden)
                </Text>
              </Pressable>
            </>
          )}
        </View>
      </Dialog>
    </>
  );
};

export default Footer;
