import React, {useEffect, useState, useCallback} from 'react';
import {SafeAreaView, View, ScrollView, ActivityIndicator} from 'react-native';
import {v4 as uuid} from 'uuid';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {
  CloseButton,
  FormNumberInput,
  Pressable,
  Text,
  PrimaryButton,
  Select,
  Dialog,
  RadioButton,
  FormTextInput,
} from '../../../components/controls';
import authentication from '../../../lib/authentication';
import Format from '../../../lib/format';
import {order as orderApi} from '../../../api/private';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import DeleteIcon from '../../../images/md-icons/delete/materialicons/24px.svg';
import FlajIcon from '../../../images/Fiat_logo 2.svg';

const PACKAGE_TYPES = {
  FLAJ: 'FLAJ',
};
const PackageIcon = ({type}) => {
  let icon = null;

  switch (type) {
    case PACKAGE_TYPES.FLAJ:
      icon = <FlajIcon />;
      break;
    default:
      icon = null;
      break;
  }

  return icon;
};

const TAX_RATE = 21;

const TaskEdit = ({navigation, route}) => {
  const task_id = route.params?.task_id;

  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandChecks, setExpandChecks] = useState(false);
  const [expandTasks, setExpandTasks] = useState(false);
  const [expandParts, setExpandParts] = useState(false);
  const [price, setPrice] = useState(null);
  const [parts, setParts] = useState(null);
  const [includes_vat, setIncludesVAT] = useState(null);
  const [packages, setPackages] = useState(null);
  const [selected_package, setSelectedPackage] = useState(null);
  const [packageType, setPackageType] = useState(null);
  const [showVATDialog, setShowVATDialog] = useState(false);
  const [editPart, setEditPart] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const task = await orderApi.fetchTask(token, task_id);
      const packages = await orderApi.taskPackages(token, task_id);

      setTask(task);
      setPackages(packages);
      setPrice(task.price);
      setParts(task.parts);
      setIncludesVAT(task.includes_vat);
      if (task.reference_id) {
        const selected_package = packages.find(
          (item) => item.reference === task.reference_id,
        );
        setSelectedPackage(task.reference_id);
        setPackageType(selected_package?.type);
      }

      setExpandParts(!!task.parts);

      setLoading(false);
    };

    fetch();
  }, [task_id]);

  const save = useCallback(async () => {
    const token = await authentication.getAccessToken();

    const response = await orderApi.saveTask(token, {
      id: task_id,
      includes_vat,
      vat_percentage: TAX_RATE,
      price,
      is_overall_price: true,
      parts: parts?.map((part) => ({
        ...part,
        generic_article_id: part.generic_article_id || uuid(),
      })),
      reference_id: selected_package,
      remark: packages?.find((item) => item.reference === selected_package)
        ?.description,
    });

    return response;
  }, [task_id, price, includes_vat, parts, selected_package, packages]);

  const onDelete = async () => {
    const token = await authentication.getAccessToken();
    const result = await orderApi.deleteTask(token, task.id);
    if (result.success) {
      navigation.goBack();
    }
  };

  const onPackageSelect = useCallback(
    (reference) => {
      const item = packages.find((item) => item.reference === reference);

      setIncludesVAT(false);
      setExpandParts(!!item.parts);
      setParts(item.parts);
      setPrice(item.amount);
      setPackageType(item.type);
      setSelectedPackage(reference);
    },
    [packages],
  );

  const package_options = packages?.map((item) => ({
    value: item.reference,
    text: item.description,
  }));

  return (
    <>
      <SafeAreaView style={[globalStyles.mainView, styles.modalMainView]}>
        {loading && <ActivityIndicator size="large" color="#231fda" />}
        {!loading && (
          <>
            <View style={[globalStyles.headerView, styles.modalHeaderView]}>
              <CloseButton
                style={styles.headerButton}
                onPress={() => navigation.goBack()}
              />
              <Text>{task?.description}</Text>
              <Pressable
                style={{position: 'absolute', right: 16}}
                onPress={() => setShowConfirmDelete(true)}>
                <DeleteIcon fill="#c4c4c4" />
              </Pressable>
            </View>
            <ScrollView contentContainerStyle={styles.contentView}>
              {packages?.length > 0 && (
                <View style={styles.article}>
                  <View style={[styles.articleHeader, {marginBottom: 16}]}>
                    <Text style={globalStyles.mediumBold}>Pakket</Text>
                    {packageType && <PackageIcon type={packageType} />}
                  </View>
                  <Select
                    defaultText="Selecteer een optie"
                    options={package_options}
                    value={package_options?.find(
                      (item) => item.value === selected_package,
                    )}
                    onChange={(option) => onPackageSelect(option.value)}
                  />
                </View>
              )}
              <View style={styles.article}>
                <View
                  style={[
                    styles.articleHeader,
                    {justifyContent: 'flex-start', marginBottom: 16},
                  ]}>
                  <Text style={globalStyles.mediumBold}>Prijs&nbsp;</Text>
                  <Pressable onPress={() => setShowVATDialog(true)}>
                    <Text
                      style={[
                        globalStyles.mediumBold,
                        {textDecorationLine: 'underline'},
                      ]}>
                      {includes_vat ? 'incl. btw' : 'excl. btw'}
                    </Text>
                  </Pressable>
                </View>
                <FormNumberInput
                  style={{width: 155}}
                  value={price}
                  onChangeNumber={(value) => setPrice(value)}
                />
              </View>
              {packages?.length > 0 && (
                <>
                  <Pressable
                    style={styles.article}
                    onPress={() => setExpandChecks(!expandChecks)}>
                    <View style={styles.articleHeader}>
                      <Text style={globalStyles.mediumBold}>Checks</Text>
                      {expandChecks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </View>
                    {expandChecks && <Text>Geen checks opgegeven</Text>}
                  </Pressable>
                  <Pressable
                    style={styles.article}
                    onPress={() => setExpandTasks(!expandTasks)}>
                    <View style={styles.articleHeader}>
                      <Text style={globalStyles.mediumBold}>Werkzaamheden</Text>
                      {expandTasks ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </View>
                    {expandTasks && <Text>Geen werkzaamheden opgegeven</Text>}
                  </Pressable>
                </>
              )}
              <Pressable
                style={styles.article}
                onPress={() => setExpandParts(!expandParts)}>
                <View style={styles.articleHeader}>
                  <Text style={globalStyles.mediumBold}>Onderdelen</Text>
                  {expandParts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </View>
              </Pressable>
              {expandParts && (
                <View>
                  {parts?.map((part, index) => {
                    const digits =
                      part.quantity - Math.floor(part.quantity) === 0 ? 0 : 1;

                    return (
                      <Pressable
                        onPress={() => setEditPart(index)}
                        key={index}
                        style={[styles.article, {flexDirection: 'row'}]}>
                        <View
                          style={{
                            width: 24,
                            height: 24,
                            backgroundColor: '#f2f2f2',
                            borderRadius: 2,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Text style={globalStyles.xSmallMedium}>
                            {Format.number(part.quantity, digits)}
                          </Text>
                        </View>
                        <Text style={{marginLeft: 30}}>{part.designation}</Text>
                      </Pressable>
                    );
                  })}
                </View>
              )}
            </ScrollView>
            <View style={[globalStyles.footerView, styles.footerView]}>
              <View
                style={[globalStyles.footerView, {justifyContent: 'flex-end'}]}>
                <PrimaryButton
                  onPress={async () => {
                    const result = await save();
                    if (result?.success) {
                      navigation.goBack();
                    }
                  }}>
                  Opslaan
                </PrimaryButton>
              </View>
            </View>
          </>
        )}
      </SafeAreaView>
      <Dialog
        style={{width: 280, alignSelf: 'center'}}
        title="Prijs"
        visible={showVATDialog}
        dismissable={false}
        onDismiss={() => setShowVATDialog(false)}>
        <View>
          <Pressable
            style={{flexDirection: 'row', paddingTop: 24, paddingBottom: 12}}
            onPress={() => setIncludesVAT(true)}>
            <RadioButton checked={includes_vat} />
            <Text style={{marginLeft: 32}}>Inclusief BTW</Text>
          </Pressable>
          <Pressable
            style={{flexDirection: 'row', paddingTop: 12}}
            onPress={() => setIncludesVAT(false)}>
            <RadioButton checked={!includes_vat} />
            <Text style={{marginLeft: 32}}>Exclusief BTW</Text>
          </Pressable>
        </View>
      </Dialog>
      {_.isNumber(editPart) && (
        <Dialog
          visible={true}
          title={parts[editPart].designation}
          dismissable={false}
          onDismiss={() => setEditPart(null)}>
          <View style={{paddingTop: 24}}>
            <FormNumberInput
              label="Aantal"
              digits={2}
              value={parts[editPart].quantity}
              onChangeNumber={(value) => {
                parts[editPart].quantity = value;
                setParts([...parts]);
              }}
            />
            <FormTextInput
              label="Omschrijving (optioneel)"
              value={parts[editPart].description}
              onChangeText={(value) => {
                parts[editPart].description = value;
                setParts([...parts]);
              }}
            />
          </View>
        </Dialog>
      )}
      <Dialog
        visible={showConfirmDelete}
        dismissable={false}
        buttons={[
          {text: 'Annuleren', onPress: () => setShowConfirmDelete(false)},
          {text: 'OK', onPress: onDelete},
        ]}>
        <Text>Weet je zeker dat je dit voorstel wilt verwijderen?</Text>
      </Dialog>
    </>
  );
};

export default TaskEdit;
