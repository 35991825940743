import React, {useEffect, useState, useContext, useRef} from 'react';
import {ActivityIndicator, SafeAreaView, View, ScrollView} from 'react-native';

import styles from './styles';
import globalStyles from '../../../styles';
import {dealer as dealerApi} from '.../../../api/private';
import {Pressable, Text, OrderStatusIcon} from '../../../components/controls';
import DashboardIcon from '../../../images/md-icons/dashboard/materialicons/24px.svg';
import authentication from '../../../lib/authentication';
import Format from '../../../lib/format';
import {OrderStatus} from '../../../types';
import {DealerHeader} from '../../../components/shared';
import UserContext from '../../../components/context/UserContext';
import * as d3 from 'd3';

const Tile = ({description, value, icon, onPress}) => {
  const content = (
    <>
      <Text
        style={[
          globalStyles.smallMedium,
          {
            color: '#828282',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}>
        {description}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginTop: 4,
        }}>
        <Text
          style={{
            fontFamiliy: 'Roboto_500Medium',
            fontSize: 35,
            lineHeight: 36,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: icon ? 6 : 0,
          }}>
          {value}
        </Text>
        {icon}
      </View>
    </>
  );

  return onPress ? (
    <Pressable style={styles.tileRoot} onPress={onPress}>
      {content}
    </Pressable>
  ) : (
    <View style={styles.tileRoot}>{content}</View>
  );
};

const TileGraph = ({values}) => {
  const graph = useRef(null);
  const [last, setLast] = useState(null);

  const width = 360;
  const height = 200;
  const marginRight = 0;
  const marginTop = 0;
  const marginBottom = 0;

  useEffect(() => {
    graph.current.innerHTML = '';

    if (!values.length) {
      return;
    }

    const svg = d3
      .select(graph.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(0, 0)');

    const data = values
      .map((item) => ({
        date: new Date(item.date),
        value: parseInt(item.value),
      }))
      .sort((a, b) => a.date - b.date);

    console.log(data);

    var x = d3
      .scaleTime()
      .domain([
        d3.min(data, (item) => item.date),
        d3.max(data, (item) => item.date),
      ])
      .range([-1, width - marginRight + 1]);

    var y = d3
      .scaleLinear()
      .range([height - marginBottom, marginTop])
      .domain([
        d3.min(data, (item) => item.value),
        d3.max(data, (item) => item.value),
      ]);

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#231fda')
      .attr('stroke-width', 2)
      .attr(
        'd',
        d3
          .line()
          .x((item) => x(item.date))
          .y((item) => y(item.value)),
      );

    const last = data[data.length - 1].value;

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#231fda')
      .attr('stroke-dasharray', '2')
      .attr('stroke-width', 1.5)
      .attr(
        'd',
        d3
          .line()
          .x((item) => x(item.date))
          .y(() => y(last)),
      )
      .attr('transform', `translate(${width}, 0)`)
      .transition()
      .ease(d3.easeSin)
      .duration(1000)
      .attr('transform', 'translate(0, 0)');

    // svg
    //   .append('g')
    //   .attr('transform', `translate(${width - marginRight}, 0)`)
    //   .call(d3.axisRight(y));

    setLast(last);
  }, [graph, values]);

  return (
    <View
      style={[
        styles.tileRoot,
        {
          minWidth: 'calc(100% - 8px)',
          maxWidth: 'calc(100% - 8px)',
        },
      ]}>
      <Text
        style={[
          globalStyles.smallMedium,
          {
            color: '#828282',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}>
        Toekomstige boekingen
      </Text>

      <View
        style={{
          flexDirection: 'row',
          marginVertical: 4,
        }}>
        <Text
          style={{
            fontFamiliy: 'Roboto_500Medium',
            fontSize: 35,
            lineHeight: 36,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
          {last}
        </Text>
      </View>
      <View ref={graph} />
    </View>
  );
};

const Overview = ({navigation}) => {
  const [loading, setLoading] = useState(true);
  const [tiles, setTiles] = useState([]);
  const [bookings, setBookings] = useState([]);
  const {me} = useContext(UserContext);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const dashboard = await dealerApi.dashboard(token);

      let tiles = [];
      if (dashboard) {
        tiles.push({
          description: 'Inkomsten',
          value: Format.number(dashboard.total, 0, true),
        });

        tiles.push({
          description: 'Transacties',
          value: Format.number(dashboard.count, 0, true),
        });

        tiles = tiles.concat(
          dashboard.status?.map((item) => ({
            description: OrderStatus.text(item.status),
            value: Format.number(item.count, 0, true),
            icon: <OrderStatusIcon status={item.status} />,
            onPress: () =>
              navigation.navigate('Dashboard', {
                screen: 'Orders',
                params: {status: item.status},
              }),
          })),
        );

        setBookings(dashboard.bookings);
      }

      setTiles(tiles);
      setLoading(false);
    };

    fetch();
  }, [navigation, me]);

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <View style={[globalStyles.headerView, styles.headerView]}>
          <DealerHeader
            title="Dashboard"
            icon={<DashboardIcon fill="#ffffff" height={18} width={18} />}
          />
        </View>
        <ScrollView style={[globalStyles.contentView, styles.contentView]}>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading && (
            <>
              <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {tiles.map((tile, index) => (
                  <Tile key={index} {...tile} />
                ))}
              </View>

              <TileGraph values={bookings} />
            </>
          )}
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

export default Overview;
