import {StyleSheet} from 'react-native';

const styles = {
  contentView: {
    flex: 1,
    paddingTop: 18,
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    backgroundColor: '#f2f2f2',
  },
};

export default StyleSheet.create(styles);
