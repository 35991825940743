import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  headerView: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  contentView: {
    marginBottom: 32,
  },
  nextButton: {
    paddingHorizontal: 32,
    alignSelf: 'flex-end',
  },
  weekdays: {
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomColor: '#e8e8e8',
  },
  weekdayView: {
    position: 'absolute',
    top: 0,
    aspectRatio: 1,
  },
  weekdayText: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerView: {
    justifyContent: 'flex-end',
  },
});
