import {StyleSheet} from 'react-native';

const styles = {
  modalMainView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalHeaderView: {
    marginTop: 20,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerButton: {
    position: 'absolute',
    left: 0,
  },
  headerView: {
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  contentView: {
    width: '100%',
    flex: 1,
    position: 'relative',
  },
  article: {
    flex: 1,
  },
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    marginBottom: -1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 20,
    zIndex: 1,
  },
  row: {
    paddingHorizontal: 0,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    marginTop: -1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  gray: {
    color: '#828282',
  },
  icon: {
    marginRight: 30,
  },
};

export default StyleSheet.create(styles);
