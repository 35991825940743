import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {SafeAreaView, View, ScrollView, ActivityIndicator} from 'react-native';
import {HeaderBackButton} from '@react-navigation/stack';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {order as orderApi} from '../../../api/private';
import {dealer as dealerApi} from '../../../api/public';
import authentication from '../../../lib/authentication';
import UserContext from '../../../components/context/UserContext';
import SearchIcon from '../../../images/md-icons/search/materialicons/24px.svg';
import ChevronLeftIcon from '../../../images/md-icons/chevron_left/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import {
  Pressable,
  Text,
  PrimaryButton,
  Checkbox,
  Dialog,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addCheckedTasks} from '../../../actions';
import {Categories} from '../../../types/ServiceCodes';
import Format from '../../../lib/format';

const CollapsableCategory = ({
  tasks,
  title,
  children,
  defaultExpand = false,
}) => {
  const [expand, setExpand] = useState(defaultExpand);

  const checked = useSelector((state) => state.checkedTasks);
  const itemsChecked = checked?.filter((item) =>
    tasks?.map((task) => task.id).includes(item.dealer_service_id),
  );

  return (
    <View style={styles.article}>
      <Pressable
        style={styles.articleHeader}
        onPress={() => setExpand(!expand)}>
        <View style={{flexDirection: 'row'}}>
          <Text style={globalStyles.mediumPlus}>{title}</Text>
          {itemsChecked?.length > 0 && (
            <>
              &nbsp;
              <Text style={globalStyles.mediumPlus}>
                ({itemsChecked.length})
              </Text>
            </>
          )}
        </View>
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Pressable>
      {expand && children}
    </View>
  );
};

const ServiceRow = ({task}) => {
  const dispatch = useDispatch();
  const checked = useSelector((state) => state.checkedTasks);

  const combo = task.related
    ?.filter((related) => !related.exclude)
    .find((item) =>
      checked.some(
        (service) => service.service_code === item.related_service_code,
      ),
    );

  const is_checked = checked.some((item) => item.dealer_service_id === task.id);
  const display_price = combo ? combo.price : task.price;

  return (
    <Pressable
      key={task.id}
      style={styles.row}
      onPress={() => {
        let changes = checked;
        if (checked.some((item) => item.dealer_service_id === task.id)) {
          changes = changes.filter(
            (item) => item.dealer_service_id !== task.id,
          );
        } else {
          changes.push({
            ...task,
            id: task.task_id,
            dealer_service_id: task.id,
            description: task.title,
          });
        }

        dispatch(addCheckedTasks(changes));
      }}>
      <View style={styles.icon}>
        <Checkbox checked={is_checked} />
      </View>
      <View style={styles.description}>
        <Text>{task.title}</Text>

        {(is_checked || combo) && _.isNumber(display_price) && (
          <Text style={[globalStyles.smallRegular, {color: '#828282'}]}>
            {Format.price(display_price)}
          </Text>
        )}
      </View>
    </Pressable>
  );
};

const Services = ({route, navigation}) => {
  const dispatch = useDispatch();
  const request_id = route.params?.request_id;
  const order_id = route.params?.order_id;

  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const checked = useSelector((state) => state.checkedTasks);

  const prefix = useRef('');

  const {me} = useContext(UserContext);

  const save = useCallback(async () => {
    if (checked.length > 0) {
      const token = await authentication.getAccessToken();
      await orderApi.addRequestTasks(
        token,
        order_id,
        request_id,
        checked.map((service) => ({
          ...service,
          includes_vat: true,
        })),
      );
    }
  }, [order_id, request_id, checked]);

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={async () => {
            if (checked.length > 0) {
              setShowConfirmation(true);
            } else {
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            }
          }}
        />
      ),
      headerTitle: (
        <Text style={{display: 'flex', justifyContent: 'center'}}>
          Opdracht
        </Text>
      ),
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.navigate('SearchTasks', {order_id});
          }}
          style={{marginRight: 16}}>
          <SearchIcon />
        </Pressable>
      ),
    });
  }, [navigation, order_id, prefix, save, dispatch, checked]);

  useEffect(() => {
    if (!me) {
      return;
    }

    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const order = await orderApi.details(token, order_id);
      const services = await dealerApi.services(order.dealer_id, order.license);
      setServices(services);
      setLoading(false);
    };

    fetch();
  }, [me, order_id, dispatch]);

  const searchResults = checked?.filter((item) => !item.service_code);

  const {POPULAR: popular, ...rest} = services;

  const checkedPopular = checked?.filter((item) =>
    popular?.map((task) => task.id).includes(item.dealer_service_id),
  );

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <ScrollView style={{flex: 1}}>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading && (
            <>
              {searchResults?.length > 0 && (
                <View style={styles.article}>
                  <View style={styles.articleHeader}>
                    <Text style={globalStyles.mediumPlus}>
                      Zoekresultaten ({searchResults.length})
                    </Text>
                  </View>
                  {searchResults?.map((task) => (
                    <Pressable
                      key={task.id}
                      style={styles.row}
                      onPress={() => {
                        let changes = checked;
                        if (checked.some((item) => item.id === task.id)) {
                          changes = changes.filter(
                            (item) => item.id !== task.id,
                          );
                        } else {
                          changes.push(task);
                        }

                        dispatch(addCheckedTasks(changes));
                      }}>
                      <View style={styles.icon}>
                        <Checkbox
                          checked={checked.some((item) => item.id === task.id)}
                        />
                      </View>
                      <View style={styles.description}>
                        <Text>{task.description}</Text>
                      </View>
                    </Pressable>
                  ))}
                </View>
              )}
              <View style={styles.article}>
                <View style={styles.articleHeader}>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={globalStyles.mediumPlus}>Meest gekozen</Text>
                    {checkedPopular?.length > 0 && (
                      <>
                        &nbsp;
                        <Text style={globalStyles.mediumPlus}>
                          ({checkedPopular.length})
                        </Text>
                      </>
                    )}
                  </View>
                </View>
                {popular.map((task, index) => (
                  <ServiceRow key={index} task={task} />
                ))}
              </View>
              {Object.keys(rest)?.map((category) => {
                const tasks = services[category];

                return (
                  <CollapsableCategory
                    tasks={tasks}
                    key={category}
                    title={Categories[category] || category}>
                    {tasks.map((task, index) => (
                      <ServiceRow key={index} task={task} />
                    ))}
                  </CollapsableCategory>
                );
              })}
            </>
          )}
        </ScrollView>
        <View
          style={[
            globalStyles.footerView,
            {justifyContent: 'flex-end', alignItems: 'center'},
          ]}>
          <PrimaryButton
            onPress={async () => {
              await save();
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            }}>
            Opslaan
          </PrimaryButton>
        </View>
      </SafeAreaView>
      <Dialog
        visible={showConfirmation}
        buttons={[
          {text: 'Nee', onPress: () => setShowConfirmation(false)},
          {
            text: 'Ja',
            onPress: () => {
              dispatch(addCheckedTasks([]));
              navigation.navigate('Job', {
                screen: 'Details',
                params: {order_id},
              });
            },
          },
        ]}>
        <View>
          <Text>De items zijn nog niet opgeslagen.</Text>
          <Text>Weet je zeker dat je terug wilt gaan?</Text>
        </View>
      </Dialog>
    </>
  );
};

export default Services;
