import config from '../../configuration';

const apiRoot = config.privateApiRoot;

const endpoints = {
  session: `${apiRoot}/Session`,

  activities: `${apiRoot}/Activities`,

  order: `${apiRoot}/Order`,

  orderActivities: `${apiRoot}/Order/Activities`,
  orderTasks: `${apiRoot}/Order/Tasks`,
  orderParts: `${apiRoot}/Order/Parts`,
  orderMessage: `${apiRoot}/Order/Message`,
  orderServices: `${apiRoot}/Order/Services`,
  orderChange: `${apiRoot}/Order/Change`,

  dealer: `${apiRoot}/Dealer`,

  user: `${apiRoot}/User`,

  car: `${apiRoot}/Car`,

  invoice: `${apiRoot}/Invoice`,

  work: `${apiRoot}/Work`,

  task: `${apiRoot}/Task`,
};

export default endpoints;
