const config = {
  production: true,
  development: false,
  privateApiRoot: 'https://bahnkick-private-api.azurewebsites.net/api',
  publicApiRoot: 'https://bahnkick-api.azurewebsites.net/api',
  signalRHubUrl: 'https://bahnkick-signalr.azurewebsites.net/api',

  hubName: 'bahnkick-dms-notifications',
  hubEndpoint:
    'Endpoint=sb://bahnkick.servicebus.windows.net/;SharedAccessKeyName=DefaultListenSharedAccessSignature;SharedAccessKey=s1rly2rsk4SR3uHM3qnq09PilJaDP/fpJhtD6PlJed8=',

  LOG_ROCKET_KEY: '8kox0k/bahnkick-dealer',
};

export default config;
