import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import {RecyclerListView, DataProvider, LayoutProvider} from 'recyclerlistview';

import styles from './styles';
import globalStyles from '../../../styles';
import {DateText, Tile} from '../../../components/modules/Calendar';
import authentication from '../../../lib/authentication';
import {dealer as dealerApi} from '../../../api/private';
import UserContext from '../../../components/context/UserContext';

const SECTION_TITLE_HEIGHT = 40;
const TILE_HEIGHT = 96;

const Orders = ({navigation, route}) => {
  const status = route.params?.status;

  const {me} = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState(null);
  const [layoutProvider, setLayoutProvider] = useState(
    new LayoutProvider(
      () => {},
      () => {},
    ),
  );
  const [width, setWidth] = useState(0);

  const dataProvider = useRef(new DataProvider((r1, r2) => r1 !== r2));
  const listViewRef = useRef(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
    });
  }, [navigation]);

  useEffect(() => {
    if (!status) {
      navigation.replace('Overview');
    }

    const fetch = async () => {
      const token = await authentication.getAccessToken();

      const orders = await dealerApi.dashboardOrders(token, status);

      const sections = Object.values(
        orders.reduce((acc, order) => {
          if (!acc[order.date]) {
            acc[order.date] = {
              date: order.date,
              orders: [],
            };
          }

          acc[order.date].orders.push(order);

          return acc;
        }, {}),
      );

      dataProvider.current = dataProvider.current.cloneWithRows(sections);

      setSections(sections);
      setLoading(false);
    };

    fetch();
  }, [navigation, status, me]);

  useEffect(() => {
    setLayoutProvider(
      new LayoutProvider(
        (index) => index,
        (index, dim) => {
          dim.width = width;
          dim.height =
            SECTION_TITLE_HEIGHT + sections[index].orders.length * TILE_HEIGHT;
        },
      ),
    );
  }, [sections, width]);

  const onSectionRenderer = useCallback(
    (index) => {
      const {date, orders} = sections[index];
      return (
        <>
          <View
            style={{flexDirection: 'row', marginLeft: 8, paddingVertical: 8}}>
            <DateText date={moment(date)} />
          </View>
          {orders.map((order) => (
            <Tile key={order.id} {...order} />
          ))}
        </>
      );
    },
    [sections],
  );

  const onLayout = useCallback((e) => {
    const {layout} = e.nativeEvent;

    setWidth(layout.width);
  }, []);

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <View
        style={[globalStyles.contentView, styles.contentView]}
        onLayout={onLayout}>
        {loading && <ActivityIndicator size="large" color="#231fda" />}
        {!loading && sections.length > 0 && (
          <RecyclerListView
            ref={listViewRef}
            style={{flex: 1}}
            contentContainerStyle={{paddingBottom: TILE_HEIGHT}}
            dataProvider={dataProvider.current}
            layoutProvider={layoutProvider}
            rowRenderer={onSectionRenderer}
            scrollViewProps={{showsVerticalScrollIndicator: false}}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default Orders;
