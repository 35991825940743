import React, {useEffect, useState, useContext} from 'react';
import {ActivityIndicator, SafeAreaView, View, ScrollView} from 'react-native';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';

import styles from './styles';
import globalStyles from '../../../styles';
import {dealer as dealerApi} from '../../../api/private';
import {useSelector} from '../../../lib/hooks';
import {
  Pressable,
  Text,
  Persona,
  OrderRequestStatusIcon,
  OrderStatusIcon,
} from '../../../components/controls';
import {PersonaSize} from '../../../components/controls/Persona';
import ChatIcon from '../../../images/md-icons/chat/materialicons/24px.svg';
import authentication from '../../../lib/authentication';
import {DealerHeader, HtmlView} from '../../../components/shared';
import {OrderRequestStatus, OrderStatus} from '../../../types';
import BrowserContext from '../../../components/context/BrowserContext';
import UserContext from '../../../components/context/UserContext';

const ORDER_REQUEST_TYPE = 'ORDER_REQUEST';

const Message = ({
  style,
  order_id,
  reference,
  first_name,
  last_name,
  message,
  message_timestamp,
  message_status,
  message_type,
  unread,
}) => {
  const navigation = useNavigation();
  const {embedded} = useContext(BrowserContext);

  const timestamp = moment(message_timestamp);
  let duration = timestamp.format('DD-MM-YYYY');
  if (timestamp.isSame(moment(), 'day')) {
    duration = timestamp.format('H:mm');
  }
  const onPress = () => {
    if (embedded && reference) {
      window.parent.postMessage({no: reference}, '*');
    }

    navigation.navigate('Job', {screen: 'Chat', params: {order_id}});
  };

  return (
    <Pressable style={[styles.messageRoot, style]} onPress={onPress}>
      <View style={styles.message}>
        <View style={{marginRight: 16, alignSelf: 'center'}}>
          <Persona
            imageInitials={`${first_name[0]}${last_name[0]}`}
            size={PersonaSize.size40}
          />
        </View>
        <View
          style={{
            flex: 1,
            borderTopWidth: 1,
            borderTopColor: '#dcdcdc',
          }}>
          <View
            style={{
              flex: 1,
              overflow: 'hidden',
              paddingVertical: 12,
              paddingRight: 16,
              flexDirection: 'row',
            }}>
            <View style={{flex: 1}}>
              <View style={styles.messageRow}>
                <Text style={[globalStyles.mediumPlus, {lineHeight: 16}]}>
                  {first_name} {last_name}
                </Text>
              </View>
              {message_status && (
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  {message_type === ORDER_REQUEST_TYPE ? (
                    <>
                      <OrderRequestStatusIcon
                        status={message_status}
                        iconProps={{width: 18, height: 18}}
                      />
                      <Text
                        style={{
                          marginLeft: 10,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                        {OrderRequestStatus.text(message_status)}
                      </Text>
                    </>
                  ) : (
                    <>
                      <OrderStatusIcon
                        status={message_status}
                        iconProps={{width: 18, height: 18}}
                      />
                      <Text
                        style={{
                          marginLeft: 10,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}>
                        {OrderStatus.text(message_status)}
                      </Text>
                    </>
                  )}
                </View>
              )}
              {message && (
                <HtmlView
                  value={message}
                  style={{overflow: 'hidden'}}
                  clampLines={1}
                />
              )}
            </View>
            <View style={{alignItems: 'flex-end'}}>
              <View>
                <Text
                  style={[
                    globalStyles.smallRegular,
                    {lineHeight: 14},
                    unread > 0 && {
                      color: '#231fda',
                    },
                  ]}>
                  {duration}
                </Text>
              </View>
              {unread > 0 && (
                <View
                  style={{
                    marginTop: 10,
                    backgroundColor: '#231fda',
                    borderRadius: 90,
                    width: 15,
                    height: 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={
                      (globalStyles.mediumBold,
                      {lineHeight: 10, fontSize: 10, color: '#ffffff'})
                    }>
                    {unread}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

const Overview = ({navigation}) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const jobNotifications = useSelector((state) => state.jobNotifications);
  const inboxNotifications = useSelector((state) => state.inboxNotifications);

  const {me} = useContext(UserContext);

  useEffect(() => {
    const fetch = async () => {
      const token = await authentication.getAccessToken();
      const messages = await dealerApi.messages(token);

      setMessages(messages);
      setLoading(false);
    };

    fetch();
  }, [navigation, me, inboxNotifications, jobNotifications]);

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        <View style={[globalStyles.headerView, styles.headerView]}>
          <DealerHeader
            title="Chat"
            icon={<ChatIcon fill="#ffffff" height={18} width={18} />}
          />
        </View>
        <ScrollView style={[globalStyles.contentView, styles.contentView]}>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading &&
            messages?.map((message, index) => (
              <Message
                key={index}
                {...message}
                style={index === 0 && {marginTop: -1}}
              />
            ))}
        </ScrollView>
      </SafeAreaView>
    </>
  );
};

export default Overview;
