import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';

const user = {
  me: async (token) => {
    const result = await withToken(token).fetch(`${endpoints.user}`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  logout: async (token, data) => {
    const result = await withToken(token).fetch(`${endpoints.user}/logout`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update: async (token, data) => {
    const result = await withToken(token).fetch(`${endpoints.user}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default user;
