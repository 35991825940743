import endpoints from './endpoints';
import {withToken} from '../../lib/fetch';

const task = {
  search: async (token, {prefix, license}) => {
    const result = await withToken(token).fetch(`${endpoints.task}/search`, {
      method: 'POST',
      body: JSON.stringify({prefix, license}),
    });

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
};

export default task;
