import config from '../configuration';
import trace from '../lib/trace';
import authentication from './authentication';

const defaultHostKey = config.functionHostKey;

export const withToken = (token, hostKey = defaultHostKey) => {
  return {
    fetch: async (url, options) => {
      trace('Requesting', url);

      const headers = (options && options.headers) || {};
      let init = {
        ...options,
        headers: {
          ...headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-functions-key': hostKey,
          Authorization: `Bearer ${token}`,
        },
      };

      const start = Date.now();
      let result = await fetch(url, init);
      const diff = Date.now() - start;

      trace(url, result.status, `${diff}ms`);

      if (result.status === 401 || result.status === 403) {
        const refresh = await authentication.refresh();
        if (refresh) {
          const newToken = await authentication.getAccessToken();

          init.headers = {
            ...init.headers,
            Authorization: `Bearer ${newToken}`,
          };
          result = await fetch(url, init);
        }
      }

      if (result.status !== 200) {
        return null;
      }

      return result;
    },
  };
};

const wrappedFetch = async (url, options) => {
  const start = Date.now();
  const result = await fetch(url, options);
  const diff = Date.now() - start;
  trace(url, result.status, `${diff}ms`);

  return result;
};

export {wrappedFetch as fetch};
