import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  menu: {
    backgroundColor: '#fff',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.3,
    shadowColor: '#000',
    shadowRadius: 4,
    elevation: 3,
  },
  option: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    marginBottom: -1,
  },
});
